import React, { useEffect, useRef, useState } from "react";
import rowClose from "../../include/images/row-close-icon.svg";
import rowOpen from "../../include/images/row-open-icon.svg";
import close from "../../include/images/close-icon.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import FilterUser from "./modal/filterUser";
import Select from "react-select";
import More from "../../include/images/more-icon.svg";
import "react-bootstrap";
import "bootstrap";
import emptyFolder from "../../include/images/folder.svg";
import moment from "moment/moment";
import { customStyles } from "../../common/customStyles/reactSelectStyle";
import Skeleton from "react-loading-skeleton";
import DateFormat from "../../common/DateFormat/dateFormat";
import VerifyDocModal from "./modal/verifyDocModal";
import PaymentInfoModal from "./modal/paymentInfoModal";
import ProfileOptions from "./modal/profileOptions";
import { connect } from "react-redux";
import {
  addCategoryPreference,
  addUser,
  getCategories,
  getCategoryPreference,
  getCountry,
  getIndustry,
  getProfileOptions,
  getState,
  getUser,
  getVerificationDoc,
} from "../../store/users";
import { toast } from "react-toastify";
import AlertError from "../../common/alerts/alertError";
import AddUserModal from "./modal/addUserModal";
import { getUserList } from "../../store/userList";
import AlertSuccess from "../../common/alerts/alertSuccess";
import Sidebar from "../../layouts/sidebar";

import CategoryPreferenceModal from "./modal/categoryPreferenceModal";

import RegionPreferences from "./modal/regionPreferences";
import {
  addManufacturePreference,
  getManufacturer,
  getManufacturerPreference,
  getPreferences,
  getRegionPreference,
  getSelectedRegion,
  updateRegionPreference,
} from "../../store/preferences";
import ManufacturePreferences from "./modal/manufacturePreferences";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { getCategoryPreferenacesDropdown, getListings } from "../../store/listings";
import { getProfile } from "../../utils.js/localStorageServices";
import LoadingBar from "react-top-loading-bar";

const UserTable = (props) => {
  const ref = useRef(null);
  const member = getProfile();
  const isInvestmentAccessible =
    member && member?.accessLevels && member?.accessLevels?.employee_investment_admin_access
      ? member?.accessLevels?.employee_investment_admin_access
      : false;
  const location = useLocation();
  const filter = queryString.parse(location.search).filter;
  const createNew = queryString.parse(location.search).create;
  const [showVerificationDocModal, setShowVerificationDocModal] = useState(false);
  const [showPayInfoModal, setShowPayInfoModal] = useState(false);
  const [showProfileOption, setShowProfileOption] = useState(false);
  const [showCategoryPreferenceModal, setShowCategoryPreferenceModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [verificationDoc, setVerificaitonDoc] = useState({});
  const [profileOptionsData, setProfileOptionsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [userData, setUserData] = useState({
    userType: "",
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    stateId: 0,
    extNumber: "",
    countryID: 0,
    industryId: 0,
    zipCode: "",
    addressLine1: "",
    webSite: "",
    phone: "",
    mobile: "",
    password: "",
  });
  const [industryList, setIndustryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [showRegionPref, setShowRegionPref] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [loadingRegion, setLoadingRegion] = useState(false);
  const [regionPrefData, setRegionPrefData] = useState([]);
  const [loadingLevel, setLoadingLevel] = useState(false);
  const [checkList, setCheckList] = useState([]);
  const [categoryDropdown, setCategoryDropdown] = useState(null);
  const [categoryDropDownData, setCategoryDropDownData] = useState([]);

  const [showManufacturePreferences, setShowManufacturePreferences] = useState(false);
  const [manufactures, setManufactures] = useState([]);
  const [selectedManufacture, setSelectedManufacture] = useState([]);
  const [loadingManufacture, setLoadingManufacture] = useState(false);

  const [buyer, setBuyer] = useState(false);

  //For SideNav
  const [showMenu, setShowMenu] = useState(false);
  const [menuList, setMenuList] = useState("");

  const companyAccessOptions = [
    { name: "Select Company Access", value: "" },
    { name: "Restricted", value: "1" },
    { name: "Operator Access", value: "2" },
    { name: "Contract Access", value: "3" },
  ];

  const limitOptions = [
    { name: 10, value: 10 },
    { name: 20, value: 20 },
    { name: 50, value: 50 },
    { name: 100, value: 100 },
    { name: 150, value: 150 },
  ];

  const userTypeOptions = [
    { name: "individual", value: "1" },
    { name: "company", value: "2" },
  ];

  //Find Seller and Create Seller Handling.
  useEffect(() => {
    filter && props?.toggleFilter();
    createNew && toggleAddUserModal();
  }, [location]);
  //////////////

  const totalPage = props.filteredCount ? Math.ceil(props.filteredCount / 50) : 0;

  const toggleShowRegion = () => {
    setShowRegionPref(!showRegionPref);
  };

  const toggleVerificationDoc = (userId) => {
    setUserId(userId);
    setShowVerificationDocModal(!showVerificationDocModal);
  };
  const togglePayInfo = () => {
    setShowPayInfoModal(!showPayInfoModal);
  };
  const toggleProfileOption = () => {
    setShowProfileOption(false);
  };
  const toggleAddUserModal = () => {
    setShowAddUserModal(!showAddUserModal);
  };
  const toggleCategoryPreferenceModal = (userId) => {
    setCategoryLoading(true);
    props.getCategoryPreference(userId, (res) => {
      if (res.status === 200) {
        setSelectedCategory(res.data.userCategories);
        setCategoryLoading(false);
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
    setShowCategoryPreferenceModal(!showCategoryPreferenceModal);
  };

  const closedCategoryPreference = () => {
    setShowCategoryPreferenceModal(!showCategoryPreferenceModal);
  };

  const fetchVerificationDoc = (userId) => {
    setLoading(true);
    setShowVerificationDocModal(true);
    props.getVerificationDoc(userId, (res) => {
      if (res.status === 200) {
        setVerificaitonDoc(res?.data?.detail);
        setLoading(false);
      } else {
        setLoading(false);
        setShowVerificationDocModal(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const fetchProfileOptions = (userId) => {
    setLoading(true);
    setShowProfileOption(true);
    props.getProfileOptions(userId, (res) => {
      if (res.status === 200) {
        setProfileOptionsData(res.data.detail);
        setLoading(false);
      } else {
        setLoading(false);
        setShowProfileOption(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showAddUserModal) {
      props.getIndustry((res) => {
        if (res.status === 200) {
          setIndustryList(res.data);
        }
      });
    }
  }, [showAddUserModal]);

  useEffect(() => {
    if (props.getPreferences && props.getPreferences.regionlistings && props.getPreferences.regionlistings.data) {
      setRegionPrefData(props.getPreferences.regionlistings.data);
    }
  }, [props.getPreferences && props.getPreferences.regionlistings && props.getPreferences.regionlistings.data]);

  useEffect(() => {
    if (!showRegionPref) {
      setSelectedId(null);
    }
    if (showRegionPref && !regionPrefData.length > 0) {
      props.getRegionPref((res) => {
        if (res && res.status == 200) {
          setRegionPrefData(res.data.data);
        }
      });
    }
    if (showRegionPref && selectedId) {
      setLoading(true);
      props.getSelectedRegion(selectedId?.user_id, (res) => {
        if (res && res.status == 200) {
          setLoading(false);
          const b = [];
          const a =
            res &&
            res?.data?.userRegions &&
            res.data?.userRegions?.length > 0 &&
            res.data?.userRegions?.map((item) => {
              if (item.owner != "All Regions") {
                if (item.owner == "United States") {
                  b.push(`${item.region_id}*United States*${item.description}`);
                }
                if (item.owner == "International") {
                  b.push(`${item.region_id}*International*${item.description}`);
                }
              }
            });
          setSelectedRegion(b);
        }
      });
    }
  }, [showRegionPref]);

  useEffect(() => {
    if (showManufacturePreferences) {
      props.getManufacturer((res) => {
        if (res.status === 200) {
          setManufactures(res.data);
        }
      });
      const fetchManufacutePreference = () => {
        setSelectedManufacture([]);
        setLoadingManufacture(true);
        props.getManufacturerPreference(userId, (res) => {
          if (res.status === 200) {
            setSelectedManufacture(res.data.userManufaturers);
            setLoadingManufacture(false);
          } else {
            setLoadingManufacture(false);
            toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
          }
        });
      };
      fetchManufacutePreference();
    }
  }, [showManufacturePreferences]);

  useEffect(() => {
    if (showAddUserModal) {
      props.getCountry((res) => {
        if (res.status === 200) {
          setCountryList(res.data);
        }
      });
    }
  }, [showAddUserModal]);

  useEffect(() => {
    if (showAddUserModal) {
      props.getState((res) => {
        if (res.status === 200) {
          setStateList(res.data);
        }
      });
    }
  }, [showAddUserModal]);

  useEffect(() => {
    props.getCategories({ category_id: 1001, forUserPreference: true }, (res) => {
      if(res.status === 200){
        const data=res?.data?.data;
        setCategoryDropDownData(data);
      }
    });
  }, []);
  const toggleManufacturePreferenceModal = () => {
    setShowManufacturePreferences(!showManufacturePreferences);
  };

  const categoryData = (props.getUser && props.getUser.categories && props.getUser.categories.data) || [];

  useEffect(() => {
    // setCategoryData();
    const checklist =
      categoryData?.category && categoryData?.category.length > 0
        ? categoryData.category.map((item) => item.category_id)
        : [];
    setCheckList(checklist);
  }, [showCategoryPreferenceModal]);


  const getCategoryDetailsMaker = async (id) => {
    return new Promise((resolve, reject) => {
      props?.getCategories({ category_id: id, forUserPreference: true }, (res) => {
        if (res?.status === 200) {
          resolve(res?.data?.data); // Resolve with the data
        } else {
          reject(new Error("Failed to fetch category details")); // Reject on error
        }
      });
    });
  };

  // Usage
  const getCategoryDetails = async (id) => {
    try {
      const data = await getCategoryDetailsMaker(id);
      console.log("Fetched data:", data);
      return data;
    } catch (error) {
      console.error("Error:", error.message);
      return null;
    }
  };

  const loadCategoryDetails = (id) => {
    ref?.current?.continuousStart(); // Start a loading state
    setLoadingLevel(true); // Set the loading indicator

    props?.getCategories({ category_id: id, forUserPreference: true }, (res) => {
      ref?.current?.complete(); // Stop loading
      setLoadingLevel(false); // Clear the loading indicator

      if (res?.status === 200) {
        const newCategories = res?.data?.data;

        // Recursive function to update the nested structure
        const updateNestedCategories = (categories, categoryId, newSubCategories) => {
          return categories.map((category) => {
            if (category.category_id === categoryId) {
              // Add or update subCategories
              return {
                ...category,
                subCategories: newSubCategories,
              };
            }
            if (category.subCategories) {
              // Recursively update subCategories
              return {
                ...category,
                subCategories: updateNestedCategories(category.subCategories, categoryId, newSubCategories),
              };
            }
            return category;
          });
        };

        // Update the main categoryDropDownData state
        setCategoryDropDownData((prevData) => {
          return updateNestedCategories(prevData, id, newCategories);
        });
      }
    });
  };
  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <div class="dashboard-insider position-relative d-lg-flex w-100 ">
        <Sidebar
          showMenu={showMenu}
          setShowMenu={setShowMenu}
          menuList={menuList}
          setMenuList={setMenuList}
          page="Users"
        />
        <div className={showMenu ? ` main-menu-expanded ` : "main-menu-compressed"}>
          <div class="filter-container d-flex align-items-center sticky-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12 d-flex align-items-center flex-wrap justify-content-between">
                  <div class="filter-title fw-medium text-capitalize">Users</div>
                  <div class="filter-widget-right d-flex align-items-center flex-wrap">
                    <div class="fw-item">
                      {props.loading ? (
                        <Skeleton width="50px" height="30px" />
                      ) : (
                        <div className="d-flex ">
                          <div class="filter-row d-flex align-items-center me-4" onClick={() => props.toggleFilter()}>
                            <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M2.39767 16.5C2.07751 16.5 1.81796 16.2357 1.81796 15.9097V1.09032C1.81796 0.764295 2.07751 0.5 2.39767 0.5C2.71784 0.5 2.97738 0.764295 2.97738 1.09032V15.9097C2.97738 16.2357 2.71784 16.5 2.39767 16.5Z"
                                fill="currentColor"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.0226 15.9097C13.0226 16.2357 13.2822 16.5 13.6023 16.5C13.9225 16.5 14.182 16.2357 14.182 15.9097V1.09032C14.182 0.764295 13.9225 0.5 13.6023 0.5C13.2822 0.5 13.0226 0.764295 13.0226 1.09032V15.9097ZM0.57971 13.18H4.21565C4.53582 13.18 4.79536 12.9158 4.79536 12.5897C4.79536 12.2637 4.53582 11.9994 4.21565 11.9994H0.57971C0.259545 11.9994 0 12.2637 0 12.5897C0 12.9158 0.259545 13.18 0.57971 13.18Z"
                                fill="currentColor"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.42029 15.9097C7.42029 16.2357 7.67983 16.5 8 16.5C8.32016 16.5 8.57971 16.2357 8.57971 15.9097V1.09032C8.57971 0.764295 8.32016 0.5 8 0.5C7.67983 0.5 7.42029 0.764295 7.42029 1.09032V15.9097ZM11.7843 13.18H15.4203C15.7405 13.18 16 12.9158 16 12.5897C16 12.2637 15.7405 11.9994 15.4203 11.9994H11.7843C11.4642 11.9994 11.2046 12.2637 11.2046 12.5897C11.2046 12.9158 11.4642 13.18 11.7843 13.18Z"
                                fill="currentColor"
                              />
                              <path
                                d="M9.81798 5.00058H6.18204C5.86187 5.00058 5.60233 4.73629 5.60233 4.41027C5.60233 4.08424 5.86187 3.81995 6.18204 3.81995H9.81798C10.1381 3.81995 10.3977 4.08424 10.3977 4.41027C10.3977 4.73629 10.1381 5.00058 9.81798 5.00058Z"
                                fill="currentColor"
                              />
                            </svg>
                            <span>Filters</span>
                          </div>

                          <div>
                            <button className="btn btn-sm btn-primary" onClick={toggleAddUserModal}>
                              Add User
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-container">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="tb-filter-row d-flex align-items-start justify-content-between">
                    <div class="filter-tags d-flex align-items-start">
                      {!props.loading &&
                        props.selectedFilter &&
                        // props.selectedFilter.keyword ||
                        (props?.selectedFilter?.name ||
                          props?.selectedFilter?.email ||
                          props?.selectedFilter?.phone) && (
                          // || props?.selectedFilter?.location
                          <>
                            <div class="filter-tag-title">Filters Applied :</div>

                            <div class="tags">
                              {/* {props.selectedFilter && props.selectedFilter.keyword && (
                                <span class="badge">
                                  Keyword : <span>{props.selectedFilter.keyword}</span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      props.setKeyword("");
                                      props.handleFilter(true, true, false, false, false, false);
                                    }}
                                  />
                                </span>
                              )} */}
                              {props.selectedFilter && props.selectedFilter.name && (
                                <span class="badge">
                                  Name : <span>{props.selectedFilter.name}</span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      props.setName("");
                                      props.handleFilter(true, false, true, false, false, false);
                                    }}
                                  />
                                </span>
                              )}
                              {props.selectedFilter && props.selectedFilter.email && (
                                <span class="badge">
                                  Email : <span>{props.selectedFilter.email}</span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      props.setEmail("");
                                      props.handleFilter(true, false, false, true, false, false);
                                    }}
                                  />
                                </span>
                              )}
                              {props.selectedFilter && props.selectedFilter.phone && (
                                <span class="badge">
                                  Phone : <span>{props.selectedFilter.phone}</span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      props.setPhone("");
                                      props.handleFilter(true, false, false, false, true, false);
                                    }}
                                  />
                                </span>
                              )}
                              {/* {props.selectedFilter && props.selectedFilter.location && (
                                <span class="badge">
                                  Location : <span>{props.selectedFilter.location}</span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      props.setLocation("");
                                      props.handleFilter(true, false, false, false, false, true);
                                    }}
                                  />
                                </span>
                              )} */}
                              {/* {props.selectedFilter && props.selectedFilter.companyAccess && (
                                <span class="badge">
                                  Company Access :{" "}
                                  <span>
                                    {props.selectedFilter.companyAccess == "1"
                                      ? "Restricted"
                                      : props.selectedFilter.companyAccess == "2"
                                      ? "Operator Access"
                                      : "Contract Access"}
                                  </span>
                                  <img
                                    class="tag-close-icon"
                                    src={close}
                                    alt=""
                                    onClick={() => {
                                      props.setCompanyAccess({
                                        name: "Select Company Access",
                                        value: "",
                                      });
                                      props.handleFilter(true, false, true);
                                    }}
                                  />
                                </span>
                              )} */}
                            </div>
                          </>
                        )}{" "}
                      {props.loading && (
                        <div class="filter-tags d-flex align-items-start">
                          <div class="filter-tag-title">
                            <div class="tags d-flex align-items-start">
                              <span class="badge">
                                <Skeleton />
                              </span>
                              <span class="badge">
                                <Skeleton />
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {props?.show && (
                      <div class="tb-filter-right d-flex align-items-center justify-content-end">
                        <div class="showing-result-text">
                          {!props.loading && props.filteredCount < 50 ? (
                            `Showing ${props.filteredCount} of ${props.filteredCount} total results`
                          ) : !props.loading && props.page == totalPage ? (
                            `Showing ${props.filteredCount} of ${props.filteredCount} total results`
                          ) : !props.loading ? (
                            `Showing ${50 * props.page} of ${props.filteredCount} total results`
                          ) : (
                            <Skeleton width="300px" height="25px" />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div class="selected-table-row d-flex justify-content-between"></div>
                  <div
                    class={`table-responsive position-relative ${
                      props.userData && props.userData.length > 0 ? "min-user-table-height" : ""
                    }`}
                  >
                    <div class="table-selected-count d-none align-items-center justify-content-between">
                      <div class="tsc-check-label d-flex align-items-center">
                        <div class="tsc-checkbox">
                          <div class="form-check tick-checkbox">
                            <input class="form-check-input" type="checkbox" value="" id="checkAll" />
                            <label class="form-check-label" for="checkAll"></label>
                          </div>
                        </div>
                        <div class="link-label fw-medium">2 Selected</div>
                        <div class="link-clear fw-medium">Clear all</div>
                      </div>
                    </div>
                    <table class="table align-middle table-collapse-table">
                      <thead>
                        <tr>
                          {/* <th style={{ width: "auto" }}></th> */}
                          <th style={{ width: "7%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              Actions
                            </a>
                          </th>
                          <th style={{ width: "8%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              ID
                            </a>
                          </th>
                          <th style={{ width: "17.5%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              User Info
                            </a>
                          </th>
                          <th style={{ width: "20%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              Contact
                            </a>
                          </th>
                          {/* <th style={{ width: "15%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              Company Access
                            </a>
                          </th> */}
                          <th style={{ width: "17.5%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              Location
                            </a>
                          </th>
                          <th style={{ width: "12.5%" }}>
                            <a href="javascript:void(0)" class="sort-by">
                              Date Created
                            </a>
                          </th>
                          <th style={{ width: "50px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.loading || props.loadingFilter
                          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4].map((item) => (
                              <tr>
                                <td colspan="7">
                                  <table class="table2">
                                    <tr>
                                      <td style={{ width: "7%" }}>
                                        <Skeleton />
                                      </td>
                                      <td style={{ width: "8%" }}>
                                        <Skeleton />
                                      </td>
                                      <td style={{ width: "17.5%" }}>
                                        <Skeleton />
                                      </td>
                                      <td style={{ width: "20%" }}>
                                        <Skeleton />
                                      </td>
                                      {/* <td style={{ width: "15%" }}>
                                        <Skeleton />
                                      </td> */}
                                      <td style={{ width: "17.5%" }}>
                                        <Skeleton />
                                      </td>
                                      <td style={{ width: "12.5%" }}>
                                        <Skeleton />
                                      </td>
                                      <td
                                        style={{
                                          width: "50px",
                                        }}
                                      >
                                        <Skeleton />
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            ))
                          : props.userData &&
                            props.userData.length > 0 &&
                            props.userData.map((item) => {
                              const location = [];
                              if (item.address_line_1) {
                                location.push(item.address_line_1);
                              }
                              if (item.address_line_2) {
                                location.push(item.address_line_2);
                              }
                              if (item.city) {
                                location.push(item.city);
                              }
                              if (item.state_name) {
                                location.push(item.state_name);
                              }
                              if (item.country_name) {
                                location.push(item.country_name);
                              }

                              return (
                                props?.show && (
                                  <tr>
                                    <td colspan="7">
                                      <table class="table2">
                                        <tr>
                                          <td style={{ width: "7%" }}>
                                            <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                              <div class="dropdown">
                                                <a
                                                  class="td-a-icon dropdown-toggle"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <img src={More} alt="" />
                                                </a>
                                                <ul class="dropdown-menu ms-1">
                                                  <li
                                                    className="menu-item"
                                                    data-bs-toggle="offcanvas"
                                                    data-bs-target="#offcanvasRight"
                                                    aria-controls="offcanvasRight"
                                                  >
                                                    <a
                                                      class="dropdown-item"
                                                      href="javascript:void(0)"
                                                      onClick={() => fetchProfileOptions(item.user_id)}
                                                    >
                                                      Profile
                                                    </a>
                                                  </li>

                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item"
                                                      href="javascript:void(0)"
                                                      onClick={() => fetchVerificationDoc(item.user_id)}
                                                    >
                                                      Verification
                                                    </a>
                                                  </li>
                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        props.setId(item.user_id);
                                                        togglePayInfo();
                                                      }}
                                                    >
                                                      Payment Info
                                                    </a>
                                                  </li>
                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        toggleCategoryPreferenceModal(item.user_id);
                                                        setUserId(item.user_id);
                                                      }}
                                                    >
                                                      Category Preferences
                                                    </a>
                                                    <a
                                                      class="dropdown-item"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        setSelectedId(item);
                                                        toggleShowRegion();
                                                      }}
                                                    >
                                                      Region Preferences
                                                    </a>
                                                  </li>
                                                  <li className="menu-item">
                                                    <a
                                                      class="dropdown-item"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        setUserId(item.user_id);
                                                        toggleManufacturePreferenceModal();
                                                      }}
                                                    >
                                                      Manufacturer Preferences
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </td>
                                          <td style={{ width: "8%" }}>
                                            <div class="td-action-btn-group d-flex align-items-center justify-content-between">
                                              <OverlayTrigger
                                                placement="left"
                                                overlay={
                                                  <Tooltip style={{ position: "fixed" }}>
                                                    <span>
                                                      {" "}
                                                      <span>UID : {item.uid ? item.uid : "N/A"}</span>
                                                      <br />
                                                      <span>Seller Id : {item.seller_id ? item.seller_id : "N/A"}</span>
                                                      <br />
                                                      <span>Member Id : {item.member_id ? item.member_id : "N/A"}</span>
                                                      <br />
                                                      <span>Buyer Id : {item.buyer_id ? item.buyer_id : "N/A"}</span>
                                                      <br />
                                                      <span>
                                                        Mt Id : {item.mt_id ? item.mt_id : item.mt_id === 0 ? 0 : "N/A"}
                                                      </span>
                                                    </span>
                                                  </Tooltip>
                                                }
                                              >
                                                <span>{item.user_id ? item.user_id : "N/A"}</span>
                                              </OverlayTrigger>
                                            </div>
                                          </td>
                                          <td style={{ width: "17.5%" }} className="word-break">
                                            <span>{item.name01}</span>
                                            <br />
                                            <span className="gray-color-text">
                                              {item.company_name && item.company_name}
                                            </span>
                                          </td>
                                          <td style={{ width: "20%" }} className="word-break">
                                            <span>{item.email && item.email != "undefined" ? item.email : ""}</span>
                                            <br />
                                            <span className="gray-color-text">
                                              {item.phone && item.phone != "undefined" && item.phone}
                                            </span>
                                          </td>
                                          {/* <td style={{ width: "15%" }}>
                                        <span>
                                          {item.company_access
                                            ? item.company_access === 1
                                              ? "Restricted"
                                              : item.company_access === 2
                                              ? "Operator Accsess"
                                              : item.company_access === 3 && "Contract Access"
                                            : "Restricted"}
                                        </span>
                                      </td> */}
                                          <td
                                            style={{
                                              width: "17.5%",
                                              whiteSpace: "normal",
                                            }}
                                          >
                                            {location && location.length > 0
                                              ? location.map((item) => item).join(", ")
                                              : "N/A"}
                                          </td>
                                          <td style={{ width: "12.5%" }}>
                                            {item.date_created
                                              ? moment(item.date_created).format("MMM-DD-YYYY")
                                              : "N/A"}
                                          </td>
                                          <td style={{ width: "50px" }}>
                                            <a
                                              href="javascript:void(0)"
                                              class="detailToggle d-flex align-content-center justify-content-center"
                                            >
                                              <div class="td-toggle-icon mx-auto">
                                                {props.expandedItem && props.expandedItem.user_id === item.user_id ? (
                                                  <img
                                                    src={rowOpen}
                                                    // class="row-open"
                                                    alt=""
                                                    onClick={() => props.setExpandedItem({})}
                                                  />
                                                ) : (
                                                  <img
                                                    src={rowClose}
                                                    // class="row-close"
                                                    alt=""
                                                    onClick={() => props.setExpandedItem(item)}
                                                  />
                                                )}
                                              </div>
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="8" class="no-pad">
                                            <div
                                              class={`detail-container ${
                                                props.expandedItem && props.expandedItem.user_id === item.user_id
                                                  ? "d-block"
                                                  : "d-none"
                                              }`}
                                              id="expand1"
                                            >
                                              <div className="trader-details-expand">
                                                <div class="d-flex flex-wrap w-100 trader-details-expand">
                                                  {/* <div class="dt-text">
                                                Job Title :{" "}
                                                <span
                                                  class="fw-semibold mr-5"
                                                  style={{
                                                    marginRight: "25px",
                                                  }}
                                                >
                                                  {item.job_title ? item.job_title : "N/A"}
                                                </span>{" "}
                                              </div> */}
                                                  <div class="dt-text">
                                                    Created By :{" "}
                                                    <span
                                                      class="fw-semibold "
                                                      style={{
                                                        marginRight: "25px",
                                                      }}
                                                    >
                                                      {item.created_by ? item.created_by : "N/A"}
                                                    </span>{" "}
                                                  </div>

                                                  <div class="dt-text">
                                                    Last Login :{" "}
                                                    <span
                                                      class="fw-semibold "
                                                      style={{
                                                        marginRight: "25px",
                                                      }}
                                                    >
                                                      {item.last_login ? <DateFormat date={item.last_login} /> : "N/A"}
                                                    </span>{" "}
                                                  </div>
                                                  <div class="dt-text">
                                                    Last Updated :{" "}
                                                    <span
                                                      class="fw-semibold "
                                                      style={{
                                                        marginRight: "25px",
                                                      }}
                                                    >
                                                      {item.last_updated ? (
                                                        <DateFormat date={item.last_updated} />
                                                      ) : (
                                                        "N/A"
                                                      )}
                                                    </span>{" "}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                )
                              );
                            })}
                      </tbody>
                    </table>
                  </div>
                  {!props.loading && !props.loadingFilter && props.userData && props.userData.length === 0 && (
                    <>
                      <div class="table-empty">
                        <div class="table-empty-inner">
                          <img src={emptyFolder} alt="" />
                          <p>No Items Found</p>
                        </div>
                      </div>
                    </>
                  )}

                  {props?.show && (
                    <div class="table-pagination">
                      <ul class="pagination  justify-content-end">
                        <li class="page-item">
                          <a
                            class={`page-link ${props.page === 1 ? "disabled" : ""}`}
                            href="#"
                            aria-label="Previous"
                            onClick={() => {
                              if (props.page !== 1) {
                                props.setPage(props.page - 1);
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li class="page-item active">
                          <a class="page-link" href="javascript:void(0)">
                            {props.page}
                          </a>
                        </li>
                        {totalPage > 1 && props.page + 1 <= totalPage && (
                          <li class="page-item">
                            <a class="page-link" href="#" onClick={() => props.setPage(props.page + 1)}>
                              {props.page + 1}
                            </a>
                          </li>
                        )}
                        <li class="page-item">
                          <a
                            class={`page-link ${props.page + 1 >= totalPage ? "disabled" : ""}`}
                            href="#"
                            aria-label="Next"
                            onClick={() => props.setPage(props.page + 1)}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <FilterUser
                show={props.showFilter}
                onHide={props.toggleFilter}
                searchKeyword={props.keyword}
                setSearchKeyword={props.setKeyword}
                name={props?.name}
                setName={props?.setName}
                email={props?.email}
                setEmail={props?.setEmail}
                phone={props?.phone}
                setPhone={props?.setPhone}
                location={props?.location}
                setLocation={props?.setLocation}
                accessType={props.companyAccess}
                setAccessType={props.setCompanyAccess}
                options={companyAccessOptions}
                handleFilter={props.handleFilter}
                loadingFilter={props.loadingFilter}
              />
            </div>

            <AddUserModal
              show={showAddUserModal}
              onHide={toggleAddUserModal}
              userData={userData}
              setUserData={setUserData}
              industryList={industryList}
              countryList={countryList}
              stateList={stateList}
              // handleAddUser={handleAddUser}
              userTypeOptions={userTypeOptions}
              buyer={buyer}
              setBuyer={setBuyer}
              setUserId={setUserId}
              toggleCategoryPreferenceModal={toggleCategoryPreferenceModal}
              loading={loading}
            />

            <VerifyDocModal
              show={showVerificationDocModal}
              onHide={toggleVerificationDoc}
              userId={userId}
              verificationDoc={verificationDoc}
              loading={loading}
            />
          </div>
          <PaymentInfoModal
            show={showPayInfoModal}
            onHide={togglePayInfo}
            ref={props.ref}
            id={props.id}
            setId={props.setId}
          />
          <ProfileOptions
            show={showProfileOption}
            onHide={toggleProfileOption}
            profileOptionsData={profileOptionsData}
            loading={loading}
          />
          <CategoryPreferenceModal
            show={showCategoryPreferenceModal}
            onHide={closedCategoryPreference}
            categoryData={categoryData}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            addCategoryPreference={props.addCategoryPreference}
            userId={userId}
            buyer={buyer}
            setBuyer={setBuyer}
            setSelectedId={setSelectedId}
            userData={props.userData}
            toggleShowRegion={toggleShowRegion}
            categoryDropDownData={categoryDropDownData}
            categoryLoading={categoryLoading}
            loadCategoryDetails={loadCategoryDetails}
            loadingLevel={loadingLevel}
            getCategoryDetails={getCategoryDetails}
          />
          <RegionPreferences
            show={showRegionPref}
            onHide={toggleShowRegion}
            data={regionPrefData}
            selectedId={selectedId}
            selectedData={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            selectedRegion={selectedRegion}
            loading={loadingRegion}
            setLoading={setLoadingRegion}
            loadingSkeleton={loading}
            setRegionPrefData={setRegionPrefData}
            buyer={buyer}
            setBuyer={setBuyer}
          />
          <ManufacturePreferences
            show={showManufacturePreferences}
            onHide={toggleManufacturePreferenceModal}
            manufactures={manufactures}
            selectedManufacture={selectedManufacture}
            setSelectedManufacture={setSelectedManufacture}
            loadingManufacture={loadingManufacture}
            userId={userId}
            addManufacturePreference={props.addManufacturePreference}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getVerificationDoc: (params, callback) => dispatch(getVerificationDoc(params, callback)),
  getProfileOptions: (params, callback) => dispatch(getProfileOptions(params, callback)),
  getIndustry: (callback) => dispatch(getIndustry(callback)),
  getCountry: (callback) => dispatch(getCountry(callback)),
  getState: (callback) => dispatch(getState(callback)),
  addUser: (data, callback) => dispatch(addUser(data, callback)),
  getUserList: (params, callback) => dispatch(getUserList(params, callback)),
  getCategories: (params, callback) => dispatch(getCategories(params, callback)),
  getCategoryPreference: (params, callback) => dispatch(getCategoryPreference(params, callback)),
  addCategoryPreference: (params, data, callback) => dispatch(addCategoryPreference(params, data, callback)),
  getRegionPref: (callback) => dispatch(getRegionPreference(callback)),
  getSelectedRegion: (id, callback) => dispatch(getSelectedRegion(id, callback)),
  updateRegionPreference: (id, data, callback) => dispatch(updateRegionPreference(id, data, callback)),
  getManufacturer: (callback) => dispatch(getManufacturer(callback)),
  getManufacturerPreference: (params, callback) => dispatch(getManufacturerPreference(params, callback)),
  addManufacturePreference: (params, data, callback) => dispatch(addManufacturePreference(params, data, callback)),
  getCategoryPreferenacesDropdown: (id, callback) => dispatch(getCategoryPreferenacesDropdown(id, callback)),
});
const mapStateToProps = (state) => ({
  getListings: getListings(state),
  getUser: getUser(state),
  getPreferences: getPreferences(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(UserTable));
