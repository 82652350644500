import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Close from "../../../include/images/close.svg";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import HTMLReactParser from "html-react-parser";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import moment from "moment";
import DateFormat from "../../../common/DateFormat/dateFormat";
import NextButton from "../../../common/form/nextButton";
import { isNumber } from "lodash";
import AsyncSelect from "react-select/async";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import { getProfile } from "../../../utils.js/localStorageServices";
import PaymentInfoComponent from "../../../components/users/modal/paymentInfoComponent";

const AddPayablesModal = (props) => {
  const requestorOptions = props?.payableDetails?.requestor?.data;
  const statusOptions = props?.payableDetails?.status?.data;
  const expenseTypeOptions = props?.payableDetails?.expenseType?.data;
  const paymentMethodOptions = props?.payableDetails?.paymentMethod?.data;
  const paymentTermOptions = props?.payableDetails?.paymentTerm?.data;
  const statesOptions = props?.payableDetails?.states?.data;
  const countriesOptions = props?.countries;
  const serviceOptions = props?.payableDetails?.deliveryService?.data;
  const deliveryMethodOptions = props?.payableDetails?.deliveryMethod?.data;
  const deliveryInfoOptions = props?.payableDetails?.deliveryInformation?.data;
  const completed = props?.completed;
  const profile = getProfile();

  const entityOptions = [
    { name: "Salvex Metals, Inc.", value: "Salvex Metals, Inc." },
    { name: "Salvex Solar, Inc.", value: "Salvex Solar, Inc." },
    { name: "Salvex Energy, Inc.", value: "Salvex Energy, Inc." },
    { name: "Salvex Food, Inc.", value: "Salvex Food, Inc." },
    { name: "Salvex Group, Inc.", value: "Salvex Group, Inc." },
    { name: "Salvex, Inc.", value: "Salvex, Inc." },
    { name: "Salvex Aviation", value: "Salvex Aviation" },
    { name: "Salvex Commodities, Inc.", value: "Salvex Commodities, Inc." },
  ];

  const paymentToOptions = [
    { name: "Please Select an Option", value: 0 },
    {
      name: "Seller",
      value: 1,
    },
    {
      name: "Buyer (Refund)",
      value: 2,
    },
    {
      name: "Other",
      value: 3,
    },
  ];

  const [defaultEntity, setDefaultEntity] = useState({
    name: "Please Select an Entity",
    value: -1,
  });

  const [userId, setUserId] = useState(
    props?.fieldData?.sellerInfo?.s_user_id ? props?.fieldData?.sellerInfo?.s_user_id : "",
  );
  const [show, setShow] = useState(false);
  const [defaultRequestor, setDefaultRequestor] = useState({ name: "", member_id: "" });
  const [defaultStatus, setDefaultStatus] = useState({
    DESCRIPTION: "Please Select a Status",
    ID: -1,
  });
  const [defaultExpense, setDefaultExpense] = useState({
    DESCRIPTION: "Please Select a Expense",
    ID: -1,
  });
  const [defaultPaymentTo, setDefaultPaymentTo] = useState({ name: "Please Select an Option", value: 0 });
  const [defaultState, setDefaultState] = useState({
    abb: "Please Select a State",
    state_id: -1,
  });
  const [defaultCountry, setDefaultCountry] = useState({
    description: "Please Select a Country",
    old: -1,
  });
  const [defaultService, setDefaultService] = useState({
    DESCRIPTION: "Please Select a Service",
    ID: -1,
  });
  const [defaultBankState, setDefaultBankState] = useState({
    abb: "Please Select a State",
    state_id: -1,
  });
  const [defaultBankCountry, setDefaultBankCountry] = useState({
    description: "Please Select a Country",
    old: -1,
  });

  const [defaultDeliveryMethod, setDefaultDeliveryMethod] = useState({
    DESCRIPTION: "Please Select a Method",
    ID: -1,
  });
  const [defaultDeliveryInfo, setDefaultDeliveryInfo] = useState({
    DESCRIPTION: "Please Select a Info",
    ID: -1,
  });
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState({
    DESCRIPTION: "Please Select a Method",
    ID: -1,
  });
  const [defaultPaymentTerm, setDefaultPaymentTerm] = useState({
    DESCRIPTION: "Please Select a Term",
    ID: -1,
  });
  const [defaultBuyer, setDefaultBuyer] = useState({
    name: "Start typing to search",
    user_id: "",
  });

  const [showDueDate, setShowDueDate] = useState(false);

  useEffect(() => {
    setDefaultEntity({
      name: "Please Select an Entity",
      value: -1,
    });
    setDefaultRequestor({
      name01: "",
      member_id: "",
    });
    setDefaultStatus({
      DESCRIPTION: "Please Select a Status",
      ID: -1,
    });
    setDefaultExpense({
      DESCRIPTION: "Please Select a Expense",
      ID: -1,
    });
    setDefaultPaymentTo({
      name: "Please Select an Option",
      value: 0,
    });
    setDefaultState({
      abb: "Please Select a State",
      state_id: -1,
    });
    setDefaultCountry({
      description: "Please Select a Country",
      old: -1,
    });
    setDefaultService({
      DESCRIPTION: "Please Select a Service",
      ID: -1,
    });
    setDefaultBankState({
      abb: "Please Select a State",
      state_id: -1,
    });
    setDefaultBankCountry({
      description: "Please Select a Country",
      old: -1,
    });
    setDefaultDeliveryMethod({
      DESCRIPTION: "Please Select a Method",
      ID: -1,
    });
    setDefaultDeliveryInfo({
      DESCRIPTION: "Please Select a Info",
      ID: -1,
    });
    setDefaultPaymentMethod({
      DESCRIPTION: "Please Select a Method",
      ID: -1,
    });
    setDefaultPaymentTerm({
      DESCRIPTION: "Please Select a Term",
      ID: -1,
    });
    setDefaultBuyer({
      name: "Start typing to search",
      user_id: "",
    });
  }, [props.clearDropdown]);

  useEffect(() => {
    if (props?.fieldData?.sellerInfo?.s_user_id && props.show) {
      setUserId(props?.fieldData?.sellerInfo?.s_user_id);
      setShow(true);
    }
  }, [props?.fieldData]);

  useEffect(() => {
    if (userId) {
      props?.getPaymentInfo(userId, (res) => {
        if (res.status === 200) {
        } else {
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [userId]);

  const member = getProfile();
  const isInvestmentAccessible =
    member && member?.accessLevels && member?.accessLevels?.employee_investment_admin_access
      ? member?.accessLevels?.employee_investment_admin_access
      : false;
  useEffect(() => {
    if (
      props.show &&
      requestorOptions &&
      statusOptions &&
      expenseTypeOptions &&
      paymentMethodOptions &&
      paymentTermOptions &&
      statesOptions &&
      countriesOptions &&
      serviceOptions &&
      deliveryMethodOptions &&
      deliveryInfoOptions &&
      props?.buyers
    ) {
      if (props?.values?.entity) {
        setDefaultEntity(entityOptions?.find((option) => option.value === props?.values?.entity));
      } else {
        setDefaultEntity({ name: "Please Select an Entity", value: -1 });
      }

      if (props?.values?.req_id) {
        setDefaultRequestor(requestorOptions?.find((option) => option.member_id === props?.values?.req_id));
      } else {
        setDefaultRequestor({
          name01: profile?.name01,
          member_id: profile?.member_id,
        });
      }

      if (props?.values?.status_id) {
        setDefaultStatus(statusOptions?.find((option) => option.ID === props?.values?.status_id));
      } else {
        setDefaultStatus({ DESCRIPTION: "Please Select a Status", ID: -1 });
      }

      if (props?.values?.expense_type) {
        setDefaultExpense(expenseTypeOptions?.find((option) => option.ID === props?.values?.expense_type));
      } else {
        setDefaultExpense({ DESCRIPTION: "Please Select an Expense", ID: -1 });
      }

      if (props?.values?.st) {
        setDefaultState(statesOptions?.find((option) => option.abb === props?.values?.st));
      } else {
        setDefaultState({ abb: "Please Select a State", state_id: -1 });
      }

      if (props?.values?.p_country_id) {
        setDefaultCountry(countriesOptions?.find((option) => option.old === props?.values?.p_country_id));
      } else {
        setDefaultCountry({ description: "Please Select a Country", old: -1 });
      }

      if (props?.values?.delivery_service) {
        setDefaultService(serviceOptions?.find((option) => option.ID === props?.values?.delivery_service));
      } else {
        setDefaultService({ DESCRIPTION: "Please Select a Service", ID: -1 });
      }

      if (props?.values?.bank_st) {
        setDefaultBankState(statesOptions?.find((option) => option.abb === props?.values?.bank_st));
      } else {
        setDefaultBankState({ abb: "Please Select a State", state_id: -1 });
      }

      if (props?.values?.country_id) {
        setDefaultBankCountry(countriesOptions?.find((option) => option.old === props?.values?.country_id));
      } else {
        setDefaultBankCountry({
          description: "Please Select a Country",
          old: -1,
        });
      }

      if (props?.values?.delivery_method) {
        setDefaultDeliveryMethod(deliveryMethodOptions?.find((option) => option.ID === props?.values?.delivery_method));
      } else {
        setDefaultDeliveryMethod({
          DESCRIPTION: "Please Select a Method",
          ID: -1,
        });
      }
      if (props?.values?.payment_to !== 0) {
        setDefaultPaymentTo(paymentToOptions?.find((val) => val?.value === props?.values?.payment_to));
      } else {
        setDefaultPaymentTo({
          name: "Please Select an Option",
          value: 0,
        });
      }

      if (props?.values?.b_user_id) {
        setUserId(props?.values?.b_user_id);
        setShow(true);
        // setDefaultBuyer(props?.buyers?.find((val) => val?.user_id === props?.values?.b_user_id));
        if (props?.fieldData?.buyerInfo?.name || props?.fieldData?.b_email) {
          setDefaultBuyer({
            name: `${props?.fieldData?.buyerInfo?.name} (${props?.fieldData?.b_email})`,
            user_id: props?.fieldData?.buyerInfo?.b_user_id,
          });
        } else {
          setDefaultBuyer({
            name: "Start typing to search",
            user_id: "",
          });
        }
      } else {
        setShow(false);
        setDefaultBuyer({
          name: "Start typing to search",
          user_id: "",
        });
      }

      if (props?.values?.delivery_info) {
        setDefaultDeliveryInfo(deliveryInfoOptions?.find((option) => option.ID === props?.values?.delivery_info));
      } else {
        setDefaultDeliveryInfo({ DESCRIPTION: "Please Select a Info", ID: -1 });
      }

      if (props?.values?.payment_method) {
        setDefaultPaymentMethod(paymentMethodOptions?.find((option) => option.ID === props?.values?.payment_method));
      } else {
        setDefaultPaymentMethod({
          DESCRIPTION: "Please Select a Method",
          ID: -1,
        });
      }

      if (props?.values?.terms_id) {
        const a = paymentTermOptions?.find((option) => option.ID === props?.values?.terms_id);

        if (a.DESCRIPTION == "Due Date") {
          setShowDueDate(true);
        }

        setDefaultPaymentTerm(a);
      } else {
        setDefaultPaymentTerm({ DESCRIPTION: "Please Select a Term", ID: -1 });
      }
    }
  }, [
    props.show,
    requestorOptions,
    statusOptions,
    expenseTypeOptions,
    paymentMethodOptions,
    paymentTermOptions,
    statesOptions,
    countriesOptions,
    serviceOptions,
    deliveryMethodOptions,
    deliveryInfoOptions,
    props?.buyers,
  ]);

  useEffect(() => {
    if (props?.values?.payment_to !== 2) {
      setUserId(props?.fieldData?.sellerInfo?.s_user_id);
      setShow(true);
    }
  }, [defaultPaymentTo]);

  let buyersOptions = props?.buyersList?.map((val) => ({
    name: `${val.name ? val.name + ` (${val.email})` : val.email} `,
    user_id: val.user_id,
    email: val.email,
  }));
  return (
    <Modal
      className="modal fade custom-modal"
      id="addPayablesModal"
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">
            {" "}
            {props.type == "edit" && props.values?.title ? props.values?.title : "New Payables Request"}
          </h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            <img src={Close} alt="" />
          </button>
        </div>
        <div class="custom-modal-body flex-grow-1 w-100 overflow-hidden pb-0">
          <div class="detail-row-container d-flex flex-wrap payables-container">
            <div class="drc-left w-50">
              <div class="page-widget-label text-capitalize mt-2 mb-4">General Info</div>
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col style={{ width: "180px" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Title</td>
                    <td>
                      <input
                        value={props?.values?.title}
                        type="text"
                        class="form-control"
                        onChange={(e) => {
                          const data = { ...props.values };
                          data.title = e.target.value;
                          props.setValues(data);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableHighlightToday={true}
                          className="form-control"
                          value={props?.values?.created}
                          format="MM/DD/YYYY"
                          onChange={(e) => {
                            const values = { ...props.values };
                            const formatted = moment(e).format("MM/DD/YYYY");
                            values.created = formatted;
                            props.setValues(values);
                          }}
                          renderInput={(params) => <TextField {...params} error={false} />}
                          inputFormat="MM/dd/yyyy"
                        />
                      </LocalizationProvider>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Requestor<sup style={{ color: "Red" }}>*</sup>
                    </td>

                    <td>
                      <Select
                        className="basic-single"
                        value={defaultRequestor}
                        options={requestorOptions}
                        classNamePrefix="select-search "
                        getOptionLabel={(option) => option.name01}
                        getOptionValue={(option) => option.member_id}
                        name="requestor"
                        placeholder="Please Select a Requestor"
                        onChange={(e) => {
                          const data = { ...props.values };
                          data.req_id = e.member_id;
                          props.setValues(data);
                          setDefaultRequestor(e);
                        }}
                        styles={customStyles}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="payables-label">Listing</td>
                    <td>
                      {props?.auctionId}-{props?.fieldData?.auctTitle}
                    </td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>
                      <Select
                        className="basic-single"
                        value={defaultStatus}
                        options={statusOptions}
                        classNamePrefix="select-search "
                        getOptionLabel={(option) => option.DESCRIPTION}
                        getOptionValue={(option) => option.ID}
                        name="status"
                        placeholder="Please Select a Status"
                        onChange={(e) => {
                          const data = { ...props.values };
                          data.status_id = e.ID;
                          props.setValues(data);
                          setDefaultStatus(e);
                        }}
                        styles={customStyles}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Who are we paying? <sup style={{ color: "Red" }}>*</sup>
                    </td>
                    <td>
                      <Select
                        className="basic-single"
                        classNamePrefix="select-search"
                        value={defaultPaymentTo}
                        options={paymentToOptions}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        name="paymentTo"
                        placeholder="Please Select an Option"
                        onChange={(e) => {
                          const data = { ...props.values };
                          data.payment_to = e.value;
                          props.setValues(data);
                          setDefaultPaymentTo(e);
                          if (e.value !== 2) {
                            setDefaultBuyer({ name: "Start typing to search", user_id: "" });
                            setUserId(props?.fieldData?.sellerInfo?.s_user_id);
                            setShow(true);
                          } else {
                            setUserId("");
                          }
                        }}
                        styles={customStyles}
                      />
                    </td>
                  </tr>
                  {props?.values?.payment_to === 2 && (
                    <tr>
                      <td>
                        Buyer (Refund) <sup style={{ color: "Red" }}>*</sup>
                      </td>
                      <td>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions={[{ name: "Start typing to search", user_id: "" }, ...buyersOptions]}
                          value={defaultBuyer}
                          className="basic-single"
                          classNamePrefix="select-search"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.user_id}
                          loadOptions={props.loadBuyers}
                          name="buyers"
                          placeholder="Start typing to search"
                          styles={customStyles}
                          onChange={(e) => {
                            const data = { ...props.values };
                            data.b_email = e.email;
                            data.b_user_id = e.user_id;
                            setUserId(e.user_id);
                            setShow(true);
                            props.setValues(data);
                            setDefaultBuyer(e);
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>Amount (in USD)</td>
                    <td>
                      <div className="fw-medium d-flex align-items-center">
                        {props?.values?.req_amount && (
                          <span className="form-control text-danger" style={{ width: "0" }}>
                            $
                          </span>
                        )}
                        <input
                          id="amount"
                          value={props?.values?.req_amount}
                          type="text"
                          class="form-control text-danger fw-medium ms-0"
                          onChange={(e) => {
                            const data = { ...props.values };

                            const a = e.target.value;
                            data.req_amount = isNumber(a) ? a : a.includes(",") ? +a.split(",").join("") : a;
                            props.setValues(data);
                          }}
                          onBlur={(e) => {
                            const data = { ...props.values };
                            data.req_amount = isNumber(props.values?.req_amount)
                              ? new Intl.NumberFormat("en-US").format(props.values?.req_amount)
                              : props.values?.req_amount.includes(",")
                              ? new Intl.NumberFormat("en-US").format(+props.values?.req_amount.split(",").join(""))
                              : new Intl.NumberFormat("en-US").format(props.values?.req_amount);
                            props.setValues(data);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="payables-label">Negative Feedback</td>
                    <td>
                      <div class="form-check dark-tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          checked={props?.values?.is_negative_feedback}
                          onChange={(e) => {
                            const data = { ...props.values };
                            data.is_negative_feedback = e.target.checked;
                            props.setValues(data);
                          }}
                          id="is_negative_feedback"
                        />
                        <label class="form-check-label" for="is_negative_feedback"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="payables-label">Legal issues</td>
                    <td>
                      <div class="form-check dark-tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          checked={props?.values?.is_legal_issue}
                          onChange={(e) => {
                            const data = { ...props.values };
                            data.is_legal_issue = e.target.checked;
                            props.setValues(data);
                          }}
                          id="is_legal_issue"
                        />
                        <label class="form-check-label" for="is_legal_issue"></label>
                      </div>
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="payables-label">Category</td>
                    <td>{props?.fieldData?.description}</td>
                  </tr>
                  <tr>
                    <td className="payables-label">Inventory</td>
                    <td>
                      <div class="form-check dark-tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          checked={props?.values?.inventory}
                          onChange={(e) => {
                            const data = { ...props.values };
                            data.inventory = e.target.checked;
                            props.setValues(data);
                          }}
                          id="tick"
                        />
                        <label class="form-check-label" for="tick"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="payables-label">Condition</td>
                    <td>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: props?.fieldData?.condition,
                        }}
                      />
                    </td>
                  </tr> */}
                </tbody>
              </table>
              <div class="dividerTable mt-4 mb-4"></div>
              <div class="page-widget-label text-capitalize mb-4">Seller Info</div>
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col style={{ width: "180px" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td className="payables-label">Name</td>
                    <td>
                      {props?.fieldData?.sellerInfo?.name}
                      <br />
                      {props?.fieldData?.sellerInfo?.cid >= 1 ? <span style={{ color: "red" }}>(Enterprise)</span> : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="payables-label">Company</td>
                    <td>{props?.fieldData?.sellerInfo?.company_name}</td>
                  </tr>
                  <tr>
                    <td className="payables-label">Address</td>
                    <td>
                      {props?.fieldData?.sellerInfo?.address_line_1 &&
                        HTMLReactParser(`${props.fieldData?.sellerInfo?.address_line_1} <br />`)}
                      {props?.fieldData?.sellerInfo?.address_line_2 &&
                        HTMLReactParser(`${props.fieldData?.sellerInfo?.address_line_2} <br />`)}
                      {props?.fieldData?.sellerInfo?.city && HTMLReactParser(`${props.fieldData?.sellerInfo?.city}, `)}
                      {props?.fieldData?.sellerInfo?.st_abb &&
                        HTMLReactParser(`${props.fieldData?.sellerInfo?.st_abb}, `)}
                      {props?.fieldData?.sellerInfo?.zip && HTMLReactParser(`${props.fieldData?.sellerInfo?.zip}`)}
                      {props?.fieldData?.sellerInfo?.c_abb &&
                        HTMLReactParser(`<br />${props.fieldData?.sellerInfo?.c_abb}`)}
                    </td>
                  </tr>
                  <tr>
                    <td className="payables-label">File Number</td>
                    <td>{props?.fieldData?.file_number}</td>
                  </tr>
                  <tr>
                    <td className="payables-label">Terms</td>
                    <td>{props?.fieldData?.terms}</td>
                  </tr>
                </tbody>
              </table>
              <div class="dividerTable mt-4 mb-4"></div>
              <div class="page-widget-label text-capitalize mb-4">Buyer Info</div>
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col style={{ width: "180px" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Terms</td>
                    <td>{props?.fieldData?.invoiceInfo[0]?.terms ? props?.fieldData?.invoiceInfo[0]?.terms : ""}</td>
                  </tr>
                </tbody>
              </table>
              <div class="dividerTable mt-4 mb-4"></div>
              <div class="page-widget-label text-capitalize">Purchase Purpose</div>
              <div className="mt-4 mb-4">
                <textarea
                  value={props?.values?.purchase_purpose}
                  rows={8}
                  type="text"
                  class="w-100 form-control"
                  onChange={(e) => {
                    const data = { ...props.values };
                    data.purchase_purpose = e.target.value;
                    props.setValues(data);
                  }}
                />
              </div>
              <div class="dividerTable mt-4 mb-4"></div>
              <div class="page-widget-label text-capitalize">Special Instructions</div>
              <div className="mt-4 mb-4">
                <textarea
                  value={props?.values?.instructions}
                  rows={8}
                  type="text"
                  class="w-100 form-control"
                  onChange={(e) => {
                    const data = { ...props.values };
                    data.instructions = e.target.value;
                    props.setValues(data);
                  }}
                />
              </div>
            </div>
            <div class="drc-right w-50">
              {defaultPaymentTo.value !== 0 && (defaultPaymentTo.value === 2 ? defaultBuyer.user_id !== "" : true) && (
                <>
                  {completed && props?.values?.payment_to !== 3 ? (
                    isInvestmentAccessible ? (
                      <PaymentInfoComponent id={userId} show={show} />
                    ) : (
                      <div class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center h-50">
                        <span style={{ marginRight: "30px", marginLeft: "30px" }}>
                          Seller has added their payment instructions to their profile for accounting. Please fill out
                          remaining info on this payable and hit 'save' to complete set up of this payable.
                        </span>
                      </div>
                    )
                  ) : (
                    <>
                      <div class="page-widget-label text-capitalize mb-4">Payment Info</div>
                      <table class="table table-borderless align-middle table-left-align">
                        <colgroup>
                          <col style={{ width: "180px" }} />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td>Entity</td>
                            <td>
                              <Select
                                className="basic-single"
                                value={defaultEntity}
                                options={entityOptions}
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.value}
                                name="entity"
                                placeholder="Please Select an Entity"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.entity = e.name;
                                  props.setValues(data);
                                  setDefaultEntity(e);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Notes</td>
                            <td>
                              <textarea
                                value={props.values.notes}
                                rows={4}
                                type="text"
                                class="w-100 form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.notes = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Method</td>
                            <td>
                              <Select
                                className="basic-single"
                                value={defaultPaymentMethod}
                                options={paymentMethodOptions}
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.DESCRIPTION}
                                getOptionValue={(option) => option.ID}
                                name="method"
                                placeholder="Please Select a Method"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.payment_method = e.ID;
                                  props.setValues(data);
                                  setDefaultPaymentMethod(e);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                          {/* <tr>
                        <td>Amount (in USD)</td>
                        <td>
                          <div className="fw-medium d-flex align-items-center">
                            {props?.values?.req_amount && (
                              <span className="form-control text-danger" style={{ width: "0" }}>
                                $
                              </span>
                            )}
                            <input
                              id="amount"
                              value={props?.values?.req_amount}
                              type="text"
                              class="form-control text-danger fw-medium ms-0"
                              onChange={(e) => {
                                const data = { ...props.values };

                                const a = e.target.value;
                                data.req_amount = isNumber(a) ? a : a.includes(",") ? +a.split(",").join("") : a;
                                props.setValues(data);
                              }}
                              onBlur={(e) => {
                                const data = { ...props.values };
                                data.req_amount = isNumber(props.values?.req_amount)
                                  ? new Intl.NumberFormat("en-US").format(props.values?.req_amount)
                                  : props.values?.req_amount.includes(",")
                                  ? new Intl.NumberFormat("en-US").format(+props.values?.req_amount.split(",").join(""))
                                  : new Intl.NumberFormat("en-US").format(props.values?.req_amount);
                                props.setValues(data);
                              }}
                            />
                          </div>
                        </td>
                      </tr> */}
                          <tr>
                            <td>Terms</td>
                            <td>
                              <Select
                                className="basic-single"
                                value={defaultPaymentTerm}
                                options={paymentTermOptions}
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.DESCRIPTION}
                                getOptionValue={(option) => option.ID}
                                name="term"
                                placeholder="Please Select a Term"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.terms_id = e.ID;
                                  if (e.DESCRIPTION === "Due Date") {
                                    setShowDueDate(true);
                                  } else {
                                    setShowDueDate(false);
                                  }
                                  props.setValues(data);
                                  setDefaultPaymentTerm(e);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                          {showDueDate && (
                            <tr>
                              <td>Due Date</td>
                              <td>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    disableHighlightToday={true}
                                    className="form-control"
                                    value={
                                      props?.values?.due_date && moment(props?.values?.due_date).format("MM/DD/YYYY")
                                    }
                                    format="MM/DD/YYYY"
                                    onChange={(e) => {
                                      const values = { ...props.values };
                                      const formatted = moment(e).format("MM/DD/YYYY");
                                      values.due_date = formatted;
                                      props.setValues(values);
                                    }}
                                    renderInput={(params) => <TextField {...params} error={false} />}
                                    inputFormat="MM/dd/yyyy"
                                  />
                                </LocalizationProvider>
                              </td>
                            </tr>
                          )}
                          {/* <tr>
                        <td className="payables-label">Profit (in USD)</td>
                        <td className="text-success">
                          {props?.fieldData?.profit
                            ? "$" + new Intl.NumberFormat("en-US").format(props?.fieldData?.profit)
                            : "$0.00"}
                        </td>
                      </tr> */}
                          <tr>
                            <td>Payable To</td>
                            <td>
                              <input
                                value={props?.values?.pay_to}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.pay_to = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Send Check To</td>
                            <td>
                              <input
                                value={props?.values?.name}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.name = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>
                              <input
                                value={props?.values?.address1}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.address1 = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <input
                                value={props?.values?.address2}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.address2 = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>City</td>
                            <td>
                              <input
                                value={props?.values?.city}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.city = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Zip</td>
                            <td>
                              <input
                                value={props?.values?.zip}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.zip = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Country</td>
                            <td>
                              <Select
                                className="basic-single"
                                value={defaultCountry}
                                options={countriesOptions}
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.description}
                                getOptionValue={(option) => option.old}
                                name="country"
                                placeholder="Please Select a Country"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.p_country_id = e.old;
                                  data.st = "";
                                  props.setValues(data);
                                  setDefaultState("");
                                  setDefaultCountry(e);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>State</td>
                            <td>
                              <Select
                                className="basic-single"
                                value={defaultState}
                                options={
                                  defaultCountry?.old == 1001
                                    ? statesOptions.filter((option) => option.state_id != 1001)
                                    : [{ abb: "IT", state_id: "1001" }]
                                }
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.abb}
                                getOptionValue={(option) => option.state_id}
                                name="state"
                                placeholder="Please Select a State"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  if (e.state_id == "1") {
                                    data.st = "";
                                    props.setValues(data);
                                  } else {
                                    data.st = e.abb;
                                    props.setValues(data);
                                  }
                                  setDefaultState(e);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>C. Phone</td>
                            <td>
                              <input
                                value={props?.values?.c_phone}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.c_phone = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>C. Email</td>
                            <td>
                              <input
                                value={props?.values?.c_email}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.c_email = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Services</td>
                            <td>
                              <Select
                                className="basic-single"
                                value={defaultService}
                                options={serviceOptions}
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.DESCRIPTION}
                                getOptionValue={(option) => option.ID}
                                name="service"
                                placeholder="Please Select a Service"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.delivery_service = e.ID;
                                  props.setValues(data);
                                  setDefaultService(e);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Tracking</td>
                            <td>
                              <input
                                value={props?.values?.tracking_num}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.tracking_num = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="dividerTable mt-4 mb-4"></div>
                      <div class="page-widget-label text-capitalize mb-4">Wire Info</div>
                      <table class="table table-borderless align-middle table-left-align">
                        <colgroup>
                          <col style={{ width: "180px" }} />
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td>Bank Name</td>
                            <td>
                              <input
                                value={props?.values?.bank_name}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.bank_name = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>
                              <input
                                value={props?.values?.bank_address1}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.bank_address1 = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <input
                                value={props?.values?.bank_address2}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.bank_address2 = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>City</td>
                            <td>
                              <input
                                value={props?.values?.bank_city}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.bank_city = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Zip</td>
                            <td>
                              <input
                                value={props?.values?.bank_zip}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.bank_zip = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Account</td>
                            <td>
                              <input
                                value={props?.values?.bank_account}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.bank_account = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Country</td>
                            <td>
                              <Select
                                className="basic-single"
                                value={defaultBankCountry}
                                options={countriesOptions}
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.description}
                                getOptionValue={(option) => option.old}
                                name="country"
                                placeholder="Please Select a Country"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.country_id = e.old;
                                  data.bank_st = "";
                                  props.setValues(data);
                                  setDefaultBankState("");
                                  setDefaultBankCountry(e);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>State</td>
                            <td>
                              <Select
                                className="basic-single"
                                value={defaultBankState}
                                options={
                                  defaultBankCountry?.old == 1001
                                    ? statesOptions.filter((option) => option.state_id != 1001)
                                    : [{ abb: "IT", state_id: "1001" }]
                                }
                                classNamePrefix="select-search "
                                getOptionLabel={(option) => option.abb}
                                getOptionValue={(option) => option.state_id}
                                name="state"
                                placeholder={"Please Select a State"}
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  if (e.state_id == "1") {
                                    data.bank_st = "";
                                    props.setValues(data);
                                  } else {
                                    data.bank_st = e.abb;
                                    props.setValues(data);
                                  }
                                  setDefaultBankState(e);
                                }}
                                styles={customStyles}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Swift Code</td>
                            <td>
                              <input
                                value={props?.values?.swift_code}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.swift_code = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>ABA</td>
                            <td>
                              <input
                                value={props?.values?.aba_number}
                                type="text"
                                class="form-control"
                                onChange={(e) => {
                                  const data = { ...props.values };
                                  data.aba_number = e.target.value;
                                  props.setValues(data);
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}

                  {/* <div class="dividerTable mt-4 mb-4"></div>
              <div class="page-widget-label text-capitalize mb-4">Delivery Info</div>
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col style={{ width: "180px" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Method</td>
                    <td>
                      <Select
                        className="basic-single"
                        value={defaultDeliveryMethod}
                        options={deliveryMethodOptions}
                        classNamePrefix="select-search "
                        getOptionLabel={(option) => option.DESCRIPTION}
                        getOptionValue={(option) => option.ID}
                        name="mthod"
                        placeholder="Please Select a Method"
                        onChange={(e) => {
                          const data = { ...props.values };
                          data.delivery_method = e.ID;
                          props.setValues(data);
                          setDefaultDeliveryMethod(e);
                        }}
                        styles={customStyles}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Info</td>
                    <td>
                      <Select
                        className="basic-single"
                        value={defaultDeliveryInfo}
                        options={deliveryInfoOptions}
                        classNamePrefix="select-search "
                        getOptionLabel={(option) => option.DESCRIPTION}
                        getOptionValue={(option) => option.ID}
                        name="info"
                        placeholder="Please Select an Info"
                        onChange={(e) => {
                          const data = { ...props.values };
                          data.delivery_info = e.ID;
                          props.setValues(data);
                          setDefaultDeliveryInfo(e);
                        }}
                        styles={customStyles}
                      />
                    </td>
                  </tr>
                </tbody>
              </table> */}
                </>
              )}
            </div>
          </div>
          <div class="dividerTable mt-4 mb-4"></div>
          <div class="page-widget-label text-capitalize mb-4">Invoices</div>
          <div class="table-responsive mb-4">
            <table class="large-table">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>Invoice</th>
                  <th>Buyer</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {props?.fieldData?.invoiceInfo && props?.fieldData?.invoiceInfo.length > 0 ? (
                  props?.fieldData?.invoiceInfo.map((item) => (
                    <tr>
                      <td>
                        <Link
                          to={`/invoice/view-invoice?auctionId=${props.auctionId}&id=${item.id}&date=${item.date_created}&inv_num=${item.inv_num}`}
                          state={{ a: "dk" }}
                          target="_blank"
                        >
                          {item.inv_num}
                        </Link>
                      </td>
                      <td>{item?.name}</td>
                      <td>
                        <DateFormat date={item?.date_created} />
                      </td>
                      <td>{item?.amount ? "$" + new Intl.NumberFormat("en-US").format(item?.amount) : "$0.00"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No Records Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={props.onHide}
          >
            Cancel
          </button>
          {!defaultRequestor?.member_id ||
          defaultPaymentTo?.value == 0 ||
          (props?.values?.payment_to == 2 &&
            // !props?.values.b_user_id &&
            // !props?.values.b_email&&
            !defaultBuyer.user_id &&
            defaultBuyer.name == "Start typing to search") ? (
            <button className="btn btn-default btn-disabled">Save</button>
          ) : (
            <NextButton
              classData="btn btn-default"
              handleSubmit={() => (props.type == "add" ? props.addNewPayables() : props.editPayable())}
              label={"Save"}
              loading={props.loading}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddPayablesModal;
