import React, { useEffect, useState } from "react";
import questions, {
  deleteQuestion,
  getQuestionDetail,
  getQuestions,
  questionDetails,
  questionFetched,
  updateQuestion,
} from "../../../store/questions";
import { connect } from "react-redux";
import Edit from "../../../include/images/edit-18x18.svg";
import Delete from "../../../include/images/trash-18x18.svg";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import DeleteModal from "../../../layouts/modals/deleteModal";
import QuestionModal from "../../../layouts/modals/questionModal/questionModal";
import Skeleton from "react-loading-skeleton";
import DateFormat from "../../../common/DateFormat/dateFormat";
import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import helpCircle from "../../../include/images/help-circle.svg";
import { getProductCount } from "../../../store/listingProduct";
import { getProfile } from "../../../utils.js/localStorageServices";
import { getCommentsAndNotes } from "../../../store/commentsAndNotes";
import { getPendingTasks } from "../../../store/tasks";

const Questions = (props) => {
  const count = props?.count?.allQuestion;
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [show, setShow] = useState(false);
  const [question, setQuestion] = useState({
    auction_id: "",
    title: "",
    name01: "",
    a_email: "",
    q_id: null,
    message: "",
    seller_response: "",
    name: "",
    company: "",
    email: "",
    phone: "",
    member_id: "",
    response: "",
    response01: "",
  });

  const member = getProfile();

  //show question
  const showQuestions = () => {
    props.refStart();
    props.getQuestions(props.auctionId, (res) => {
      if (res.status === 200) {
        props.refStop();
        setShow(true);
      } else {
        props.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const questionData =
    props && props.questionDetails && props.questionDetails.questions && props.questionDetails.questions.data;

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleQuestionsModal = () => {
    setShowQuestionModal(!showQuestionModal);
  };

  const reloadQuestions = () => {
    props.getQuestions(props.auctionId, (res) => {
      if (res?.status === 200) {
      } else {
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Delete Handler
  const handleDelete = () => {
    setLoadingDelete(true);
    props.refStart();
    const id = props.auctionId;
    const q_id = questionId;
    props.deleteQuestion(id, q_id, (res) => {
      if (res.status === 200) {
        reloadQuestions();
        props?.getProductCount(id, (res) => {});
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
        setShowQuestionModal(false);
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Get Question Detail
  const getQuestionDetail = (id) => {
    setLoading(true);
    props?.refStart();
    props.getQuestionDetail(props.auctionId, id, (res) => {
      if (res.status === 200) {
        setQuestion({
          auction_id: res?.data?.data?.auction_id,
          title: res?.data?.data?.title,
          name01: res?.data?.data?.name01,
          a_email: res?.data?.data?.a_email,
          q_id: res?.data?.data?.q_id,
          message: res?.data?.data?.message,
          seller_response: res?.data?.data?.seller_response,
          name: res?.data?.data?.name,
          company: res?.data?.data?.company,
          email: res?.data?.data?.email,
          phone: res?.data?.data?.phone,
          member_id: res?.data?.data?.member_id,
        });
        props?.refStop();
        setLoading(false);
        setShowQuestionModal(true);
      } else {
        props?.refStop();
        setLoading(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Forward to seller
  const forwardToSeller = () => {
    setLoading(true);
    props.refStart();
    const data = {};
    data.question = question.message;
    data.q_id = question.q_id;
    props.updateQuestion(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoading(false);
        setShowQuestionModal(false);
        reloadQuestions();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoading(false);
        setShowQuestionModal(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Mark Answered
  const markAnswered = () => {
    setLoading(true);
    props.refStart();
    const data = {};
    data.answer_question = question.q_id;
    props.updateQuestion(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props?.getProductCount(props?.auctionId, (res) => {});
        props.refStop();
        setLoading(false);
        setShowQuestionModal(false);
        reloadQuestions();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoading(false);
        setShowQuestionModal(false);
        toast(<AlertError message={res && res.data && res.data.messgae ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Send Market Maker Response
  const sendResponse = () => {
    setLoading(true);
    props.refStart();
    const data = {};
    data.response = question.response ? question.response : "";
    data.q_id = question.q_id ? question.q_id : "";
    data.mm_name = member?.name01 ? member?.name01 : "";
    data.mm_email = member?.email ? member?.email : "";
    data.email = question.email ? question.email : "";
    props.updateQuestion(props.auctionId, data, (res) => {
      if (res.status === 200) {
        reloadQuestions();
        props.getCommentsAndNotes(props.auctionId, {}, (res) => {});
        props.getPendingTasks(props.auctionId, (res) => {});
        props.refStop();
        setLoading(false);
        setShowQuestionModal(false);
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message="Message Shouldn't be Empty" />);
      }
    });
  };

  //Send Seller Response
  const sendResponseSeller = () => {
    setLoading(true);
    props.refStart();
    const data = {};
    data.response = question.response01 ? question.response01 : "";
    data.q_id = question.q_id ? question.q_id : "";
    data.mm_name = question.name01 ? question.name01 : "";
    data.mm_email = question.a_email ? question.a_email : "";
    data.email = question.email ? (question.email == "undefined" ? "N/A" : question.email) : "N/A";
    props.updateQuestion(props.auctionId, data, (res) => {
      if (res.status === 200) {
        props.refStop();
        setLoading(false);
        setShowQuestionModal(false);
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoading(false);
        toast(<AlertError message="Message Shouldn't be Empty" />);
      }
    });
  };

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div class="page-widget-head d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
            <div
              class="page-widget-label text-capitalize cursor-pointer"
              onClick={() => (show ? setShow(false) : showQuestions())}
            >
              Questions {!props?.loadingMain && (count > 0 ? `(${count})` : "(0)")}
            </div>
          </div>
          <li
            className="badge-item cursor-pointer"
            onClick={() => (show ? setShow(false) : showQuestions())}
            style={{ marginLeft: "10px" }}
          >
            <div className="badge-box" style={{ background: "#DADEE0" }}>
              {show ? "Hide" : "Show"}
            </div>
          </li>
        </div>
      </div>
      {show ? (
        <div class={questionData?.length > 0 && "page-widget-body mt-3"}>
          <div class="table-responsive">
            <table class="large-table">
              <colgroup>
                <col style={{ width: "28px" }} />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col style={{ width: "28px" }} />
                <col style={{ width: "28px" }} />
              </colgroup>
              {props.loadingMain ? (
                <>
                  <thead>
                    <tr>
                      <th>
                        <Skeleton />
                      </th>
                      <th>
                        <Skeleton />
                      </th>
                      <th>
                        <Skeleton />
                      </th>
                      <th>
                        <Skeleton />
                      </th>
                      <th>
                        <Skeleton />
                      </th>
                      <th>
                        <Skeleton />
                      </th>
                      <th>
                        <Skeleton />
                      </th>
                      <th>
                        <Skeleton />
                      </th>
                      <th>
                        <Skeleton />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  </tbody>
                </>
              ) : (
                <>
                  {questionData?.length > 0 && (
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Question</th>
                        <th>Posted on</th>
                        <th>Posted By</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {questionData?.map((val, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td
                            style={{
                              whiteSpace: "normal",
                              textAlign: "left",
                            }}
                          >
                            {val.message ? val.message : "N/A"}
                          </td>
                          <td>{val.date_created ? <DateFormat date={val.date_created} /> : "N/A"}</td>
                          <td>{val.name ? `${val.name}-${val.company}` : "N/A"}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <a className="green-text" href={`mailto:${val.email}`}>
                                {val.email ? val.email : "N/A"}
                              </a>
                              {val.email && (
                                <div className="d-text-item fw-medium" style={{ width: "24px" }}>
                                  {val?.emailstatuscode === 0 ? (
                                    <img src={greenEmail} alt="" title="Email OK" className="img-fluid" />
                                  ) : val?.emailstatuscode === 1 ? (
                                    <img
                                      src={purpleEmail}
                                      alt=""
                                      title="Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)"
                                      className="img-fluid"
                                    />
                                  ) : val?.emailstatuscode === 2 ? (
                                    <img src={invalid} alt="" title="Email Invalid" className="img-fluid" />
                                  ) : val?.emailstatuscode === 3 ? (
                                    <img
                                      src={spamEmail}
                                      alt=""
                                      title="Email Blocked by Spam or Firewall"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img src={notVerified} alt="" title="Email Not Verified" className="img-fluid" />
                                  )}
                                </div>
                              )}
                            </div>
                          </td>
                          <td>{val.phone ? val.phone : "N/A"}</td>
                          <td>
                            {val.qs_id === 1
                              ? "Needs Response"
                              : val.qs_id === 2
                              ? "Answered"
                              : val.qs_id === 3
                              ? "To Seller"
                              : val.qs_id === 4
                              ? "Seller Responded"
                              : "N/A"}
                          </td>
                          <td>
                            <button type="button" onClick={() => getQuestionDetail(val.q_id)}>
                              <img src={Edit} alt="Edit" />
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              onClick={() => {
                                setQuestionId(val.q_id);
                                toggleDeleteModal();
                              }}
                            >
                              <img src={Delete} alt="Delete" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              )}
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
      <QuestionModal
        getQuestionDetail={props.getQuestionDetail}
        show={showQuestionModal}
        onHide={toggleQuestionsModal}
        question={question}
        setQuestion={setQuestion}
        questionId={questionId}
        setQuestionId={setQuestionId}
        forwardToSeller={forwardToSeller}
        markAnswered={markAnswered}
        sendResponse={sendResponse}
        sendResponseSeller={sendResponseSeller}
        showDeleteModal={showDeleteModal}
        toggleDeleteModal={toggleDeleteModal}
        loadingDelete={loadingDelete}
        loading={loading}
        deleteHandler={handleDelete}
      />
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={handleDelete}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  //global states for storing api data
  getQuestions: (params, callback) => dispatch(getQuestions(params, callback)),
  deleteQuestion: (params, questionId, callback) => dispatch(deleteQuestion(params, questionId, callback)),
  getQuestionDetail: (params, questionId, callback) => dispatch(getQuestionDetail(params, questionId, callback)),
  updateQuestion: (params, data, callback) => dispatch(updateQuestion(params, data, callback)),
  getProductCount: (id, callback) => dispatch(getProductCount(id, callback)),
  getCommentsAndNotes: (id, params, callback) => dispatch(getCommentsAndNotes(id, params, callback)),
  getPendingTasks: (id, callback) => dispatch(getPendingTasks(id, callback)),

  //global state for manipulating data
  questionFetched: (data) => dispatch(questionFetched(data)),
});
const mapStateToProps = (state) => ({
  questionDetails: questionDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Questions));
