import React, { useState, useEffect, useRef } from "react";
import { Offcanvas } from "react-bootstrap";
import crossIcon from "../../../include/images/cross-icon.svg";
import default_logo from "../../../include/images/customer_default_logo.svg";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { SketchPicker } from "react-color";
import { connect } from "react-redux";
import { saveCustomizeDashboard } from "../../../store/primarySeller";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import LoadingBar from "react-top-loading-bar";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const DashboardStylinng = ({ id, setCustomizeDashobard, data, sellerStatus, selectedSellerOption, ...props }) => {
  const ref = useRef(null);
  const [primaryColor, setPrimaryColor] = useState("#227300");
  const [secondaryColor, setSecondaryColor] = useState("#47AD1D");

  const [showPickerPrimary, setShowPickerPrimary] = useState(false);
  const [showPickerSecondary, setShowPickerSecondary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [sellerLogo, setSellerLogo] = useState("");

  const handleImageUploader = () => {
    if (!selectedImage && sellerLogo) {
      setLoading(true);
      ref && ref.current && ref.current.continuousStart();

      const payload = {
        dashboard_logo: data?.dashboard_logo ? sellerLogo : "/co/logos/" + sellerLogo,
        show_custom_dashboard:
          (selectedSellerOption == 1 || selectedSellerOption == 2 || selectedSellerOption == 3) &&
          sellerStatus !== "pending" &&
          sellerStatus !== "rejected"
            ? true
            : false,
        dashboard_color: primaryColor,
        dashboard_secondary_color: secondaryColor,
      };
      props.saveCustomizeDashboard(id, payload, (res) => {
        if (res.status === 200) {
          return (
            ref && ref.current && ref.current.complete(),
            setLoading(false),
            setCustomizeDashobard(true),
            props?.onHide(),
            toast(<AlertSuccess message={"Information Saved"} />)
          );
        } else {
          return (
            ref && ref.current && ref.current.complete(),
            setLoading(false),
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something went wrong"} />,
            )
          );
        }
      });
    } else {
      setLoading(true);
      ref && ref.current && ref.current.continuousStart();

      const file = selectedImage;

      const fSize = Math.round(file.size / 1048576);
      const fType = file.type;
      const ext = file.name.split(".").pop();
      if (fSize > 25) {
        return (
          toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />),
          ref && ref.current && ref.current.complete(),
          setLoading(false)
        );
      } else if (!["image/jpeg", "image/jpg", "image/png"].includes(fType)) {
        return (
          toast(
            <AlertError message="Image is not of correct format and hence cannot be uploaded. Valid image formats are jpeg, jpg, and png." />,
          ),
          ref && ref.current && ref.current.complete(),
          setLoading(false)
        );
      } else {
        const fileName = "newSalvex/" + uuidv4() + "." + ext;
        Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            setLoading(false);

            toast(<AlertError message={err && err.message ? err.message : "Something went wrong"} />);
          },
        }).then((result) => {
          const data = {
            dashboard_logo: "public/" + result.key,
            show_custom_dashboard:
              (selectedSellerOption == 1 || selectedSellerOption == 2 || selectedSellerOption == 3) &&
              sellerStatus !== "pending" &&
              sellerStatus !== "rejected"
                ? true
                : false,
            dashboard_color: primaryColor,
            dashboard_secondary_color: secondaryColor,
            upload_on_s3: true,
          };
          props.saveCustomizeDashboard(id, data, (res) => {
            if (res.status === 200) {
              return (
                ref && ref.current && ref.current.complete(),
                setLoading(false),
                setCustomizeDashobard(true),
                props?.onHide(),
                toast(<AlertSuccess message={"Information Saved"} />)
              );
            } else {
              return (
                ref && ref.current && ref.current.complete(),
                setLoading(false),
                toast(
                  <AlertError
                    message={res && res.data && res.data.message ? res.data.message : "Something went wrong"}
                  />,
                )
              );
            }
          });
        });
      }
    }
  };

  useEffect(() => {
    if (data?.dashboard_color) {
      setPrimaryColor(data?.dashboard_color);
    }
    if (data?.dashboard_secondary_color) {
      setSecondaryColor(data?.dashboard_secondary_color);
    }
    if (data?.dashboard_logo?.length > 0) {
      setSellerLogo(data?.dashboard_logo);
    } else if (data?.picture?.proc_logo?.length > 0) {
      setSellerLogo(data?.picture?.proc_logo);
    }
  }, [data]);

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Offcanvas
        show={props.show}
        onHide={() => props.onHide()}
        placement="end"
        className="offcanvas offcanvas-end custom-offcanvas-sm "
        backdropClassName="upload-images-backdrop"
        tabindex="-1"
        id="userFilter"
        enforceFocus={false}
      >
        <Offcanvas.Header className="offcanvas-header ps-4" style={{ marginLeft: "7px" }}>
          <h5 class="offcanvas-title">Customize Dashboard</h5>
          <button type="button" className="btn-close text-reset" onClick={() => props.onHide()}></button>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body co-body">
          <div class="form-group">
            <label for="keyword" class="form-label">
              Logo
            </label>
            <div class="upload-lg-button position-relative">
              <span class="file-input">
                <input
                  type="file"
                  id="inputGroupFile02"
                  accept={`.jpg,.jpeg,.png`}
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                  onClick={(e) => (e.target.value = null)}
                />
                <span id="upload-file-name" class="d-flex align-items-center">
                  {selectedImage?.name
                    ? selectedImage?.name?.length > 50
                      ? selectedImage?.name?.substring(0, 50)
                      : selectedImage?.name
                    : "Seller Logo"}
                  {selectedImage ? (
                    <img class="cross-icon-box" src={crossIcon} alt="" onClick={() => setSelectedImage(null)} />
                  ) : (
                    ""
                  )}
                </span>
              </span>
            </div>
            {
              <img
                style={{ width: "100px", marginTop: "15px" }}
                src={
                  selectedImage
                    ? `${URL.createObjectURL(selectedImage)}`
                    : data?.dashboard_logo
                    ? process.env.REACT_APP_MEDIA_URL + "/" + sellerLogo
                    : process.env.REACT_APP_MEDIA_URL + "/co/logos/" + sellerLogo
                }
              />
            }
          </div>
          <div class="form-group">
            <label for="primary" class="form-label">
              Primary Color
            </label>
            {showPickerPrimary && (
              <SketchPicker
                color={primaryColor}
                onChangeComplete={(newColor) => {
                  setPrimaryColor(newColor.hex);
                  setShowPickerPrimary(false);
                }}
              />
            )}
            <input
              type="text"
              class="form-control"
              id="primary"
              value={primaryColor}
              readOnly // Prevent manual editing
              onClick={() => setShowPickerPrimary(!showPickerPrimary)}
              style={{
                backgroundImage: `linear-gradient(to right, ${primaryColor} 100%, transparent 100%)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <div class="form-group">
            <label for="secondary" class="form-label">
              Secondary Color
            </label>
            {showPickerSecondary && (
              <SketchPicker
                color={secondaryColor}
                onChangeComplete={(newColor) => {
                  setSecondaryColor(newColor.hex);
                  setShowPickerSecondary(false);
                }}
              />
            )}
            <input
              type="text"
              class="form-control"
              id="secondary"
              value={secondaryColor}
              readOnly // Prevent manual editing
              onClick={() => setShowPickerSecondary(!showPickerSecondary)}
              style={{
                backgroundImage: `linear-gradient(to right, ${secondaryColor} 100%, transparent 100%)`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                border: "1px solid #ccc",
              }}
            />
          </div>
        </Offcanvas.Body>
        <div class="offcanvas-footer">
          <a
            href="javascript:void(0)"
            class={`btn btn-default btn-block ${
              (loading || (!selectedImage && !sellerLogo) || !primaryColor || !secondaryColor) && "disabled"
            }`}
            onClick={() => handleImageUploader()}
          >
            Save
          </a>
        </div>
      </Offcanvas>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  saveCustomizeDashboard: (id, data, callback) => dispatch(saveCustomizeDashboard(id, data, callback)),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardStylinng);
