import React, { useEffect, useRef, useState } from "react";

//Images

import save from "../../../include/images/save.svg";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "react-loading-skeleton/dist/skeleton.css";
import { v4 as uuidv4 } from "uuid";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import { toast } from "react-toastify";
import { dataURLtoFile } from "../../../common/editImage/dataURLtoFile";
import { Amplify, Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import {
  deleteListingImage,
  getListingMedia,
  listingImageReceived,
  listingImages,
  markAsPrimary,
  primaryPicture,
  updateListingTitle,
  uploadListingImages,
} from "../../../store/listingsMedia";
import { detailListing, listingDetailReceived } from "../../../store/listings";
import { getModals, setUploadImage } from "../../../store/modal";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_UPLOAD_BUCKET,
    region: "us-east-1",
  },
});

const publicPicturesHoc = (WrappedComp) => {
  const NewComp = ({
    refStart,
    refStop,
    loadingMain,
    auctionId,
    listingDetail,
    categoryName,
    detail,
    scrollToView,
    isAviationCategory,
    locationFilled,
    saleType,
    id,
    showMessages,
    toggleMessages,
    toggleChat,
    loadingChats,
    selectedChat,
    showChats,
    setSelectedChat,
    setShowSidebarOffcanvas,
    uploadImageModal,
    setUploadImageModal,
    ...props
  }) => {
    const componentRef = useRef(null);
    const componentRef2 = useRef(null);
    //states
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUploaded, setImageUploaded] = useState([]);
    const [progress, setProgress] = useState("");
    const [editedFile, setEditedFile] = useState([]);
    const [optionType, setOptionType] = useState("");
    const [optionTypeId, setOptionTypeId] = useState("");
    const [title, setTitle] = useState("");
    const [imgData, setImgData] = useState({});

    //loading Images states

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [loadingTitleUpdate, setLoadingTitleUpdate] = useState(false);

    const [loadingDelete, setLoadingDelete] = useState(false);

    //Modal States
    // const [uploadImageModal, setUploadImageModal] = useState(false);
    const [imageGalleryModal, setImageGalleryModal] = useState(false);
    const [startIndex, setStartIndex] = useState(null);

    const getMediaData = props.getMediaData;
    const primaryPicture = props.getMediaData && props.getMediaData.picture_id;

    //Popover
    const popoverClick =
      optionType === "edit" ? (
        <Popover
          id="popover-positioned-top"
          title="Popover top"
          bsPrefix=" popover custom-popover border-0 rounded-0"
          onClick={(e) => e.stopPropagation()}
        >
          <Popover.Body>
            <div class="d-flex align-content-center">
              <input
                type="text"
                placeholder="Enter Image Title"
                class="form-control flex-grow-1"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <button
                type="button"
                class={`addClickButton d-flex align-items-center justify-content-center ${
                  loadingTitleUpdate ? "btn-disabled " : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSaveTitle();
                  // setShowBackdrop(false);
                }}
              >
                <img src={save} alt="" />
              </button>
            </div>
          </Popover.Body>
        </Popover>
      ) : (
        <Popover></Popover>
      );

    //Reload Images
    const reloadImages = () => {
      props.listingImages(auctionId, (res) => {
        if (res && res.status === 200) {
          refStop();
        } else {
          refStop();
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    };

    //Image Gallery Toggle Function
    const toggleImageGalleryModal = () => {
      setImageGalleryModal(!imageGalleryModal);
      if (uploadImageModal) {
        setUploadImageModal(!uploadImageModal);
      }
    };

    //Image Upload Toggle Function
    const toggleUploadImageModal = () => {
      setUploadImageModal(!uploadImageModal);
      setOptionType("");
      setOptionTypeId("");
      setImageUploaded("");
      setSelectedImage("");
      setEditedFile("");
    };

    //To set images in image gallery modal

    useEffect(() => {
      if (
        getMediaData &&
        getMediaData.listingImages &&
        getMediaData.listingImages.pics &&
        getMediaData.listingImages.pics.length
      ) {
        let items = [];
        getMediaData.listingImages.pics.map((ep) =>
          items.push({
            original:
              ep.s_path.substring(0, 5) === "https"
                ? `${ep.s_path}${ep.filename}_lp.${ep.suffix}`
                : `${process.env.REACT_APP_MEDIA_URL}${ep.s_path}${ep.filename}_lp.${ep.suffix.toLowerCase()}`,
            thumbnail:
              ep.s_path.substring(0, 5) === "https"
                ? `${ep.s_path}${ep.filename}_lp.${ep.suffix}`
                : `${process.env.REACT_APP_MEDIA_URL}${ep.s_path}${ep.filename}_lp.${ep.suffix.toLowerCase()}`,
          }),
        );
        setImgData(items);
      }
    }, [getMediaData && getMediaData.listingImages && getMediaData.listingImages.pics]);

    //Images selection while uploading
    const onChangeImageUpload = (e) => {
      setImageUploaded([...imageUploaded, ...e.target.files]);
    };

    //Image Uploading Function
    const handleImageUploader = () => {
      const e = imageUploaded;
      if (imageUploaded.length) {
        setLoadingUpload(true);
        setProgress("uploading");
        refStart();
      }
      for (let i = 0; i < imageUploaded.length; i++) {
        const file = imageUploaded[i];

        const fSize = Math.round(file.size / 1048576);
        const fType = file.type;
        const ext = file.name.split(".").pop();
        if (fSize > 25) {
          return (
            toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 25MB." />),
            refStop(),
            setLoadingUpload(false),
            setProgress("error")
          );
        } else if (!["image/jpeg", "image/jpg", "image/png"].includes(fType)) {
          return (
            toast(
              <AlertError message="Image is not of correct format and hence cannot be uploaded. Valid image formats are jpeg, jpg, and png." />,
            ),
            refStop(),
            setLoadingUpload(false),
            setProgress("error")
          );
        } else {
          const fileName = "newSalvex/" + uuidv4() + "." + ext;
          Storage.put(fileName, file, {
            completeCallback: (event) => {},
            progressCallback: (progress) => {},
            errorCallback: (err) => {
              setLoadingUpload(false);

              toast(<AlertError message={err && err.message ? err.message : "Something went wrong"} />);
            },
          }).then((result) => {
            const data = {
              image: "public/" + result.key,
              picture_id: result.picture_id,
            };
            props.uploadListingImages(auctionId, data, (res) => {
              if (res.status === 200) {
                reloadImages();
                if (i > 0 && i === imageUploaded.length - 1 && res.status === 200) {
                  return (
                    refStop(),
                    setLoadingUpload(false),
                    setProgress("completed"),
                    setImageUploaded([]),
                    setTimeout(() => setProgress(""), 800),
                    toast(<AlertSuccess message={"Information Saved"} />)
                  );
                } else if (i == 0 && imageUploaded.length === 1) {
                  return (
                    refStop(),
                    setLoadingUpload(false),
                    setProgress("completed"),
                    setImageUploaded([]),
                    setTimeout(() => setProgress(""), 800),
                    toast(<AlertSuccess message={"Information Saved"} />)
                  );
                }
              } else {
                return (
                  setLoadingUpload(false),
                  setProgress("error"),
                  refStop(),
                  toast(
                    <AlertError
                      message={res && res.data && res.data.message ? res.data.message : "Something went wrong"}
                    />,
                  )
                );
              }
            });
          });
        }
      }
    };

    //Edit Image Function
    const handleEditImageUploader = async () => {
      if (editedFile.length > 0) {
        setProgress("uploading");
        refStart();
        const file = dataURLtoFile(editedFile, `salvex`);

        setLoadingUpload(true);

        const fileName = "newSalvex/" + uuidv4() + ".jpeg";
        Storage.put(fileName, file, {
          completeCallback: (event) => {},
          progressCallback: (progress) => {},
          errorCallback: (err) => {
            return (
              refStop(),
              setLoadingUpload(false),
              setProgress("error"),
              toast(<AlertError message={err && err.message ? err.message : "Something went wrong"} />)
            );
          },
        }).then((result) => {
          const data = {
            image: "public/" + result.key,
            picture_id: selectedImage.picture_id,
          };
          props.uploadListingImages(auctionId, data, (res) => {
            if (res.status === 200) {
              setLoadingUpload(false);
              refStop();
              setOptionType("");
              setEditedFile([]);

              reloadImages();
              setProgress("completed");
              setTimeout(() => setProgress(""), 800);
              toast(<AlertSuccess message={"Information Saved"} />);
            } else {
              setProgress("error");
              setLoadingUpload(false);
              refStop();
              toast(
                <AlertError
                  message={res && res.data && res.data.message ? res.data.message : "Something went wrong"}
                />,
              );
            }
          });
        });
      } else {
        if (optionType === "rotate") {
          toast(
            <AlertError
              message={"No changes in image detected. There must be some changes inorder to update the old image."}
            />,
          );
        } else if (optionType === "crop") {
          toast(<AlertError message={"This is default selected area. You need to select desired area to proceed."} />);
        } else if (optionType === "scribble") {
          toast(
            <AlertError
              message={"No changes in image detected. There must be some changes inorder to update the old image."}
            />,
          );
        }
      }
    };

    //set Image caption
    const handleSaveTitle = () => {
      if (optionType === "edit") {
        const data = {
          caption: title ? title : "",
          picture_id: optionTypeId,
        };
        refStart();
        setLoadingTitleUpdate(true);
        props.updateListingTitle(auctionId, data, (res) => {
          if (res && res.status === 200) {
            const img = {
              ...getMediaData.listingImages.pics.filter((item) => item.picture_id === optionTypeId)[0],
            };
            img.caption = title;
            const pics = [img, ...getMediaData.listingImages.pics.filter((item) => item.picture_id !== optionTypeId)];
            props.listingImageReceived({ pics });
            refStop();
            setTitle("");
            if (uploadImageModal) {
              componentRef.current.click();
            } else {
              componentRef2.current.click();
            }

            setOptionType("");
            setOptionTypeId("");
            setLoadingTitleUpdate(false);
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            refStop();
            setTitle("");
            setOptionType("");
            setOptionTypeId("");
            setLoadingTitleUpdate(false);
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      }
    };

    //delete image
    const deleteHandler = () => {
      setLoadingDelete(true);
      refStart();
      if (optionType === "delete") {
        const id = selectedImage && selectedImage.picture_id;
        props.deleteListingImage(id, (res) => {
          if (res.status === 200) {
            const pics = [...getMediaData.listingImages.pics.filter((item) => item.picture_id !== id)];
            props?.detailListing(auctionId, (res) => {});
            props.listingImageReceived({ pics });
            refStop();
            setLoadingDelete(false);
            setOptionType("");
            setSelectedImage({});
            toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            refStop();
            setLoadingDelete(false);
            toast(
              <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
            );
          }
        });
      }
    };

    //Primary Picture
    const handlePrimaryPicture = (item) => {
      refStart();

      const data = {
        picture_id: item.picture_id,
      };
      props.markAsPrimary(auctionId, data, (res) => {
        if (res.status === 200) {
          const img = item.picture_id;
          props.primaryPicture(img);
          props?.detailListing(auctionId, (res) => {});
          refStop();
          toast(<AlertSuccess message={"Information Saved"} />);
        } else {
          refStop();
          toast(
            <AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />,
          );
        }
      });
    };

    useEffect(() => {
      if (optionType === "scribble" && editedFile.length > 0) {
        refStart();
        handleEditImageUploader();
      }
    }, [editedFile]);

    const data = getMediaData && getMediaData.listingImages;

    const primaryImage =
      data && data.pics && data.pics.length > 0 && data.pics.filter((item) => item.picture_id === primaryPicture)[0];

    const newListingImages = primaryImage
      ? data.pics.filter((item) => item.picture_id !== primaryPicture)
      : data && data.pics;

    return (
      <WrappedComp
        refStart={refStart}
        refStop={refStop}
        id={id}
        loadingMain={loadingMain}
        auctionId={auctionId}
        listingDetail={listingDetail}
        getMediaData={getMediaData}
        componentRef2={componentRef2}
        primaryImage={primaryImage}
        setSelectedImage={setSelectedImage}
        setStartIndex={setStartIndex}
        toggleImageGalleryModal={toggleImageGalleryModal}
        handlePrimaryPicture={handlePrimaryPicture}
        popoverClick={popoverClick}
        setOptionType={setOptionType}
        optionType={optionType}
        loadingTitleUpdate={loadingTitleUpdate}
        setTitle={setTitle}
        setOptionTypeId={setOptionTypeId}
        setUploadImageModal={setUploadImageModal}
        newListingImages={newListingImages}
        uploadImageModal={uploadImageModal}
        data={data}
        onChangeImageUpload={onChangeImageUpload}
        handleSaveTitle={handleSaveTitle}
        title={title}
        optionTypeId={optionTypeId}
        handleImageUploader={handleImageUploader}
        imageUploaded={imageUploaded}
        deleteHandler={deleteHandler}
        progress={progress}
        loadingUpload={loadingUpload}
        setEditedFile={setEditedFile}
        loadingDelete={loadingDelete}
        imageGalleryModal={imageGalleryModal}
        imgData={imgData}
        toggleUploadImageModal={toggleUploadImageModal}
        selectedImage={selectedImage}
        handleEditImageUploader={handleEditImageUploader}
        setImageUploaded={setImageUploaded}
        setProgress={setProgress}
        componentRef={componentRef}
        startIndex={startIndex}
        primaryPicture={primaryPicture}
        scrollToView={scrollToView}
        isAviationCategory={isAviationCategory}
        categoryName={categoryName}
        locationFilled={locationFilled}
        saleType={saleType}
        count={props?.count}
        showMessages={showMessages}
        toggleMessages={toggleMessages}
        toggleChat={toggleChat}
        loadingChats={loadingChats}
        selectedChat={selectedChat}
        showChats={showChats}
        setSelectedChat={setSelectedChat}
        setShowSidebarOffcanvas={setShowSidebarOffcanvas}
      />
    );
  };
  const mapDispatchToProps = (dispatch) => ({
    //global states for storing api data
    listingImages: (params, callback) => dispatch(listingImages(params, callback)),
    uploadListingImages: (params, data, callback) => dispatch(uploadListingImages(params, data, callback)),
    updateListingTitle: (params, data, callback) => dispatch(updateListingTitle(params, data, callback)),
    markAsPrimary: (params, data, callback) => dispatch(markAsPrimary(params, data, callback)),
    deleteListingImage: (params, callback) => dispatch(deleteListingImage(params, callback)),
    detailListing: (id, callback) => dispatch(detailListing(id, callback)),
    setUploadImageModal: (data) => dispatch(setUploadImage(data)),

    //Global state maintained for manipulation
    primaryPicture: (payload) => dispatch(primaryPicture(payload)),
    listingImageReceived: (payload) => dispatch(listingImageReceived(payload)),
    listingDetailReceived: (payload) => dispatch(listingDetailReceived(payload)),
  });
  const mapStateToProps = (state) => ({
    getMediaData: getListingMedia(state),
    uploadImageModal: getModals(state)?.uploadImage,
  });

  return connect(mapStateToProps, mapDispatchToProps)(React.memo(NewComp));
};

export default publicPicturesHoc;
