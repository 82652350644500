import React, { useEffect, useState } from "react";
import {
  changePrimarySeller,
  getEmailReportLog,
  getPrimarySeller,
  getSellers,
  primarySellerData,
  saveCustomizeDashboard,
  saveSellerDisplayRequest,
  updateSendReportEmail,
  updateSendWeeklyReportEmailFrequency,
  userAuctionListings,
} from "../../../store/primarySeller";
import { connect } from "react-redux";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { numberFormat, removeTags, twoDecimals } from "../../../utils.js/conversion";

import greenEmail from "../../../include/images/green-mail.svg";
import notVerified from "../../../include/images/mail-not-verified.svg";
import invalid from "../../../include/images/mail-invalid.svg";
import spamEmail from "../../../include/images/mail-spammed-blocked.svg";
import purpleEmail from "../../../include/images/mail-purple.svg";
import helpCircle from "../../../include/images/help-circle.svg";
import Skeleton from "react-loading-skeleton";
import ImageLargeModal from "../../../layouts/modals/imageGalleryModal";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SellerAgreementModal from "../../../layouts/modals/primarySeller/sellerAgreementModal";
import ndaRed from "../../../include/images/nda-red.svg";
import ndaYellow from "../../../include/images/nda-yellow.svg";
import ndaGreen from "../../../include/images/nda-green.svg";

import icon1 from "../../../include/images/icon___1.svg";

import edit from "../../../include/images/edit.svg";
import icon2 from "../../../include/images/icon__2.svg";
import icon3 from "../../../include/images/red-hand.png";
import green from "../../../include/images/User_Green.svg";
import red from "../../../include/images/User_Red.svg";
import purple from "../../../include/images/User_Purple.svg";
import yellow from "../../../include/images/User_yellow.svg";
import { detailListing, getListings, getSellerMotivation } from "../../../store/listings";
import moment from "moment";
import { getLatestActivity, getPriceInfo, updatePriceFeature } from "../../../store/latestActivity";
import { getModals, setListingAgreement, setQtyPricingModal, setShowAddPayableModal } from "../../../store/modal";
import { payableDetails } from "../../../store/payables";
import { getProfile } from "../../../utils.js/localStorageServices";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import EmailReportLog from "../../../layouts/modals/primarySeller/emailReportLog";
import _, { isEmpty, isNull } from "lodash";
import { dashboardData, getMotivation, updateMotivation } from "../../../store/dashBoard";
import ReactTooltip from "react-tooltip";
import Motivation from "../../../layouts/modals/sellerMotivationModal.jsx/motivation";
import HTMLReactParser from "html-react-parser";
import { getUser, getVerificationDoc } from "../../../store/users";
import PaymentInfoModal from "../../users/modal/paymentInfoModal";
import PaymentInfo from "../../../layouts/modals/paymentInfo/paymentInfoModal";
import VerifyDocModal from "../../users/modal/verifyDocModal";
import { getAskingPriceHistory, getMisc, updatePriceRange } from "../../../store/misc";
import AskingPriceHistory from "../../../layouts/modals/primarySeller/askingPriceHistory";
import { getEmailContent, getEmailLogs, getEmailUUID } from "../../../store/third-part-apis/brevo";
import ARRHistory from "../../../layouts/modals/bids/aRRHistory";
import ViewBARREmail from "../../reports/buyerARREmailStats/viewEmail";
import { getKeyBuyerCount, getListingProductDetail } from "../../../store/listingProduct";
import PushHistory from "../../../layouts/modals/primarySeller/pushHistory";
import { getCountries, getStates } from "../../../store/productLocation";
import FindUser from "../../../common/findUser/findUser";
import VerificationStatusNote from "../../../layouts/modals/primarySeller/verificationStatusNote";
import DashboardStyling from "../../../layouts/modals/dashboardStyling/dashboardStyling";

const weekFrequencyOptions = [
  { name: "Select Week", value: "-1" },
  { name: "0 Week", value: 0 },
  { name: "1 Week", value: 1 },
  { name: "2 Weeks", value: 2 },
  { name: "3 Weeks", value: 3 },
  { name: "4 Weeks", value: 4 },
];

const sellerDetailOptions = [
  { name: "DO NOT Display Seller Info on Public View", value: 0 },
  { name: "Display Seller Name on Public View", value: 1 },
  { name: "Display Seller Name & Logo on Public View", value: 2 },
];

const PrimarySellerNew = (props) => {
  const listingAgreement = props?.dashboardData?.listingAgreement?.data;
  const keyPushCount = props?.getListingProductDetail?.keyBuyerCount?.data;
  const data = props?.primarySellerData?.primarySeller?.data;
  const completed = props?.getUser?.paymentInform?.detail?.isComplete;
  const detail = props?.getListings?.detail?.detail;
  const priceInfo = props.getLatestActivity?.priceInfo || {};

  const isListingEnabled = props.getListings?.detail?.detail?.enable_for_seller_weekly_report;

  const listingContract = props?.listingContract;

  const motivationData =
    (props.getListings && props.getListings.sellerMotivation && props.getListings.sellerMotivation.data) || {};
  const mediaUrl = process.env.REACT_APP_MEDIA_URL;
  const payablesData =
    props && props.payableDetails && props.payableDetails.payables && props.payableDetails.payables.data;
  const publicAskingPriceData = props?.getAskingPriceData?.data;

  const d = new Date();
  let diff = d.getTimezoneOffset();

  const [imageGalleryModal, setImageGalleryModal] = useState(false);
  const [startIndex, setStartIndex] = useState("");
  const [imageData, setImageData] = useState([]);
  const [title, setTitle] = useState("");
  const [sellerAgreementModal, setSellerAgreementModal] = useState(false);
  const [weekFrequency, setWeekFrequency] = useState({
    name: "Select Week",
    value: "",
  });
  const [showMotivation, setShowMotivation] = useState(false);
  const [sellerData, setSellerData] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingRange, setLoadingRange] = useState(false);
  const [showEmailReport, setShowEmailReport] = useState(false);
  const [showPayInfoModal, setShowPayInfoModal] = useState(false);
  const [showPayInfoEditModal, setShowPayInfoEditModal] = useState(false);
  const [allAgr, setAllAgr] = useState(false);
  const [showVerificationDocModal, setShowVerificationDocModal] = useState(false);
  const [verificationDoc, setVerificaitonDoc] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPriceHistory, setShowPriceHistory] = useState(false);
  const [priceFeatureData, setPriceFeatureData] = useState({
    price_type: "",
  });
  const [askingPrice, setAskingPrice] = useState(
    priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")?.feature,
  );
  const [wholesaleValue, setWholesaleValue] = useState(
    priceInfo?.auction_prices?.find((item) => item.description === "Original Wholesale Value")?.feature,
  );

  const [userId, setUserId] = useState("");
  const [priceRange, setPriceRange] = useState({ from: "", to: "" });
  const [emailHistoryReport, setEmailHistoryReport] = useState([]);
  const [SARRfilters, setSARRFilters] = useState({
    action: { name: "Select Action", value: "-1" },
    startDate: null,
    endDate: null,
    dateRange: {
      name: "Last 30 days",
      value: { from: moment().subtract(30, "days").format("YYYY-MM-DD"), to: moment().format("YYYY-MM-DD") },
    },
  });
  const [sarrEmail, setSarrEmail] = useState("");

  const [showSentEmail, setShowSentEmail] = useState(false);
  const [sentEmailContent, setSentEmailContent] = useState(null);
  const [showPushHistory, setShowPushHistory] = useState(false);

  const [showNewSeller, setShowNewSeller] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [response, setResponse] = useState(false);
  const [resData, setResData] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [seller, setSeller] = useState({});
  const [searchData, setSearchData] = useState({
    name: "",
    company_name: "",
    city: "",
    st_des: "",
    zip: "",
    c_des: "",
    phone: "",
    fax: "",
    cell: "",
    email: "",
    notes: "",
    mType: 0,
  });

  const [selectedSellerOption, setSelectedSellerOption] = useState(0);
  const [sellerStatus, setSellerStatus] = useState("");
  const [customizeDashobard, setCustomizeDashobard] = useState(false);
  const [showCustomizeDashobard, setShowCustomizeDashobard] = useState(false);

  const [showVerificationNote, setShowVerificationNote] = useState(false);

  const toggleCustomizeDashobard = () => {
    setShowCustomizeDashobard(!showCustomizeDashobard);
  };

  const toggleVerificationNote = () => {
    setShowVerificationNote(!showVerificationNote);
  };

  const toggleNewSeller = () => {
    setShowNewSeller(!showNewSeller);
  };

  const handleSearch = () => {
    setLoadingSearch(true);
    props.refStart();
    props.getSellers(props.auctionId, searchData, (res) => {
      if (res.status === 200) {
        setResData(res.data.data);
        props.refStop();
        setLoadingSearch(false);
        setResponse(true);
      } else {
        setLoadingSearch(false);
        props.refStop();
        setResponse(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  const handleNewSearch = () => {
    setResponse(false);
    setResData([]);
    setSeller({});
    setSearchData({
      name: "",
      company_name: "",
      city: "",
      st_des: "",
      zip: "",
      c_des: "",
      phone: "",
      fax: "",
      cell: "",
      email: "",
      notes: "",
      mType: 0,
    });
  };

  useEffect(() => {
    const handleUpdatePriceFeature = () => {
      props?.refStart();
      props.updatePriceFeature(props?.auctionId, priceFeatureData, (res) => {
        if (res.status === 200) {
          props.getPriceInfo(props?.auctionId, (res) => {});
          props?.refStop();
        } else {
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (props?.auctionId && priceFeatureData?.price_type) {
      handleUpdatePriceFeature();
    }
  }, [priceFeatureData]);

  useEffect(() => {
    setAskingPrice(priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")?.feature);
    setWholesaleValue(
      priceInfo?.auction_prices?.find((item) => item.description === "Original Wholesale Value")?.feature,
    );
  }, [priceInfo]);

  const handleSelectSeller = () => {
    const payload = {
      setSeller: seller?.id ? seller?.id : "",
    };
    setLoadingSearch(true);
    props?.refStart();
    props.changePrimarySeller(props.auctionId, payload, (res) => {
      if (res.status === 200) {
        const data = res?.data?.data;
        const sellerId = data?.seller_id ? data?.seller_id : 0;
        const memberId = data?.member_id ? data?.member_id : 0;
        const sId = data?.id ? data?.id : 1;
        if (sellerId == 0 && memberId == 0) {
          props?.refStop();
          setLoadingSearch(false);
          props?.setNoSeller(true);
          toggleNewSeller();
        } else {
          props?.getPrimarySeller(props?.auctionId, sellerId, memberId, sId, (res) => {
            if (res.status === 200) {
              props?.setNoSeller(false);
              props?.getKeyBuyerCount(props?.auctionId, (res) => {});
              props?.getSellerMotivation(props?.auctionId, (res) => {});
              props?.refStop();
              setLoadingSearch(false);
              toggleNewSeller();
            } else {
              props?.refStop();
              setLoadingSearch(false);
              toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
            }
          });
        }
      } else {
        props?.refStop();
        setLoadingSearch(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showNewSeller) {
      props.getCountries((res) => {
        if (res.status === 200) {
          setCountry(res.data.data);
        }
      });
      props.getStates((res) => {
        if (res.status === 200) {
          setState(res.data.data);
        }
      });
      setResponse(false);
      setResData([]);
      setSeller({});
      setSearchData({
        name: "",
        company_name: "",
        city: "",
        st_des: "",
        zip: "",
        c_des: "",
        phone: "",
        fax: "",
        cell: "",
        email: "",
        notes: "",
        mType: 0,
      });
    }
  }, [showNewSeller]);

  const latestBids = props.getLatestActivity?.latestBids || {};
  const bidLog = props?.getLatestActivity?.bidLog?.data;
  const productDetails = props?.getListingProductDetail?.detail?.detail || {};
  const showAddPayableModal = props?.payablesModal;
  const sellerMoivation = props?.dashboardData?.motivaiton?.data?.motivation;

  const wholesaleValueFeatured = priceInfo?.auction_prices?.find(
    (item) => item.description === "Original Wholesale Value",
  )?.feature;

  const toggleSentEmail = () => {
    setShowSentEmail(!showSentEmail);
  };

  const togglePriceHistory = () => {
    setShowPriceHistory(!showPriceHistory);
  };

  const fetchEmailContent = async (email, messageId) => {
    props?.refStart();
    const uuidData = await getEmailUUID(
      `smtp/emails?email=${email}&messageId=${messageId}&sort=desc&limit=500&offset=0`,
    );
    const emailContent = await getEmailContent(`smtp/emails/${uuidData?.data?.transactionalEmails?.[0]?.uuid}`);
    setSentEmailContent(emailContent?.data);
    toggleSentEmail();
    props?.refStop();
  };

  const getSellerARRLogs = async (email) => {
    props?.refStart();
    const logsData = await getEmailLogs(
      `smtp/statistics/events?limit=250&offset=0&email=${email}&tags=Seller%20Report&sort=desc`,
    );
    setEmailHistoryReport(logsData?.data?.events);
    setSarrEmail(email);
    props?.refStop();
    toggleEmailReport();
  };

  useEffect(() => {
    const filteredData = async () => {
      props?.refStart();
      const logsData = await getEmailLogs(
        `smtp/statistics/events?limit=250&offset=0&email=${sarrEmail}&tags=Seller%20Report&sort=desc${
          SARRfilters?.startDate && SARRfilters?.endDate
            ? `&startDate=${SARRfilters?.startDate}&endDate=${SARRfilters?.endDate}`
            : ""
        }`,
      );
      if (logsData?.message && logsData?.status !== 200) {
        props?.refStop();
        toast(<AlertError message={logsData?.response?.data?.message} />);
      } else {
        props?.refStop();
        setEmailHistoryReport(logsData?.data?.events);
      }
    };
    if (
      showEmailReport &&
      ((!SARRfilters?.startDate && !SARRfilters?.endDate) || (SARRfilters?.startDate && SARRfilters?.endDate))
    ) {
      filteredData();
    }
  }, [SARRfilters?.startDate, SARRfilters?.endDate]);

  useEffect(() => {
    if (!isEmpty(detail)) {
      const value = { ...priceRange };
      value.from = parseInt(detail?.higher_range);
      value.to = parseInt(detail?.lower_range);
      setPriceRange(value);
    }
  }, [detail]);

  useEffect(() => {
    if (data || showMotivation) {
      props?.getMotivation(data?.user?.s_user_id, (res) => {
        setSellerData(res?.data?.data?.motivation);
      });
    }
    setSelectedSellerOption(
      data?.sellerDisplayRequest?.action === "rejected" ? 0 : data?.sellerDisplayRequest?.display_seller_detail || 0,
    );
    setSellerStatus(data?.sellerDisplayRequest?.action === "pending" ? "pending" : "");
    setCustomizeDashobard(data?.show_custom_dashboard);
  }, [data, showMotivation]);

  const toggleMotivation = () => {
    setShowMotivation(!showMotivation);
  };

  const toggleEmailReport = () => {
    setShowEmailReport(!showEmailReport);
  };
  const togglePayInfo = () => {
    setShowPayInfoModal(!showPayInfoModal);
  };
  const togglePayInfoEdit = () => {
    setShowPayInfoEditModal(!showPayInfoEditModal);
  };

  const toggleAddPayableModal = () => {
    props?.setShowAddPayableModal(!showAddPayableModal);
  };

  const toggleSellerAgreementModal = () => {
    setSellerAgreementModal(!sellerAgreementModal);
  };

  const toggleImageGalleryModal = () => {
    setImageGalleryModal(!imageGalleryModal);
  };

  const toggleVerificationDoc = (userId) => {
    setUserId(userId);
    setShowVerificationDocModal(!showVerificationDocModal);
  };

  const fetchVerificationDoc = (userId) => {
    setLoading(true);
    setShowVerificationDocModal(true);
    props.getVerificationDoc(userId, (res) => {
      if (res.status === 200) {
        setVerificaitonDoc(res?.data?.detail);
        setLoading(false);
      } else {
        setLoading(false);
        setShowVerificationDocModal(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };
  //website function
  const website = (val) => (val && val.includes("http://") ? val : "http://" + val);
  const sellerAskingPrice =
    priceInfo?.auction_prices?.find((item) => item.description === "Original Wholesale Value")?.p_value > 0
      ? Math.round(
          (priceInfo?.auction_details?.seller_reserve /
            priceInfo?.auction_prices?.find((item) => item.description === "Original Wholesale Value")?.p_value) *
            100,
        )
      : "0";

  const profile = getProfile();
  const isInvestmentAccessible =
    profile && profile?.accessLevels && profile?.accessLevels?.employee_investment_admin_access
      ? profile?.accessLevels?.employee_investment_admin_access
      : false;

  const keyPushCountAccess = profile?.accessLevels?.key_push_count_access;

  const allowedIds = [1, 784848, 842446, 878419, 884147];

  const systemAdmin = profile?.accessLevels?.system_admin;
  const pushHistorydata =
    (props.getListingProductDetail &&
      props.getListingProductDetail.buyerPush &&
      props.getListingProductDetail.buyerPush.data) ||
    {};

  useEffect(() => {
    if (data?.weekReportEmailFrequency === 0 || data?.weekReportEmailFrequency) {
      const a = weekFrequencyOptions?.filter((ye) => ye?.value == data?.weekReportEmailFrequency)?.[0];
      setWeekFrequency(a);
    } else {
      setWeekFrequency({ name: "Select Week", value: "0" });
    }
  }, [data?.weekReportEmailFrequency]);

  const handleWeekReportEmail = (value) => {
    const payload = {
      weekReportEmailFrequency: value,
    };
    if (value !== "-1") {
      setLoadingStatus(true);
      props?.refStart();
      props?.updateSendWeeklyReportEmailFrequency(data?.user?.s_user_id, payload, (res) => {
        if (res.status === 200) {
          props?.getPrimarySeller(props?.auctionId, props?.sellerId, props?.memberId, props?.s_id, (res) => {
            setLoadingStatus(false);
          });

          props?.refStop();
        } else {
          setLoadingStatus(true);
          props?.refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    }
  };

  const updatePrimarySeller = () => {
    props?.getPrimarySeller(props?.auctionId, props?.sellerId, props?.memberId, props?.s_id, (res) => {});
  };

  const resubscribeHandler = () => {
    props?.refStart();
    props?.updateSendReportEmail(data?.user?.s_user_id, (res) => {
      if (res && res.status == 200) {
        props?.getPrimarySeller(props?.auctionId, props?.sellerId, props?.memberId, props?.s_id, (res) => {});
        props?.refStop();
        toast(<AlertSuccess message={"Seller subscribed to Asset Recovery Weekly Report"} />);
      } else {
        props?.refStop();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  const getEmailReportHistory = () => {
    props?.refStart();
    const userId = data?.user?.s_user_id;
    const payload = {
      sort: "createdAt",
      order: "desc",
    };
    props?.getEmailReportLog(userId, payload, (res) => {
      if (res.status === 200) {
        props?.refStop();
        toggleEmailReport();
      } else {
        props?.refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const handleSaveMotivation = () => {
    setLoadingStatus(true);
    props?.refStart();
    props?.updateMotivation(data?.user?.s_user_id, { motivation: sellerData }, (res) => {
      if (res && res?.status == 200) {
        props?.getMotivation(data?.user?.s_user_id, (res1) => {
          setLoadingStatus(false);
          props?.refStop();
          setSellerData(res1?.data?.data?.motivation);
          toggleMotivation();
          toast(<AlertSuccess message={"Seller Motivation Updated"} />);
        });
      } else {
        setLoadingStatus(false);
        props?.refStop();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (sellerAgreementModal && allAgr) {
      props?.refStart();
      props?.userAuctionListings(data?.user?.s_user_id, (res) => {
        if (res && res?.status == 200) {
          props?.refStop();
        } else {
          props?.refStop();
          toggleSellerAgreementModal();
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  }, [sellerAgreementModal]);

  useEffect(() => {
    if (showPriceHistory) {
      props?.getAskingPriceHistory(props?.auctionId, (res) => {
        if (res && res?.status === 200) {
        }
      });
    }
  }, [showPriceHistory]);

  const submitPriceRange = () => {
    if (priceRange?.from) {
      props?.refStart();
      setLoadingRange(true);
      const payload = {
        higher_range: +priceRange?.from,
        lower_range: priceRange?.to ? +priceRange?.to : 0,
      };
      props?.updatePriceRange(props?.auctionId, payload, (res) => {
        if (res && res.status === 200) {
          props?.getAskingPriceHistory(props?.auctionId, (res) => {});
          props?.detailListing(props?.auctionId, (res) => {});
          setLoadingRange(false);
          props?.refStop();
          toast(<AlertSuccess message={"Public Asking Price Range Updated"} />);
        } else {
          const value = { ...priceRange };
          value.to = parseInt(detail?.lower_range);
          setPriceRange(value);
          props?.refStop();
          setLoadingRange(false);
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
        }
      });
    }
  };

  const handleCustomizeDashobard = () => {
    props?.refStart();
    const payload = {
      show_custom_dashboard: !customizeDashobard,
    };
    if (!data?.dashboard_color && !data?.dashboard_secondary_color && data?.dashboard_logo) {
      payload.dashboard_logo = data?.dashboard_logo;
    }
    setCustomizeDashobard(!customizeDashobard);
    props?.saveCustomizeDashboard(data?.user?.s_user_id, payload, (res) => {
      if (res && res.status === 200) {
        props?.refStop();
        toast(<AlertSuccess message={"Information Updated"} />);
      } else {
        setCustomizeDashobard(!customizeDashobard);

        props?.refStop();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  const parseDate = (dateString) => {
    return new Date(dateString.replace(/(AM|PM)/i, (match) => ` ${match}`));
  };
  const sortedBidLog =
    bidLog?.length > 0
      ? [...bidLog]?.sort((a, b) => {
          const dateA = parseDate(a.updated_dt);
          const dateB = parseDate(b.updated_dt);
          return dateA - dateB;
        })
      : [];
  const latestAcceptedValue = sortedBidLog?.filter((ye) => ye?.type)?.pop();
  const categoryId = props?.getListingProductDetail?.detail?.detail?.category_id_for_entity;
  const filterEntityByCategory = (arr, catId, entityMap) => {
    return arr?.filter((item) => {
      const entityName = entityMap[catId] || "Salvex Commodities, Inc.";
      return entityName && item.salvex_company.includes(entityName);
    });
  };
  const entityMap = {
    1008: "Salvex Metals, Inc.",
    1176: "Salvex Aviation",
    1395: "Salvex Solar, Inc.",
    1294: "Salvex Energy, Inc.",
    1384: "Salvex Energy, Inc.",
    1002: "Salvex Food, Inc.",
  };

  const updateSellerDispayRequest = (id) => {
    props?.refStart();
    props?.saveSellerDisplayRequest({ member_id: `${data?.user?.s_member_id}`, display_seller_detail: id }, (res) => {
      if (res?.status == 200) {
        updatePrimarySeller();
        props?.refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props?.refStop();
        toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something went wrong"} />);
      }
    });
  };

  return (
    <>
      <div class="details-page-widget-block">
        <div class={props?.data && props?.data?.length > 0 && "page-widget-body"}>
          {props.loadingMain ? (
            <div class="table-responsive">
              <table class="large-table">
                <colgroup>
                  <col style={{ minWidth: "28px" }} />
                  <col />
                  <col />
                  <col />
                  <col />

                  <col style={{ minWidth: "44px" }} />

                  <col style={{ minWidth: "44px" }} />
                  <col style={{ minWidth: "44px" }} />
                  <col style={{ minWidth: "44px" }} />
                  <col style={{ minWidth: "44px" }} />

                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />

                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                  <col style={{ minWidth: "24px" }} />
                </colgroup>

                <tbody>
                  {[1, 2, 3].map(() => (
                    <tr>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            [1, 2, 3]?.length > 0 && (
              <div class="table-responsive">
                <table class="large-table large-table1 res-primary">
                  {/* <colgroup>
                    <col style={{ width: "25%", maxWidth: "25%", minWidth: "25%" }} />
                    <col style={{ width: "25%", maxWidth: "25%", minWidth: "25%" }} />
                    <col style={{ width: "25%", maxWidth: "25%", minWidth: "25%" }} />
                    <col style={{ width: "25%", maxWidth: "25%", minWidth: "25%" }} />
                  </colgroup> */}
                  {/* <thead>
                  <tr>
                    <th>Enterprise Logo</th>
                    <th>Picture</th>
                    <th>Seller Information</th>
                    <th>Customer Verification</th>
                    <th>Seller Motivation</th>
                    <th>Pricing</th>
                  </tr>
                </thead> */}
                  <tbody>
                    <tr>
                      {!props?.loadingMain && (!data?.user?.s_user_id || props?.noSeller) ? (
                        <td colSpan={"3"} className="nps-container">
                          <h3>No Seller Found</h3>
                          <a
                            class={`btn btn-other btn-xs-size btn-auto mt-2`}
                            href="javascript:void(0)"
                            onClick={toggleNewSeller}
                          >
                            Assign Seller
                          </a>
                        </td>
                      ) : (
                        <>
                          <td>
                            <div class="seller-logo w-40 cursor-pointer">
                              {data?.picture?.proc_logo && (
                                <img
                                  className={`${data?.picture?.publicly_traded ? "purple-border" : "gray-border"}`}
                                  onClick={() => {
                                    if (process.env.REACT_APP_MODE == "development") {
                                      window.open(
                                        `https://admin-dev.salvex.com/admin/_proc/_company/_edit.cfm?cid=${data?.user?.cid}`,
                                      );
                                    } else {
                                      window.open(
                                        `https://admin.salvex.com/admin/_proc/_company/_edit.cfm?cid=${data?.user?.cid}`,
                                      );
                                    }
                                  }}
                                  src={`https://${
                                    process.env.REACT_APP_MODE === "development" ? "media-dev" : "media"
                                  }.salvex.com/co/logos/${data?.picture?.proc_logo}`}
                                  alt=""
                                />
                              )}
                            </div>
                            {!props.loadingMain && (
                              <a
                                href="javascript:void(0)"
                                style={{ cursor: "auto" }}
                                class="green-btn-text fw-medium ms-auto"
                              >
                                {data?.user?.business_unit}
                              </a>
                            )}
                            <div className="d-block">
                              <span
                                className="green-btn-text cursor-pointer"
                                onClick={() => {
                                  if (process.env.REACT_APP_MODE == "development") {
                                    window.open(
                                      `https://admin-dev.salvex.com/admin/_user/_edit.cfm?user_id=${data?.user?.s_user_id}`,
                                    );
                                  } else {
                                    window.open(
                                      `https://admin.salvex.com/admin/_user/_edit.cfm?user_id=${data?.user?.s_user_id}`,
                                    );
                                  }
                                }}
                              >
                                {data?.user?.name}
                              </span>
                              {data?.user?.cid > 0 ? <span class="red-text-label">(Enterprise)</span> : ""}
                              <a
                                className="ms-1"
                                href={
                                  filterEntityByCategory(data?.ncnda_agreements, categoryId, entityMap)?.[0]
                                    ?.nda_signed_date
                                    ? `${process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET}/${
                                        filterEntityByCategory(data?.ncnda_agreements, categoryId, entityMap)?.[0]
                                          ?.file_name
                                      }`
                                    : `/ncnd-agreement/view?user_id=${data?.user?.s_user_id}&cat_id=${categoryId}`
                                }
                                target="_blank"
                              >
                                {filterEntityByCategory(data?.ncnda_agreements, categoryId, entityMap)?.[0]
                                  ?.nda_sent_date &&
                                filterEntityByCategory(data?.ncnda_agreements, categoryId, entityMap)?.[0]
                                  ?.nda_signed_date ? (
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                      <Tooltip style={{ position: "fixed" }}>
                                        <span>
                                          {filterEntityByCategory(data?.ncnda_agreements, categoryId, entityMap)?.[0]
                                            ?.name
                                            ? "Signed"
                                            : "Uploaded"}{" "}
                                          on{" "}
                                          {moment(
                                            filterEntityByCategory(data?.ncnda_agreements, categoryId, entityMap)?.[0]
                                              ?.nda_signed_date,
                                          ).format("MM/DD/YYYY")}
                                        </span>
                                      </Tooltip>
                                    }
                                  >
                                    <img src={ndaGreen} alt="" />
                                  </OverlayTrigger>
                                ) : (
                                  <img
                                    src={
                                      filterEntityByCategory(data?.ncnda_agreements, categoryId, entityMap)?.[0]
                                        ?.nda_sent_date
                                        ? ndaYellow
                                        : ndaRed
                                    }
                                    alt=""
                                  />
                                )}
                              </a>
                              <br />
                            </div>
                            <span className="d-block ">{data?.user?.job_title ? data?.user?.job_title : ""}</span>
                            <span className="d-block ">
                              {data?.user?.designation ? <span>{data?.user?.designation}</span> : ""}
                            </span>
                            <span
                              className="d-block cursor-pointer"
                              onClick={() => {
                                if (process.env.REACT_APP_MODE == "development") {
                                  window.open(
                                    `https://admin-dev.salvex.com/admin/_proc/_company/_edit.cfm?cid=${data?.user?.cid}`,
                                  );
                                } else {
                                  window.open(
                                    `https://admin.salvex.com/admin/_proc/_company/_edit.cfm?cid=${data?.user?.cid}`,
                                  );
                                }
                              }}
                            >
                              {data?.user?.company_name ? data?.user?.company_name : ""}
                            </span>
                            <span className="d-block ">{data?.user?.phone ? data?.user?.phone : ""}</span>
                            <span className="d-block ">{data?.user?.cell ? data?.user?.cell : ""}</span>
                            <span className="d-block ">
                              {data?.user?.email ? (
                                <a href={`mailto:${data?.user?.email}`} className="green-btn-text cursor-pointer">
                                  {data?.user?.email}
                                </a>
                              ) : (
                                "N/A"
                              )}{" "}
                              {data?.user?.emailStatusCode === 0 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email OK</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={greenEmail} alt="" />
                                </OverlayTrigger>
                              ) : data?.user?.emailStatusCode === 1 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Temporarily Suspended/Unreachable or Undetermined (Soft Bounce)</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={purpleEmail} alt="" />
                                </OverlayTrigger>
                              ) : data?.user?.emailStatusCode === 2 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Invalid</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={invalid} alt="" />
                                </OverlayTrigger>
                              ) : data?.user?.emailStatusCode === 3 ? (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Blocked by Spam or Firewall</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={spamEmail} alt="" />
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip style={{ position: "fixed" }}>
                                      <span>Email Not Verified</span>
                                    </Tooltip>
                                  }
                                >
                                  <img src={notVerified} alt="" />
                                </OverlayTrigger>
                              )}
                            </span>
                            <span className="d-block">{data?.user?.industry_description}</span>
                            <span className="d-block">
                              {data?.user?.address_line_1} {data?.user?.address_line_2}{" "}
                              {(data?.user?.address_line_1 || data?.user?.address_line_2) && <br />}{" "}
                              {data?.user?.city && data?.user?.city + ","} {data?.user?.st_abb} {data?.user?.zip}{" "}
                              {(data?.user?.city || data?.user?.st_abb || data?.user?.zip) && <br />}
                              {data?.user?.c_abb}
                            </span>
                            <a
                              class={`btn btn-primary btn-xs-size btn-auto mt-2`}
                              href={`https://${
                                process.env.REACT_APP_MODE == "development" ? "admin-dev.salvex" : "admin.salvex"
                              }.com/admin/_sellers/_mrk_doc_auc/_create.cfm?aucID=${props?.auctionId}&s_user_id=${
                                data?.user?.s_user_id
                              }`}
                              target="_blank"
                            >
                              Email
                            </a>
                            <a
                              class={`btn btn-other btn-xs-size btn-auto mt-2`}
                              href="javascript:void(0)"
                              onClick={toggleNewSeller}
                            >
                              Change Seller
                            </a>
                            {data?.user?.s_member_id ? (
                              <>
                                <div style={{ marginTop: "0.5rem", minWidth: "268px" }}>
                                  <Select
                                    menuPosition="fixed"
                                    menuShouldScrollIntoView
                                    styles={customStyles}
                                    placeholder="Select Seller Details"
                                    value={
                                      sellerDetailOptions?.filter((item) => item?.value === selectedSellerOption)?.[0]
                                    }
                                    options={sellerDetailOptions}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.value}
                                    onChange={(e) => {
                                      setSelectedSellerOption(e?.value);
                                      updateSellerDispayRequest(e?.value);
                                      if (e?.value != 0) {
                                        setSellerStatus("pending");
                                      } else {
                                        setSellerStatus("accepted");
                                      }
                                    }}
                                  />
                                  {sellerStatus === "pending" ? (
                                    <p style={{ color: "red" }}>(Approval Pending)</p>
                                  ) : sellerStatus === "rejected" ? (
                                    <p style={{ color: "red" }}>(Rejected)</p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {selectedSellerOption == 1 || selectedSellerOption == 2 || selectedSellerOption == 3 ? (
                                  <div style={{ marginTop: "0.5rem", minWidth: "268px" }}>
                                    <div
                                      style={{ paddingLeft: "30px" }}
                                      class="form-check dark-tick-checkbox d-inline"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value="chk_wholesale_value_feature"
                                        checked={customizeDashobard}
                                        id="chk_customize_feature"
                                        onClick={() => handleCustomizeDashobard()}
                                      />
                                      <label class="form-check-label small-check-box" for="chk_customize_feature">
                                        Customize Dashboard with color and logo
                                      </label>
                                    </div>
                                    {customizeDashobard && (
                                      <a
                                        style={{ paddingLeft: "30px" }}
                                        className="d-block mt-2 green-btn-text cursor-pointer"
                                        href="javascript:void(0)"
                                        onClick={() => toggleCustomizeDashobard()}
                                      >
                                        Select Color and Logo
                                      </a>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <span className="d-block ">
                              Seller Bid Success :{" "}
                              {data?.success_rate ? twoDecimals(data?.success_rate) + "%" : "0.00%"} ({data?.rate[0]}/
                              {data?.rate[1]})
                            </span>
                            <span className="d-block ">
                              Enterprise Bid Success :{" "}
                              {data?.success_rate_ent ? twoDecimals(data?.success_rate_ent) + "%" : "0.00%"} (
                              {data?.rate[2]}/{data?.rate[3]})
                            </span>
                            <span className="d-block ">
                              Total Sales : {data?.deals?.sold_deals ? data?.deals?.sold_deals : "0"}
                            </span>
                            <span className="d-block ">
                              Total Profits :{" "}
                              {data?.deals?.profit
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(data?.deals?.profit)
                                : "$0"}
                            </span>
                            <div
                              style={{
                                display:
                                  (data?.user?.dhs_approved || data?.user?.okay || data?.user?.stop_working) &&
                                  "inline-flex",
                              }}
                            >
                              {data?.user?.dhs_approved && <img class="l-logo-box" src={icon1} alt="" />}
                              {data?.user?.okay && <img class="l-logo-box" src={icon2} alt="" />}
                              {data?.user?.stop_3zworking && (
                                <img class="l-logo-box" style={{ width: "28px" }} src={icon3} alt="" />
                              )}

                              {data?.verification?.verification_status == "red" ? (
                                <img
                                  class="l-logo-box cursor-pointer"
                                  onClick={toggleVerificationNote}
                                  src={red}
                                  alt=""
                                />
                              ) : data?.verification?.verification_status == "yellow" ? (
                                <img
                                  class="l-logo-box cursor-pointer"
                                  onClick={toggleVerificationNote}
                                  src={yellow}
                                  alt=""
                                />
                              ) : data?.verification?.verification_status == "purple" ? (
                                <img
                                  class="l-logo-box cursor-pointer"
                                  onClick={toggleVerificationNote}
                                  src={purple}
                                  alt=""
                                />
                              ) : data?.verification?.verification_status == "green" ? (
                                <img
                                  class="l-logo-box cursor-pointer"
                                  onClick={toggleVerificationNote}
                                  src={green}
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="d-flex">
                              {(data?.user?.company_name &&
                                data?.user?.company_registration_proof &&
                                data?.user?.company_tax_identification_proof) ||
                              data?.user?.identification_proof ? (
                                <li className={`badge-item cursor-pointer`}>
                                  <div
                                    className={`badge-box gsp`}
                                    style={{ width: "fit-content" }}
                                    onClick={() => fetchVerificationDoc(data?.user?.s_user_id)}
                                  >
                                    Verification Docs Added
                                  </div>
                                </li>
                              ) : (
                                <li className={`badge-item  cursor-pointer`}>
                                  <div
                                    className={`badge-box ysp`}
                                    style={{ width: "fit-content" }}
                                    onClick={() => fetchVerificationDoc(data?.user?.s_user_id)}
                                  >
                                    Verification Docs Pending
                                  </div>
                                </li>
                              )}
                              <li className={`badge-item ${(completed || systemAdmin) && "cursor-pointer"}`}>
                                <div
                                  className={`badge-box ${completed ? "gsp" : "ysp"}`}
                                  style={{ width: "fit-content" }}
                                  onClick={completed ? togglePayInfo : systemAdmin ? togglePayInfoEdit : ""}
                                >
                                  Payment Info {completed ? "Added" : "Pending"}
                                </div>
                              </li>
                            </div>
                            {data?.user?.last_login ? (
                              <div className="d-block">Last login : {<DateFormat date={data?.user?.last_login} />}</div>
                            ) : (
                              ""
                            )}
                            <div class="d-block">
                              <a
                                href={
                                  profile?.tokenToMimicUser
                                    ? `${
                                        process.env.REACT_APP_MODE == "development"
                                          ? "https://newdev.salvex.com"
                                          : "https://salvex.com"
                                      }/my-account/mimic-user/?token=${profile?.tokenToMimicUser}&uniqueId=${
                                        data?.user?.s_member_id
                                      }&id=${data?.user?.s_user_id}`
                                    : "javascript:void(0)"
                                }
                                target={profile?.tokenToMimicUser ? "_blank" : "_self"}
                                class={`btn btn-primary btn-xs-size btn-auto`}
                                style={{ marginTop: "10px" }}
                              >
                                Mimic
                              </a>
                              <a
                                href="javascript:void(0)"
                                class={`btn btn-sm ${payablesData?.length > 0 ? "btn-primary" : "btn-secondary"}`}
                                style={{ marginTop: "10px" }}
                                onClick={() => toggleAddPayableModal()}
                              >
                                Add Payables
                              </a>
                              {payablesData?.length > 0 ? (
                                <a
                                  href="javascript:void(0)"
                                  class={`btn btn-primary btn-sm `}
                                  style={{ marginTop: "10px" }}
                                  onClick={() => {
                                    props?.setSection("payableRef");
                                    props?.scrollToView("payableRef");
                                  }}
                                >
                                  View Payables
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="d-block" style={{ marginTop: "10px", whiteSpace: "pre-line" }}>
                              {" "}
                              {
                                <>
                                  <a
                                    href="javascript:void(0)"
                                    className="link-btn sm-text"
                                    onClick={() => toggleMotivation()}
                                  >
                                    Seller Motivation :{" "}
                                  </a>
                                  <span>
                                    {sellerMoivation
                                      ? sellerMoivation?.length > 138
                                        ? removeTags(sellerMoivation?.substring(0, 138)) + "..."
                                        : HTMLReactParser(sellerMoivation)
                                      : "N/A"}
                                  </span>{" "}
                                </>
                              }
                            </div>
                          </td>
                          <td>
                            {motivationData?.variables?.find((item) => item.type === "checkbox_5")?.motivation == 1 ||
                              motivationData?.variables?.find((item) => item.type === "checkbox_6")?.motivation == 1 ||
                              motivationData?.variables?.find((item) => item.type === "checkbox_7")?.motivation == 1 ||
                              motivationData?.variables?.find((item) => item.type === "checkbox_8")?.motivation == 1 ||
                              motivationData?.variables?.find((item) => item.type === "checkbox_29")?.motivation == 1 ||
                              motivationData?.variables?.find((item) => item.type === "checkbox_30")?.motivation == 1 ||
                              (motivationData?.variables?.find((item) => item.type === "Sale_Deadlines_Timelines")
                                ?.motivation && (
                                <span className="d-block mb-2">
                                  {" "}
                                  TimeLine For Sale :
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_5")
                                      ?.motivation == 1 && "Immediately"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_6")
                                      ?.motivation == 1 && "Less Than 1 Month"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_7")
                                      ?.motivation == 1 && "Less Than 3 Months"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_8")
                                      ?.motivation == 1 && "No Timeline"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_29")
                                      ?.motivation == 1 && "Owner (Salvage)"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_30")
                                      ?.motivation == 1 && "Warehouse/Location (Abandoned Cargo)"}
                                  </div>
                                  {motivationData?.variables?.find((item) => item.type === "date_1")?.motivation &&
                                    motivationData?.variables?.find((item) => item.type === "date_1")?.motivation !=
                                      "" && (
                                      <div>
                                        Date:{" "}
                                        {moment(
                                          motivationData?.variables?.find((item) => item.type === "date_1")?.motivation,
                                        ).format("YYYY-MM-DD")}
                                      </div>
                                    )}
                                  {motivationData?.variables?.find((item) => item.type === "Sale_Deadlines_Timelines")
                                    ?.motivation
                                    ? motivationData?.variables?.find(
                                        (item) => item.type === "Sale_Deadlines_Timelines",
                                      )?.motivation
                                    : "N/A"}
                                </span>
                              ))}
                            {data?.variables?.find((item) => item.type === "checkbox_35")?.motivation == 1 ||
                              data?.variables?.find((item) => item.type === "checkbox_36")?.motivation == 1 ||
                              data?.variables?.find((item) => item.type === "checkbox_37")?.motivation == 1 ||
                              data?.variables?.find((item) => item.type === "checkbox_38")?.motivation == 1 ||
                              data?.variables?.find((item) => item.type === "checkbox_39")?.motivation == 1 ||
                              data?.variables?.find((item) => item.type === "checkbox_50")?.motivation == 1 ||
                              data?.variables?.find((item) => item.type === "Asset_Pricing_Discussion")?.motivation ==
                                1 ||
                              (data?.variables?.find((item) => item.type === "checkbox_40")?.motivation == 1 && (
                                <span className="d-block mb-2">
                                  Average Asking Price :
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_35")
                                      ?.motivation == 1 && "Less than 20% Wholesale Value"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_36")
                                      ?.motivation == 1 && "20%-40% Wholesale Value"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_37")
                                      ?.motivation == 1 && "40%-60% Wholesale Value"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_38")
                                      ?.motivation == 1 && "60%-80% Wholesale Value"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_39")
                                      ?.motivation == 1 && "80%-100% Wholesale Value"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_40")
                                      ?.motivation == 1 && "100% + Wholesale Value"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_50")
                                      ?.motivation == 1 && "No Pricing"}
                                  </div>
                                  {motivationData?.variables?.find((item) => item.type === "Asset_Pricing_Discussion")
                                    ?.motivation
                                    ? motivationData?.variables?.find(
                                        (item) => item.type === "Asset_Pricing_Discussion",
                                      )?.motivation
                                    : "N/A"}
                                </span>
                              ))}
                            {data?.variables?.find((item) => item.type === "checkbox_42")?.motivation == 1 ||
                              data?.variables?.find((item) => item.type === "checkbox_43")?.motivation == 1 ||
                              data?.variables?.find((item) => item.type === "checkbox_44")?.motivation == 1 ||
                              data?.variables?.find((item) => item.type === "checkbox_45")?.motivation == 1 ||
                              (data?.variables?.find((item) => item.type === "Insured_Bidding")?.motivation == 1 && (
                                <span className="d-block">
                                  Competiton :{" "}
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_42")
                                      ?.motivation == 1 && "Insured Bidding"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_43")
                                      ?.motivation == 1 && "Insured Not Bidding"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_44")
                                      ?.motivation == 1 && "Distribution List"}
                                  </div>
                                  <div>
                                    {motivationData?.variables?.find((item) => item.type === "checkbox_45")
                                      ?.motivation == 1 && "Other Websites"}
                                  </div>
                                  {motivationData?.variables?.find((item) => item.type === "Insured_Bidding")
                                    ?.motivation
                                    ? motivationData?.variables?.find((item) => item.type === "Insured_Bidding")
                                        ?.motivation
                                    : "N/A"}
                                </span>
                              ))}
                            {/* {listingContract && listingContract?.data?.length > 0 && (
                          <li className="badge-item cursor-pointer" onClick={() => props?.toggleContractListing()}>
                            <div className={`badge-box gsp`}>Signed Listing Agreement</div>
                          </li>
                        )} */}

                            {listingAgreement?.length > 0 ? (
                              listingAgreement[0]?.agreed ? (
                                <li
                                  className="badge-item cursor-pointer"
                                  onClick={() => props?.toggleContractListing()}
                                >
                                  <div
                                    className={`badge-box d-ysp`}
                                    data-tip={`
                                <div>
                                  <span>Lisitng Agreement Sent Date: ${
                                    moment(listingAgreement[0]?.created)
                                      .add({ minute: diff })
                                      .format("MMM-D-YYYY, h:mm:ss A") + " CST"
                                  }</span>
                                </div>
                                <div>
                                  <span>Listing Agreement Signed Date: ${
                                    moment(listingAgreement[0]?.agreed)
                                      .add({ minute: diff })
                                      .format("MMM-D-YYYY, h:mm:ss A") + " CST"
                                  }</span>
                                </div>
                              `}
                                    data-html={true}
                                  >
                                    Listing Agreement Signed
                                  </div>

                                  <ReactTooltip
                                    effect="solid"
                                    place="bottom"
                                    type="dark"
                                    // offset={{ top: 10 }}
                                    border={true}
                                  />
                                </li>
                              ) : !listingAgreement[0]?.agreed && listingAgreement[0]?.created ? (
                                <li className="badge-item cursor-pointer">
                                  <div className={`badge-box greysp`}>Listing Agreement Pending</div>
                                </li>
                              ) : (
                                ""
                              )
                            ) : (
                              <li className="badge-item cursor-pointer">
                                <div className={`badge-box rsp`}>Listing Agreement Not Sent</div>
                              </li>
                            )}
                            <div>
                              <a
                                class={`btn btn-primary btn-primary-group btn-xs-size btn-auto`}
                                style={{ marginTop: "10px", padding: "7px 5px" }}
                                onClick={() => {
                                  setAllAgr(true);
                                  toggleSellerAgreementModal();
                                }}
                              >
                                Multi Listing Agreement
                              </a>
                              <a
                                class={`btn btn-other btn-xs-size btn-auto`}
                                style={{ marginTop: "10px", padding: "7px 5px" }}
                                onClick={() => {
                                  setAllAgr(false);
                                  props?.setListingAgreement(!props?.listingAgreement);
                                }}
                              >
                                Listing Agreement
                              </a>
                            </div>

                            <div>
                              <a
                                target="_blank"
                                href={`/report/seller-asset-recovery-report?id=${data?.user?.s_user_id}`}
                                class={`btn btn-primary btn-primary-group btn-xs-size btn-auto `}
                                style={{ marginTop: "10px", padding: "7px 5px" }}
                              >
                                Seller Asset Recovery Report
                              </a>
                              <a
                                href="javascript:void(0)"
                                class={`btn btn-other btn-xs-size btn-auto `}
                                style={{ marginTop: "10px", padding: "7px 5px" }}
                                onClick={() => getSellerARRLogs(data?.user?.email)}
                              >
                                SARR Stats
                              </a>
                            </div>
                            {isListingEnabled === false && !props.loadingMain ? (
                              <div className="d-block mt-1" style={{ whiteSpace: "pre-line" }}>
                                <span style={{ color: "red" }}>This Listing is marked as disabled for Seller ARR.</span>
                              </div>
                            ) : (
                              ""
                            )}
                            {!props.loadingMain &&
                            !loadingStatus &&
                            data?.frequencyUpdatedBy == "Seller" &&
                            weekFrequency?.value == "0" ? (
                              <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                                <span style={{ color: "red" }}> Seller has decided not to receive these emails. </span>{" "}
                              </span>
                            ) : (
                              ""
                            )}
                            {!loadingStatus && data?.frequencyUpdatedBy == "Seller" && weekFrequency?.value === 1 ? (
                              <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                                <span style={{ color: "red" }}>
                                  {" "}
                                  Seller has decided to receive this email once in a week.
                                </span>{" "}
                              </span>
                            ) : !loadingStatus && data?.frequencyUpdatedBy == "Seller" && weekFrequency?.value === 2 ? (
                              <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                                <span style={{ color: "red" }}>
                                  {" "}
                                  Seller has decided to receive this email once in two weeks.
                                </span>{" "}
                              </span>
                            ) : !loadingStatus && data?.frequencyUpdatedBy == "Seller" && weekFrequency?.value === 3 ? (
                              <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                                <span style={{ color: "red" }}>
                                  {" "}
                                  Seller has decided to receive this email once in three weeks.
                                </span>{" "}
                              </span>
                            ) : !loadingStatus && data?.frequencyUpdatedBy == "Seller" && weekFrequency?.value === 4 ? (
                              <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                                <span style={{ color: "red" }}>
                                  {" "}
                                  Seller has decided to receive this email once in four weeks.
                                </span>{" "}
                              </span>
                            ) : (
                              ""
                            )}

                            <div className="d-flex align-items-center  mt-3 flex-wrap">
                              <div>
                                <label for="weeklyReportInput">Seller ARR Frequency : </label>
                              </div>
                              <div style={{ flexGrow: "2" }}>
                                <Select
                                  menuPosition="fixed"
                                  menuShouldScrollIntoView
                                  styles={customStyles}
                                  placeholder="Select Week"
                                  value={weekFrequency}
                                  options={weekFrequencyOptions}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.value}
                                  onChange={(e) => {
                                    handleWeekReportEmail(e?.value);
                                    setWeekFrequency(e);
                                  }}
                                />
                              </div>
                            </div>
                            {!data?.lastWeeklyReport?.sender?.name ? (
                              ""
                            ) : (
                              <span className="mb-10 mt-10 d-block " style={{ whiteSpace: "pre-line" }}>
                                <span>
                                  {" "}
                                  Seller Asset Recovery Report was last sent on{" "}
                                  {data?.lastWeeklyReport?.createdAt
                                    ? moment(data?.lastWeeklyReport?.createdAt).format("MM-DD-yyyy")
                                    : "N/A"}{" "}
                                  by {data?.lastWeeklyReport?.sender?.name}.{" "}
                                </span>{" "}
                              </span>
                            )}
                            <div className="d-flex  align-items-center mt-2 flex-wrap">
                              <a
                                onClick={() => {
                                  window.open(
                                    process.env.REACT_APP_MODE === "development"
                                      ? `https://admin-dev.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=local`
                                      : `https://admin.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=local`,
                                  );
                                }}
                                target="_blank"
                                class={`btn btn-other btn-xs-size btn-auto `}
                                style={{ marginTop: "10px", padding: "7px 5px" }}
                              >
                                Local Push{" "}
                                {!props?.loadingBuyerPush && keyPushCount?.local > 0
                                  ? `(${keyPushCount?.local})`
                                  : "(0)"}
                              </a>
                              <a
                                href={
                                  process.env.REACT_APP_MODE == "development"
                                    ? `https://newadmin-dev.salvex.com/listings/report/individual-push?id=${props?.auctionId}`
                                    : `https://newadmin.salvex.com/listings/report/individual-push?id=${props?.auctionId}`
                                }
                                target="_blank"
                                class={`btn btn-primary btn-primary-group btn-xs-size btn-auto `}
                                style={{ marginTop: "10px", padding: "7px 5px", marginLeft: "0px" }}
                              >
                                Individual Push
                              </a>
                              <a
                                href={
                                  process.env.REACT_APP_MODE == "development"
                                    ? `https://admin-dev.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=regional`
                                    : `https://admin.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=regional`
                                }
                                target="_blank"
                                class={`btn btn-other btn-xs-size btn-auto `}
                                style={{ marginTop: "10px", padding: "7px 5px" }}
                              >
                                Regional Push{" "}
                                {!props?.loadingBuyerPush && keyPushCount?.regional > 0
                                  ? `(${keyPushCount?.regional})`
                                  : "(0)"}
                              </a>
                              {keyPushCountAccess ? (
                                <>
                                  <a
                                    href={
                                      process.env.REACT_APP_MODE == "development"
                                        ? `https://admin-dev.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=global`
                                        : `https://admin.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=global`
                                    }
                                    target="_blank"
                                    class={`btn btn-primary btn-primary-group btn-xs-size btn-auto `}
                                    style={{ marginTop: "10px", padding: "7px 5px", marginLeft: "0px" }}
                                  >
                                    Global Push{" "}
                                    {!props?.loadingBuyerPush && keyPushCount?.global > 0
                                      ? `(${keyPushCount?.global})`
                                      : "(0)"}
                                  </a>
                                  <a
                                    href={
                                      process.env.REACT_APP_MODE == "development"
                                        ? `https://admin-dev.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=combine`
                                        : `https://admin.salvex.com/admin/_listings/views/key_buyer.cfm?aucID=${props?.auctionId}&tr=combine`
                                    }
                                    target="_blank"
                                    class={`btn btn-other btn-xs-size btn-auto`}
                                    style={{ marginTop: "10px", padding: "7px 5px", marginLeft: "0px" }}
                                  >
                                    All Key Push{" "}
                                    {!props?.loadingBuyerPush && keyPushCount?.combine > 0
                                      ? `(${keyPushCount?.combine})`
                                      : "(0)"}
                                  </a>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="d-block mt-2">
                              <span
                                style={{ marginBottom: "10px" }}
                                className="link-item add-more asking-price-tool d-block"
                                onClick={() => setShowPushHistory(true)}
                              >
                                Push History
                              </span>
                            </div>
                          </td>
                        </>
                      )}
                      <td>
                        {latestAcceptedValue?.type ? (
                          <li className="badge-item cursor-pointer ">
                            <div className={`badge-box bg-purple`} style={{ color: "white" }}>
                              Bid :{" "}
                              <span className="number-bold">
                                {latestAcceptedValue?.bid_amount
                                  ? "$" +
                                    new Intl.NumberFormat("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(latestAcceptedValue.bid_amount)
                                  : "$0"}{" "}
                                {latestAcceptedValue?.type ? "Accepted" : "Rejected"}
                              </span>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}

                        {latestBids?.latestBids?.length > 0 ? (
                          <li className="badge-item cursor-pointer ">
                            <div className={`badge-box gsp`}>
                              High Bid :{" "}
                              <span className="number-bold">
                                {latestBids?.latestBids?.[0]?.total_bid
                                  ? "$" +
                                    new Intl.NumberFormat("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(latestBids?.latestBids?.[0]?.total_bid)
                                  : "$0.00"}
                              </span>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                        {detail?.for_donation ? (
                          <li className="badge-item cursor-pointer">
                            <div className={`badge-box`} onClick={() => props?.setPricingModal(true)}>
                              Seller Asking Price : <span className="number-bold text-green">DONATED</span>
                              {latestBids?.isSellerAccepted && (
                                <img style={{ marginBottom: "4px", height: "15px" }} src={icon2} alt="" />
                              )}
                              &nbsp;
                              <br />
                            </div>
                          </li>
                        ) : (
                          <li className="badge-item cursor-pointer">
                            <div
                              className={`badge-box ${!priceInfo?.auction_details?.seller_reserve && "neglected-deal"}`}
                              onClick={() => props?.setPricingModal(true)}
                            >
                              Seller Asking Price :{" "}
                              <span className="number-bold">
                                {priceInfo?.auction_details?.seller_reserve
                                  ? "$" +
                                    new Intl.NumberFormat("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(priceInfo?.auction_details?.seller_reserve)
                                  : "$0"}{" "}
                                {props?.auction_details?.seller_reserve &&
                                  !isNaN(props?.auction_details?.seller_reserve) &&
                                  productDetails?.pipeDetail?.tonnage &&
                                  !isNaN(productDetails?.pipeDetail?.tonnage) &&
                                  productDetails?.pipeDetail?.tonnage > 0 && (
                                    <span class="fw-normal">
                                      (
                                      {(
                                        props?.auction_details?.seller_reserve / productDetails?.pipeDetail?.tonnage
                                      )?.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }) + "/MT"}
                                      )
                                    </span>
                                  )}
                                <span class="fw-normal">
                                  (
                                  {priceInfo?.auction_details?.seller_reserve && priceInfo?.auction_details?.quantity
                                    ? "$" +
                                      new Intl.NumberFormat("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(
                                        (
                                          priceInfo?.auction_details?.seller_reserve /
                                          priceInfo?.auction_details?.quantity
                                        ).toFixed(2),
                                      )
                                    : "$0"}{" "}
                                  Per Unit)
                                </span>
                              </span>
                              {latestBids?.isSellerAccepted && (
                                <img style={{ marginBottom: "4px", height: "15px" }} src={icon2} alt="" />
                              )}
                              &nbsp;
                              <br />
                            </div>
                          </li>
                        )}
                        <li className="badge-item cursor-pointer">
                          <div
                            className={`badge-box ${
                              sellerAskingPrice > "50"
                                ? "rsp"
                                : sellerAskingPrice <= "50" && sellerAskingPrice > "20"
                                ? "ysp"
                                : sellerAskingPrice <= "20"
                                ? "gsp"
                                : ""
                            }`}
                          >
                            Seller Asking Price Percentage :{" "}
                            <span className="number-bold">{sellerAskingPrice + "%"}</span>
                          </div>
                        </li>

                        <li className="badge-item cursor-pointer">
                          <div
                            className={`badge-box ${
                              !priceInfo?.auction_prices?.find(
                                (item) => item.description === "Original Wholesale Value",
                              )?.p_value
                                ? "neglected-deal"
                                : ""
                            }`}
                            onClick={() => props?.setPricingModal(true)}
                          >
                            Wholesale Value :{" "}
                            <span className="number-bold">
                              {priceInfo?.auction_prices?.find(
                                (item) => item.description === "Original Wholesale Value",
                              )?.p_value
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(
                                    priceInfo?.auction_prices?.find(
                                      (item) => item.description === "Original Wholesale Value",
                                    )?.p_value,
                                  )
                                : "$0.00"}
                            </span>
                            <div class="form-check dark-tick-checkbox d-inline" onClick={(e) => e.stopPropagation()}>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="chk_wholesale_value_feature"
                                checked={wholesaleValue}
                                id="chk_wholesale_value_feature"
                                onClick={(e) => {
                                  const data = { ...priceFeatureData };
                                  data.price_type = e.target.value;
                                  setWholesaleValue(!wholesaleValue);
                                  setPriceFeatureData(data);
                                }}
                              />
                              <label class="form-check-label small-check-box" for="chk_wholesale_value_feature"></label>
                            </div>
                          </div>
                        </li>
                        <li className="badge-item cursor-pointer">
                          <div
                            className={`badge-box ${
                              !priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")?.p_value
                                ? "neglected-deal"
                                : ""
                            }`}
                            onClick={() => props?.setPricingModal(true)}
                          >
                            Public Asking Price :{" "}
                            <span className="number-bold">
                              {priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")?.p_value
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(
                                    priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")
                                      ?.p_value,
                                  )
                                : "$0.00"}
                              {priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")
                                ?.p_value &&
                                !isNaN(
                                  priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")
                                    ?.p_value,
                                ) &&
                                productDetails?.pipeDetail?.tonnage &&
                                !isNaN(productDetails?.pipeDetail?.tonnage) &&
                                productDetails?.pipeDetail?.tonnage > 0 && (
                                  <span class="fw-normal">
                                    (
                                    {(
                                      priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")
                                        ?.p_value / productDetails?.pipeDetail?.tonnage
                                    )?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) +
                                      "/MT"}
                                    )
                                  </span>
                                )}{" "}
                            </span>
                            <div class="form-check dark-tick-checkbox d-inline" onClick={(e) => e.stopPropagation()}>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="chk_asking_price_feature"
                                checked={askingPrice}
                                id="chk_asking_price_feature"
                                onClick={(e) => {
                                  const data = { ...priceFeatureData };
                                  data.price_type = e.target.value;
                                  setAskingPrice(!askingPrice);
                                  setPriceFeatureData(data);
                                }}
                              />
                              <label class="form-check-label small-check-box" for="chk_asking_price_feature"></label>
                            </div>
                          </div>
                        </li>

                        {latestBids?.bidInfo?.seller_bid ? (
                          <li className="badge-item cursor-pointer ">
                            <div className={`badge-box`}>
                              Last Bid Amount :{" "}
                              <span className="number-bold">
                                {latestBids?.bidInfo?.seller_bid
                                  ? "$" +
                                    new Intl.NumberFormat("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(latestBids?.bidInfo?.seller_bid)
                                  : "$0.00"}
                              </span>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                        {latestBids?.bidInfo?.seller_bid_date && (
                          <li className="badge-item cursor-pointer ">
                            <div className={`badge-box`}>
                              Last Bid Date :{" "}
                              <span className="number-bold">
                                <DateFormat date={latestBids?.bidInfo?.seller_bid_date} />
                              </span>
                            </div>
                          </li>
                        )}
                        <div class="mt-3 fw-medium">
                          {bidLog &&
                            bidLog.length > 0 &&
                            bidLog.map((val) =>
                              val?.type ? (
                                <div className="col-text mt-2">
                                  {val.updated_dt.replace(/(AM|PM)/, " $1") + " CST"} Bid{" "}
                                  <span className={val.type ? "text-success" : "text-danger"}>
                                    {val.bid_amount
                                      ? "$" +
                                        new Intl.NumberFormat("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }).format(val.bid_amount)
                                      : "$0"}{" "}
                                    {val.type ? "Accepted" : "Rejected"}
                                  </span>
                                  {!val.type &&
                                    `- Seller Asking ${
                                      val.sp_amount
                                        ? "$" +
                                          new Intl.NumberFormat("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }).format(val.sp_amount)
                                        : "$0"
                                    }`}
                                </div>
                              ) : (
                                ""
                              ),
                            )}
                        </div>
                        <div class="mt-3 fw-600">
                          <div className="d-flex align-items-center justify-content-start mt-1">
                            <div style={{ maxWidth: "60%", marginRight: "15px" }}>
                              <label for="upper-range">High Public Asking Price</label>
                              <input
                                id="upper-range"
                                type="number"
                                className="form-control"
                                placeholder="Upper Range"
                                disabled
                                value={priceRange?.from}
                              />
                              <label for="upper-range">{"$" + numberFormat(priceRange?.from)}</label>
                            </div>
                            <div style={{ maxWidth: "60%", marginRight: "15px" }}>
                              <label for="upper-range">Low Reduced Asking Price</label>
                              <input
                                id="upper-range"
                                type="number"
                                className="form-control"
                                placeholder="Lower Range"
                                onChange={(e) => {
                                  const value = { ...priceRange };
                                  value.to = e.target.value;
                                  setPriceRange(value);
                                }}
                                value={priceRange?.to}
                              />
                              <label for="upper-range">{"$" + numberFormat(priceRange?.to)}</label>
                            </div>
                            <div style={{ maxWidth: "100%", marginRight: "15px" }}>
                              <span
                                style={{ marginBottom: "10px" }}
                                className="link-item add-more asking-price-tool d-block"
                                onClick={togglePriceHistory}
                              >
                                History
                              </span>
                              <a
                                href="javascript:void(0)"
                                class={`btn btn-primary btn-xs-size  btn-auto  ${
                                  loadingRange || !priceRange?.from ? "btn-disabled" : ""
                                }`}
                                onClick={submitPriceRange}
                              >
                                Save
                              </a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>
        <AskingPriceHistory
          show={showPriceHistory}
          onHide={togglePriceHistory}
          data={publicAskingPriceData}
          askingPrice={priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")?.p_value}
          wholesaleValue={
            wholesaleValueFeatured
              ? priceInfo?.auction_prices?.find((item) => item.description === "Original Wholesale Value")?.p_value
              : 0
          }
        />
        <Motivation
          show={showMotivation}
          onHide={toggleMotivation}
          setData={setSellerData}
          data={sellerData}
          handleSubmit={handleSaveMotivation}
        />
        <ImageLargeModal
          show={imageGalleryModal}
          onHide={toggleImageGalleryModal}
          items={imageData}
          startIndex={startIndex}
          title={title}
        />
        <SellerAgreementModal
          show={sellerAgreementModal}
          onHide={toggleSellerAgreementModal}
          allAgreements={allAgr}
          auctionId={props.auctionId}
          refStart={props.refStart}
          refStop={props?.refStop}
        />
        <VerifyDocModal
          show={showVerificationDocModal}
          onHide={toggleVerificationDoc}
          userId={data?.user?.s_user_id}
          verificationDoc={verificationDoc}
          loading={loading}
          isUploading={systemAdmin ? true : false}
          isCompany={data?.user?.user_type == "company" ? true : data?.user?.cid >= 1 ? true : false}
          updatePrimarySeller={updatePrimarySeller}
        />
        <PaymentInfoModal show={showPayInfoModal} onHide={togglePayInfo} ref={props.ref} id={data?.user?.s_user_id} />
        <PaymentInfo
          show={showPayInfoEditModal}
          onHide={togglePayInfoEdit}
          ref={props.ref}
          id={data?.user?.s_user_id}
        />
        <DashboardStyling
          show={showCustomizeDashobard}
          onHide={toggleCustomizeDashobard}
          setCustomizeDashobard={setCustomizeDashobard}
          id={data?.user?.s_user_id}
          data={data}
          selectedSellerOption={selectedSellerOption}
          sellerStatus={sellerStatus}
        />
        {showEmailReport && (
          <ARRHistory
            show={showEmailReport}
            onHide={toggleEmailReport}
            data={emailHistoryReport}
            type={"Seller Report"}
            setData={setEmailHistoryReport}
            setFilter={setSARRFilters}
            filter={SARRfilters}
            email={sarrEmail}
            fetchEmailContent={fetchEmailContent}
          />
        )}
        {showSentEmail && <ViewBARREmail show={showSentEmail} onHide={toggleSentEmail} data={sentEmailContent} />}
        {showPushHistory && (
          <PushHistory
            show={showPushHistory}
            onHide={() => setShowPushHistory(!showPushHistory)}
            data={pushHistorydata}
          />
        )}
        {showNewSeller && (
          <FindUser
            title={"Find Seller"}
            show={showNewSeller}
            onHide={toggleNewSeller}
            searchData={searchData}
            setSearchData={setSearchData}
            handleSubmit={handleSearch}
            loading={loadingSearch}
            response={response}
            resData={resData}
            country={country}
            state={state}
            handleNewSearch={handleNewSearch}
            setUser={setSeller}
            handleSelectUser={handleSelectSeller}
          />
        )}
        <VerificationStatusNote
          show={showVerificationNote}
          onHide={toggleVerificationNote}
          title={"Verification Status Notes"}
          note={data?.verification?.verification_status_note ? data?.verification?.verification_status_note : ""}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPrimarySeller: (params, sellerId, memberId, s_id, callback) =>
    dispatch(getPrimarySeller(params, sellerId, memberId, s_id, callback)),
  setShowAddPayableModal: (data) => dispatch(setShowAddPayableModal(data)),
  setPricingModal: (data) => dispatch(setQtyPricingModal(data)),
  setListingAgreement: (data) => dispatch(setListingAgreement(data)),
  updateSendWeeklyReportEmailFrequency: (id, data, callback) =>
    dispatch(updateSendWeeklyReportEmailFrequency(id, data, callback)),

  updateSendReportEmail: (params, callback) => dispatch(updateSendReportEmail(params, callback)),
  getEmailReportLog: (userId, params, callback) => dispatch(getEmailReportLog(userId, params, callback)),
  updateMotivation: (params, data, callback) => dispatch(updateMotivation(params, data, callback)),
  getMotivation: (params, callback) => dispatch(getMotivation(params, callback)),
  userAuctionListings: (id, callback) => dispatch(userAuctionListings(id, callback)),
  getVerificationDoc: (id, callback) => dispatch(getVerificationDoc(id, callback)),
  updatePriceRange: (id, data, callback) => dispatch(updatePriceRange(id, data, callback)),
  getAskingPriceHistory: (id, callback) => dispatch(getAskingPriceHistory(id, callback)),
  detailListing: (params, callback) => dispatch(detailListing(params, callback)),
  getSellers: (id, params, callback) => dispatch(getSellers(id, params, callback)),
  changePrimarySeller: (id, params, callback) => dispatch(changePrimarySeller(id, params, callback)),
  getCountries: (callback) => dispatch(getCountries(callback)),
  getStates: (callback) => dispatch(getStates(callback)),
  getKeyBuyerCount: (id, callback) => dispatch(getKeyBuyerCount(id, callback)),
  getSellerMotivation: (id, callback) => dispatch(getSellerMotivation(id, callback)),
  updatePriceFeature: (id, data, callback) => dispatch(updatePriceFeature(id, data, callback)),
  getPriceInfo: (id, callback) => dispatch(getPriceInfo(id, callback)),
  saveSellerDisplayRequest: (data, callback) => dispatch(saveSellerDisplayRequest(data, callback)),
  saveCustomizeDashboard: (id, data, callback) => dispatch(saveCustomizeDashboard(id, data, callback)),
});

const mapStateToProps = (state) => ({
  primarySellerData: primarySellerData(state),
  getListings: getListings(state),
  getLatestActivity: getLatestActivity(state),
  payablesModal: getModals(state)?.payablesModal,
  listingAgreement: getModals(state)?.listingAgreement,
  payableDetails: payableDetails(state),
  getUser: getUser(state),
  dashboardData: dashboardData(state),
  getAskingPriceData: getMisc(state)?.askingPriceData,
  getListingProductDetail: getListingProductDetail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimarySellerNew);
