import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";
import misc from "./misc";

const slice = createSlice({
  name: "modal",
  initialState: {
    categoryModal: false,
    moreDetailsModal: false,
    locationModal: false,
    startegicModal: false,
    conditionModal: false,
    qtyPricingModal: false,
    primarySellerModal: false,
    privateDocModal: false,
    publicDocModal: false,
    payablesModal: false,
    descriptionModal: false,
    aiDescription: false,
    aiLead: false,
    aiCarbon: false,
    aiMfr: false,
    featuredNotes: false,
    listingAgreement: false,
    title: false,
    uploadImage: false,
    titleType:""
  },
  reducers: {
    setCategoryModal: (state, action) => {
      state.categoryModal = action.payload;
    },
    setMoreDetailsModal: (state, action) => {
      state.moreDetailsModal = action.payload;
    },
    setLocationModal: (state, action) => {
      state.locationModal = action.payload;
    },
    setStrategicModal: (state, action) => {
      state.startegicModal = action.payload;
    },
    setConditionModal: (state, action) => {
      state.conditionModal = action.payload;
    },
    setQtyPricingModal: (state, action) => {
      state.qtyPricingModal = action.payload;
    },
    setPrimarySellerModal: (state, action) => {
      state.primarySellerModal = action.payload;
    },
    setPrivateDocModal: (state, action) => {
      state.privateDocModal = action.payload;
    },
    setPublicDocModal: (state, action) => {
      state.publicDocModal = action.payload;
    },
    setShowAddPayableModal: (state, action) => {
      state.payablesModal = action.payload;
    },
    setDescription: (state, action) => {
      state.descriptionModal = action.payload;
    },
    setAIDescription: (state, action) => {
      state.aiDescription = action.payload;
    },
    setAILead: (state, action) => {
      state.aiLead = action.payload;
    },
    setShowAIMfr: (state, action) => {
      state.aiMfr = action.payload;
    },
    setShowAICarbon: (state, action) => {
      state.aiCarbon = action.payload;
    },
    setFeaturedNotes: (state, action) => {
      state.featuredNotes = action.payload;
    },
    setListingAgreement: (state, action) => {
      state.listingAgreement = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setUploadImage: (state, action) => {
      state.uploadImage = action.payload;
    },
    setTitleType: (state, action) => {
      state.titleType = action.payload;
    },
  },
});

export const {
  setCategoryModal,
  setMoreDetailsModal,
  setLocationModal,
  setStrategicModal,
  setConditionModal,
  setQtyPricingModal,
  setPrimarySellerModal,
  setPrivateDocModal,
  setPublicDocModal,
  setShowAddPayableModal,
  setShowAICarbon,
  setAIDescription,
  setAILead,
  setDescription,
  setShowAIMfr,
  setFeaturedNotes,
  setListingAgreement,
  setTitle,
  setUploadImage,
  setTitleType,
} = slice.actions;
export default slice.reducer;

export const getModals = createSelector(
  (state) => state.entities.modal,
  (modal) => modal,
);
