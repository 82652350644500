import HTMLReactParser from "html-react-parser";
import { flatten } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import selectedIcon from "../../../include/images/yellow-star-icon.svg";
import darkStarIcon from "../../../include/images/dark-star-icon.svg";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import DeleteModal from "../../../layouts/modals/deleteModal";
import commentsAndNotes, {
  addCommentsAndNotes,
  commentsAndNotesDetails,
  commentsAndNotesFetched,
  deleteChat,
  getAdminList,
  getCommentsAndNotes,
  getTaskType,
} from "../../../store/commentsAndNotes";
import Skeleton from "react-loading-skeleton";
import NextButton from "../../../common/form/nextButton";
import DateFormat from "../../../common/DateFormat/dateFormat";
import Trash from "../../../include/images/trash-18x18.svg";
import { useLocation } from "react-router-dom";
import helpCircle from "../../../include/images/help-circle.svg";
import queryString from "query-string";
import { getProfile } from "../../../utils.js/localStorageServices";
import moment from "moment";
import { getPendingTasks } from "../../../store/tasks";
import { getStrategicTeam } from "../../../store/strategicTeam";
import AddAdminList from "../../../layouts/modals/commentsAndNotes/addAdminList";
import FeaturedNotes from "../../../layouts/modals/commentsAndNotes/featuredNotes";
import { getModals, setFeaturedNotes, setStrategicModal } from "../../../store/modal";
import { getProductCount } from "../../../store/listingProduct";
import StrategicTeam from "../strategicTeam/strategicTeam";
import Editor from "../../../common/editor/editor";
import Edit from "../../../include/images/edit-18x18.svg";
import ReactTooltip from "react-tooltip";
import { twoDecimals } from "../../../utils.js/conversion";

const customStyles1 = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#47ad1d",
      color: "#fff",
    },
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: state.isSelected ? "#fff" : "black",
    backgroundColor: state.isSelected ? "#47ad1d" : provided.backgroundColor,
    // maxHeight: "84px",
  }),
  control: (base, state) => ({
    ...base,
    // height: 60,
    minHeight: 42,
    borderColor: state.isFocused ? "#47AD1D" : "#e4e4e4",
    boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    border: state.isHovered ? "0" : "1px solid #e4e4e4",

    "&:hover": {
      borderColor: state.isFocused ? "#47AD1D" : "0",
      boxShadow: state.isFocused ? "0 0 0 0.5px #47AD1D" : "0",
    },
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    "&:hover": {
      backgroundColor: "rgb(95, 50, 187, 10%)",
      color: "#6119c0",
    },
  }),
};

const CommentsAndNotes = (props) => {
  const commentsAndNotesData =
    props &&
    props.commentsAndNotesDetails &&
    props.commentsAndNotesDetails.commentsAndNotes &&
    props.commentsAndNotesDetails.commentsAndNotes.data;
  const totalPages = props?.commentsAndNotesDetails?.commentsAndNotes?.pagination?.totalPages;
  const totalCount = props?.commentsAndNotesDetails?.commentsAndNotes?.pagination?.totalRecords;

  const adminData = props && props.commentsAndNotesDetails && props.commentsAndNotesDetails?.adminList?.data;
  const location = useLocation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [chatId, setChatId] = useState("");
  const [addMessage, setAddMessage] = useState({
    task_type: 0,
    to_id: "",
    message: "",
    send_email: true,
    all_seller_listings: false,
    cc_emails_list: [],
  });
  const [selectTask, setSelectTask] = useState(null);
  const [selectAdmin, setSelectAdmin] = useState([]);
  const [onDelete, setOnDelete] = useState(false);
  const [pendingTask, setPendingTask] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [taskType, setTaksType] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [strategicList, setStrategicList] = useState([]);
  const [page, setPage] = useState(1);
  const [enable, setEnable] = useState(false);
  // const [showFeatured, setShowFeatured] = useState(false);
  const showFeatured = props?.showFeatured;

  const toggleFeatured = () => {
    props?.setShowFeatured(!showFeatured);
  };

  const toggleShowAdmin = () => {
    setShowAddAdmin(!showAddAdmin);
  };

  // Refs
  const msgRef = useRef();
  // useEffect(() => {
  //   !onDelete && !showMore && setCommentsData(commentsAndNotesData?.slice(0, 5));
  // }, [props.refStop, commentsAndNotesData]);
  //Get to Admin list
  useEffect(() => {
    if (location && location.search) {
      const id = queryString.parse(location.search).id;
      setAdminList([]);
      setSelectAdmin([]);
      props.getAdminList(id, (res) => {
        if (res.status === 200) {
          setAdminList(res.data.data);
        }
      });
    }
  }, [location]);

  //Get Task Type
  useEffect(() => {
    props.getTaskType((res) => {
      if (res.status === 200) {
        setTasks(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    if (props?.auctionId) {
      setLoading(true);
      props?.getCommentsAndNotes(props?.auctionId, { page: 1 }, (res) => {
        if (res?.status === 200) {
          setEnable(true);
          setLoading(false);
          setCommentsData([...(commentsData || []), ...res?.data?.data]);
        } else {
          setLoading(false);
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [props?.auctionId]);

  //Pagination
  useEffect(() => {
    if (enable) {
      props?.refStart();
      setLoading(true);
      props?.getCommentsAndNotes(props?.auctionId, { page: page }, (res) => {
        if (res?.status === 200) {
          props?.refStop();
          setLoading(false);
          setCommentsData([...(commentsData || []), ...res?.data?.data]);
        } else {
          props?.refStop();
          setLoading(false);
          toast(<AlertError message={res?.data?.message ? res?.data?.message : "Something Went Wrong"} />);
        }
      });
    }
  }, [page]);

  const toggleDeleteModal = (chat_id) => {
    setChatId(chat_id);
    setShowDeleteModal(!showDeleteModal);
  };
  const reloadCommentAndNotes = () => {
    props.refStart();
    props.getCommentsAndNotes(props.auctionId, { page: 1 }, (res) => {
      if (res?.status === 200) {
        props.refStop();
      } else {
        props.refStop();
        props.refStop();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    if (showMore) {
      setCommentsData(commentsAndNotesData);
    } else {
      setCommentsData(commentsAndNotesData?.slice(0, 5));
    }
  }, [showMore]);

  //Add Notes and Comments
  const handleSubmit = () => {
    if (addMessage.message) {
      const payload = { ...addMessage };
      payload.task_type = taskType;
      props.refStart();
      setLoading(true);
      props.addCommentsAndNotes(props.auctionId, payload, (res) => {
        if (res.status === 200) {
          props.getPendingTasks(props.auctionId, (res) => {
            props?.getProductCount(props?.auctionId, (res) => {});
            if (res.status === 200) {
              if (res?.data?.data?.length > 0) {
                props.setShowPendingTask(true);
              }
              setPendingTask(res.data.data);
              setTaksType(0);
              const data = {
                task_type: 0,
                to_id: "",
                message: "",
                send_email: true,
                all_seller_listings: false,
                cc_emails_list: [],
              };
              setAddMessage(data);
              props.refStop();
              msgRef.current.value = "";
              setSelectTask(null);
              setSelectAdmin([]);
              props.getCommentsAndNotes(props.auctionId, { page: 1 }, (res1) => {
                setCommentsData(res1.data.data.slice(0, 5));
              });
              setLoading(false);
              toast(<AlertSuccess message={"Information Saved"} />);
            } else {
              props.refStop();
              setLoading(false);
              toast(<AlertError message={res.data.data ? res.data.daa : "Something went Wrong!"} />);
            }
          });
        } else {
          props.refStop();
          setLoading(false);
          toast(<AlertError message={res.data.data ? res.data.daa : "Something went Wrong!"} />);
        }
      });
    } else {
      toast(<AlertError message={"Please enter a message."} />);
    }
  };

  //Delete Chat
  const deleteHandler = () => {
    setLoadingDelete(true);
    setOnDelete(true);
    props.refStart();
    const id = chatId;
    props.deleteChat(id, (res) => {
      if (res && res.status === 200) {
        props?.getProductCount(props?.auctionId, (res) => {});
        const data = [...commentsData.filter((item) => item.chat_id !== id)];
        setCommentsData(data);
        // props.commentsAndNotesFetched({ data });
        props.refStop();
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        props.refStop();
        setLoadingDelete(false);
        setLoadingDelete(false);
        toggleDeleteModal();
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const options = flatten(tasks)?.map((task) => ({
    value: task?.task_type,
    label: task?.description,
    further: task?.further,
    options: task?.options,
  }));

  const session = getProfile();

  // useEffect(() => {
  //   if (commentsData) {
  //     const container = document.getElementById("comments-messages");
  //     var node = document.createElement("SPAN");
  //     node.innerText = "\n Pers. " + (i + 1) + " \u00a0";
  //     container.appendChild(node);
  //   }
  // }, [commentsData]);

  const teamDetails = props?.getStrategicTeam?.strategicTeam?.detail;

  useEffect(() => {
    if (teamDetails) {
      const data = [...selectAdmin];
      const data1 = [];

      // if (teamDetails.author_name) {
      //   data.push({
      //     name: teamDetails.author_name,
      //     value: teamDetails.author,
      //   });
      //   data1.push({
      //     name01: teamDetails.author_name,
      //     member_id: teamDetails.author,
      //   });
      // }
      if (teamDetails.individual_origin_name) {
        data.push({
          name: teamDetails.individual_origin_name,
          value: teamDetails.individual_origin,
        });
        data1.push({
          name01: teamDetails.individual_origin_name,
          member_id: teamDetails.individual_origin,
        });
      }
      if (teamDetails.bc_id_name) {
        data.push({
          name: teamDetails.bc_id_name,
          value: teamDetails.bc_id,
        });

        data1.push({
          name01: teamDetails.bc_id_name,
          member_id: teamDetails.bc_id,
        });
      }
      if (teamDetails.sc_id_name) {
        data.push({
          name: teamDetails.sc_id_name,
          value: teamDetails.sc_id,
        });
        data1.push({
          name01: teamDetails.sc_id_name,
          member_id: teamDetails.sc_id,
        });
      }
      if (teamDetails.dc_id_name) {
        data.push({
          name: teamDetails.dc_id_name,
          value: teamDetails.dc_id,
        });
        data1.push({
          name01: teamDetails.dc_id_name,
          member_id: teamDetails.dc_id,
        });
      }
      if (teamDetails.dc_id_1_name) {
        data.push({
          name: teamDetails.dc_id_1_name,
          value: teamDetails.dc_id_1,
        });
        data1.push({
          name01: teamDetails.dc_id_1_name,
          member_id: teamDetails.dc_id_1,
        });
      }
      if (teamDetails.dc_id_2_name) {
        data.push({
          name: teamDetails.dc_id_2_name,
          value: teamDetails.dc_id_2,
        });
        data1.push({
          name01: teamDetails.dc_id_2_name,
          member_id: teamDetails.dc_id_2,
        });
      }
      if (teamDetails.dc_id_3_name) {
        data.push({
          name: teamDetails.dc_id_3_name,
          value: teamDetails.dc_id_3,
        });
        data1.push({
          name01: teamDetails.dc_id_3_name,
          member_id: teamDetails.dc_id_3,
        });
      }
      if (teamDetails.dc_id_4_name) {
        data.push({
          name: teamDetails.dc_id_4_name,
          value: teamDetails.dc_id_4,
        });
        data1.push({
          name01: teamDetails.dc_id_4_name,
          member_id: teamDetails.dc_id_4,
        });
      }
      if (teamDetails.mgr_name) {
        data.push({
          name: teamDetails.mgr_name,
          value: teamDetails.mgr_id,
        });
        data1.push({
          name01: teamDetails.mgr_name,
          member_id: teamDetails.mgr_id,
        });
      }
      if (teamDetails.admin_name) {
        data.push({
          name: teamDetails.admin_name,
          value: teamDetails.admin_id,
        });
        data1.push({
          name01: teamDetails.admin_name,
          member_id: teamDetails.admin_id,
        });
      }
      if (teamDetails.outside_agent_name) {
        data.push({
          name: teamDetails.outside_agent_name,
          value: teamDetails.outside_agent,
        });
        data1.push({
          name01: teamDetails.outside_agent_name,
          member_id: teamDetails.outside_agent,
        });
      }
      // if (teamDetails.inspector_name) {
      //   data.push({
      //     name: teamDetails.inspector_name,
      //     value: teamDetails.inspector,
      //   });
      //   data1.push({
      //     name01: teamDetails.inspector_name,
      //     member_id: teamDetails.inspector,
      //   });
      // }

      setSelectAdmin([...new Map(data.map((item) => [item["name"], item])).values()]);
      const ids = [];
      const d = data1?.map((ie) => {
        // if (!ids?.includes(ie?.member_id)) { Uncomment if needed
        if (!ids?.find((val) => val?.name01 === ie?.name01)) {
          ids.push(ie);
        }
      });
      setStrategicList(ids);
    }
  }, [props?.auctionId, teamDetails]);

  useEffect(() => {
    const a = adminData?.filter((iu) => iu?.name01?.toLowerCase()?.includes(keyword?.toLowerCase())) || [];
    setAdminList(a);
  }, [keyword]);

  //Handle Update Starred
  const handleStarred = (chatId) => {
    props?.refStart();
    const payload = {
      chat_id: chatId,
    };
    props?.addCommentsAndNotes(props?.auctionId, payload, (res) => {
      if (res.status === 200) {
        props?.refStop();
        setCommentsData((pre) =>
          pre?.map((item) => (item?.chat_id === chatId ? { ...item, isStarred: !item?.isStarred } : item)),
        );
        props?.getProductCount(props?.auctionId, (res) => {});
      } else {
        props?.refStop();
        setShowMore(false);
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const showStrategicModal = props?.startegicModal;

  const toggleStrategicModal = () => {
    props?.setShowStrategicModal(!showStrategicModal);
  };

  const getTooltipContent = (ip) => {
    let content = "";
    if (teamDetails?.author_name === ip?.name01) content += " Created By,";
    if (teamDetails?.individual_origin_name === ip?.name01) content += " Individual Origin,";
    if (teamDetails?.d?.comm_member_1 === ip?.name01) content += "Trader,";
    if (teamDetails?.d?.comm_member_2 === ip?.name01) content += " Business Development,";
    if (teamDetails?.d?.comm_member_3 === ip?.name01) content += " Administrator 1,";
    if (teamDetails?.d?.comm_member_4 === ip?.name01) content += " Administrator 2";
    if (teamDetails?.d?.comm_member_5 === ip?.name01) content += " Mid Manager,";
    if (teamDetails?.d?.comm_member_6 === ip?.name01) content += "Optimizer,";
    if (teamDetails?.d?.outside_agent_name === ip?.name01) content += " Outside Agent,";
    if (teamDetails?.d?.mgr_name === ip?.name01) content += " Trader Manager,";
    if (teamDetails?.d?.admin_name === ip?.name01) content += " BD Manager,";
    return content;
  };

  return (
    <div class="details-page-widget-block" id={props.id}>
      <div
        class={`page-widget-head d-flex align-items-center justify-content-between ${
          !session?.accessLevels?.accounting_payment && "mb-1"
        }`}
      >
        <div class="w-100 d-flex align-items-between space-between">
          <div class="page-widget-label text-capitalize">Comments and Notes</div>
          <a
            href={`https://app.logrocket.com/z6fl3h/salvex/metrics/logrocket-overview?filters=%255B%257B%2522id%2522%253A%2522SJfxxlSd5%2522%252C%2522type%2522%253A%2522timeOnPage%2522%252C%2522params%2522%253A%257B%2522url%2522%253A%257B%2522operator%2522%253A%257B%2522name%2522%253A%2522contains%2522%252C%2522type%2522%253A%2522CONTAINS%2522%252C%2522hasStrings%2522%253Atrue%257D%252C%2522strings%2522%253A%255B%2522listing-detail%253Fid%253D${props?.auctionId}%2522%255D%252C%2522type%2522%253A%2522href%2522%257D%252C%2522time%2522%253A%257B%2522operator%2522%253A%257B%2522name%2522%253A%2522at%2520least%2520once%2522%252C%2522type%2522%253A%2522GT%2522%252C%2522hasStrings%2522%253Afalse%252C%2522hideLabel%2522%253Atrue%252C%2522value%2522%253A%25221s%2522%257D%252C%2522strings%2522%253A%255B%25221s%2522%255D%257D%257D%257D%255D&range=6M`}
            target="blank"
            class="btn btn-primary btn-sm"
          >
            Log Rocket
          </a>
        </div>
      </div>
      {!session?.accessLevels?.accounting_payment && (
        <div className="badge-box startegic-team-badge ">
          Commissions Status:
          <span
            style={{
              color: teamDetails?.commission_status === 3 && "#41ad1d",
            }}
          >
            {teamDetails?.commission_status === 2
              ? " Submitted"
              : teamDetails?.commission_status === 3
              ? " Approved"
              : " Pending Review"}
          </span>
        </div>
      )}
      <StrategicTeam
        auctionId={props?.auctionId}
        // refStart={props?.refStart()}
        // refStop={props?.refStop()}
        id="strategicRef"
      />
      <div class="page-widget-body mt-3">
        <div class="widget-form-widget comments-notes-parent">
          {props.loadingMain ? (
            <Skeleton height="300px" width="100%" />
          ) : (
            <>
              {/* <div class="wfw-label-row w-100 d-flex flex-wrap justify-content-between">
                <div class="wfw-label w-100 pr-3">
                  <div class="wfw-txt-bold fw-medium">Additional Recipients</div>
                  <p class="wfw-txt">Internal Ref. and Trader already included</p>
                </div>
                <div class="wfw-btn w-50">
                  <a href="javascript:void(0)" class="btn btn-primary">
                New Research Assignment
              </a>
                </div>
              </div> */}

              <div class=" mb-15">
                <div className="d-flex">
                  <div
                    class={`form-checkbox-group-row d-flex flex-wrap ${
                      session?.accessLevels?.accounting_payment ? "w-100" : "w-70"
                    }`}
                    style={{ marginLeft: "0px" }}
                  >
                    {strategicList &&
                      strategicList?.length > 0 &&
                      strategicList?.map((ip, k) => (
                        <div class="form-checkbox-item form-checkbox-item-receipt " key={k}>
                          <div class="form-check form-check-receipt dark-tick-checkbox">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={selectAdmin?.some((io) => io?.name == ip?.name01)}
                              id={`${ip?.name01}${k + 90}`}
                              onChange={(e) => {
                                const values = { ...addMessage };
                                const data = [...selectAdmin];
                                if (!selectAdmin?.some((io) => io?.name == ip?.name01)) {
                                  data.push({ name: ip?.name01, value: `${ip?.member_id}` });
                                  const b = data.map((da) => da?.value.toString()).join(",");
                                  values.to_id = b;
                                  setSelectAdmin(data);
                                  setAddMessage(values);
                                } else {
                                  const a = data?.filter((ep) => ep?.name != ip?.name01);
                                  const b = a.map((da) => da?.value.toString()).join(",");
                                  values.to_id = b;
                                  setSelectAdmin(a);
                                  setAddMessage(values);
                                }
                              }}
                            />
                            <label
                              class="form-check-label fw-medium text-nowrap cursor-pointer"
                              for={`${ip?.name01}${k + 90}`}
                              data-for={`${ip?.name01}${k + 90}`}
                              data-tip={
                                getTooltipContent(ip)?.split(",")?.length === 2
                                  ? getTooltipContent(ip)?.split(",")[0]
                                  : getTooltipContent(ip)
                              }
                            >
                              {ip?.name01}
                              {!session?.accessLevels?.accounting_payment && (
                                <ReactTooltip
                                  effect="solid"
                                  place="top"
                                  id={`${ip?.name01}${k + 90}`}
                                  // className="custom-react-tooltip"
                                  type="dark"
                                  html={true}
                                  border={true}
                                />
                              )}
                            </label>
                          </div>
                        </div>
                      ))}
                    {selectAdmin &&
                      selectAdmin?.length > 0 &&
                      selectAdmin?.map((ip, k) =>
                        !strategicList?.find((sd) => sd?.name01 == ip?.name) ? (
                          <div class="form-checkbox-item form-checkbox-item-receipt " key={k}>
                            <div class="form-check form-check-receipt dark-tick-checkbox">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                checked={selectAdmin?.some((io) => io?.name == ip?.name)}
                                id={`${ip?.name}${k + 90}`}
                                onChange={(e) => {
                                  const values = { ...addMessage };
                                  const data = [...selectAdmin];
                                  if (!selectAdmin?.some((io) => io?.name == ip?.name)) {
                                    data.push({ name: ip?.name, value: `${ip?.value}` });
                                    const b = data.map((da) => da?.value.toString()).join(",");
                                    values.to_id = b;
                                    setSelectAdmin(data);
                                    setAddMessage(values);
                                  } else {
                                    const a = data?.filter((ep) => ep?.name != ip?.name);
                                    const b = a.map((da) => da?.value.toString()).join(",");
                                    values.to_id = b;
                                    setSelectAdmin(a);
                                    setAddMessage(values);
                                  }
                                }}
                              />
                              <label
                                class="form-check-label fw-medium text-nowrap cursor-pointer"
                                for={`${ip?.name}${k + 90}`}
                                data-for={`${ip?.name}${k + 90}`}
                                data-tip={
                                  getTooltipContent(ip)?.split(",")?.length === 1
                                    ? getTooltipContent(ip)?.split(",")[0]
                                    : getTooltipContent(ip)
                                }
                              >
                                {ip?.name}
                                {!session?.accessLevels?.accounting_payment && (
                                  <ReactTooltip
                                    effect="solid"
                                    place="top"
                                    id={`${ip?.name}${k + 90}`}
                                    // className="custom-react-tooltip"
                                    type="dark"
                                    html={true}
                                    border={true}
                                  />
                                )}
                              </label>
                            </div>
                          </div>
                        ) : (
                          ""
                        ),
                      )}
                  </div>
                  {!session?.accessLevels?.accounting_payment && (
                    <div className="w-30">
                      <a className="mt-3" href="javascript:void(0)" onClick={toggleStrategicModal}>
                        <img src={Edit} alt="Edit" />
                      </a>
                    </div>
                  )}
                </div>
                <a href="javascript:void(0)" class="link-btn add-more" onClick={() => toggleShowAdmin()}>
                  Click here to add more recipients
                </a>
              </div>
              <div class="form-group-30 mb-15" style={{ marginTop: "15px" }}>
                <Select
                  className="basic-single"
                  classNamePrefix="select-search "
                  placeholder="Select Task"
                  value={selectTask}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.task_type}
                  options={options}
                  styles={customStyles1}
                  onChange={(e) => {
                    setTaksType(e.task_type);
                    const values = { ...addMessage };
                    values.task_type = e.task_type;
                    values.message = e.further ? e.further : "";
                    setSelectTask(e);
                    setAddMessage(values);
                  }}
                />
              </div>

              <div class=" mb-15">
                <input
                  ref={msgRef}
                  type="text"
                  placeholder="CC"
                  onChange={(e) => {
                    const values = { ...addMessage };
                    const val = e.target.value.split(",");
                    values.cc_emails_list = val;
                    setAddMessage(values);
                  }}
                  class="form-control white"
                />
              </div>
              <div>
                <Editor
                  name="task"
                  className="ck-content"
                  onChange={(e) => {
                    const values = { ...addMessage };
                    values.message = e;
                    setAddMessage(values);
                  }}
                  editorLoaded={true}
                  value={addMessage.message}
                />
              </div>
              <div class="form-checkbox-group-row d-flex flex-wrap align-items-center">
                <div class="form-checkbox-item">
                  <div class="form-check dark-tick-checkbox">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={addMessage.send_email}
                      checked={addMessage.send_email}
                      placeholder="Send Email"
                      onChange={(e) => {
                        const values = { ...addMessage };
                        values.send_email = e.target.checked;
                        setAddMessage(values);
                      }}
                      id="sendMail2"
                    />
                    <label class="form-check-label fw-medium text-nowrap" for="sendMail2">
                      Send Email
                    </label>
                  </div>
                </div>
                <div class="form-checkbox-item">
                  <div class="form-check dark-tick-checkbox">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={addMessage.all_seller_listings}
                      checked={addMessage.all_seller_listings}
                      onChange={(e) => {
                        const values = { ...addMessage };
                        values.all_seller_listings = e.target.checked;
                        setAddMessage(values);
                      }}
                      id="sendMail"
                    />
                    <label class="form-check-label fw-medium text-nowrap" for="sendMail">
                      Post to all seller's listings?
                    </label>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div class="btn btn-primary btn-sm send-btn-notes" onClick={() => handleSubmit()}>
                    Send
                  </div>
                </div>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end"></div>
            </>
          )}
        </div>
        <div class="text-card-block-row mt-4">
          {props.loadingMain ? (
            <>
              <div className="text-card-block-column skeleton">
                <div className="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                  <div className="tcbc-header-label d-flex flex-wrap align-items-center">
                    <div className="fw-medium skeleton-line">
                      <Skeleton width={100} />
                    </div>
                    <span className="skeleton-line"></span>
                  </div>
                  <div className="tcbc-right-box">
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="tcbc-text-row">
                  <p className="skeleton-line">
                    <Skeleton count={3} />
                  </p>
                </div>
              </div>
              <div className="text-card-block-column skeleton">
                <div className="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                  <div className="tcbc-header-label d-flex flex-wrap align-items-center">
                    <div className="fw-medium skeleton-line">
                      <Skeleton width={100} />
                    </div>
                    <span className="skeleton-line"></span>
                  </div>
                  <div className="tcbc-right-box">
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="tcbc-text-row">
                  <p className="skeleton-line">
                    <Skeleton count={3} />
                  </p>
                </div>
              </div>
              <div className="text-card-block-column skeleton">
                <div className="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                  <div className="tcbc-header-label d-flex flex-wrap align-items-center">
                    <div className="fw-medium skeleton-line">
                      <Skeleton width={100} />
                    </div>
                    <span className="skeleton-line"></span>
                  </div>
                  <div className="tcbc-right-box">
                    <Skeleton width={100} />
                  </div>
                </div>
                <div className="tcbc-text-row">
                  <p className="skeleton-line">
                    <Skeleton count={3} />
                  </p>
                </div>
              </div>
            </>
          ) : (
            commentsData &&
            commentsData?.length > 0 &&
            commentsData?.map((val) => {
              if (val?.task_id > 0 && (val.task_status_id == 1 || val.task_status_id == 4)) {
                return;
              } else {
                return (
                  <div class="text-card-block-column">
                    <div class="tcbc-header d-flex flex-wrap align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <div
                          class="tcbc-header-label d-flex ps-2 flex-column align-items-start justify-content-center text-white me-1"
                          style={{
                            backgroundColor: val?.task_id > 0 ? "#137c10" : "#104b7c",
                            width: "250px",
                            minHeight: "50px",
                            maxHeight: "auto",
                          }}
                        >
                          <div class="fw-medium">
                            {val?.is_declined ? <span>Declined Bid</span> : val?.name01}
                            {val?.sent_id ? <em> to {val?.sent_name}</em> : ""}
                            <br /> <div>{val?.created && <DateFormat date={val?.created} />}</div>
                          </div>
                        </div>
                        <div className="cursor-pointer" onClick={() => handleStarred(val?.chat_id)}>
                          <img src={val?.isStarred ? selectedIcon : darkStarIcon} alt="" />
                        </div>
                      </div>
                      {val?.member_id == session.member_id || session.member_id == 1 ? (
                        <div class="tcbc-right-box">
                          <button
                            type="button"
                            class="delete-button d-flex align-items-center"
                            onClick={() => toggleDeleteModal(val?.chat_id)}
                          >
                            <img src={Trash} alt="" />
                            Delete
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {val?.is_declined ? (
                      <>
                        <strong>Amount Declined:</strong> &nbsp;
                        {val?.BidAmount ? "$" + new Intl.NumberFormat("en-US").format(val?.BidAmount) : "$0.00"} <br />
                        <strong>Bidder:</strong> &nbsp;{val?.bidderName} <br />
                        <strong>Message Sent: </strong>
                      </>
                    ) : (
                      ""
                    )}
                    {val?.requested ? (
                      <>
                        <span>
                          {`${val?.name01} has requested ${val?.managed_by} to help communicate with ${val?.buyer_name}`}{" "}
                        </span>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    <div
                      style={{ whiteSpace: "pre-wrap" }}
                      class="tcbc-text-row div-comm-notes-sec text-start div-comments-message"
                      id="comments-messages"
                      dangerouslySetInnerHTML={{
                        __html: val.message
                          ?.replaceAll("/s3/viewDocFile.cfm?hash=", process.env.REACT_APP_S3_PRIVATE_DOC_BUCKET + "/")
                          ?.replaceAll(`">View Agreement.`, `.pdf">View Agreement.`),
                      }}
                    />
                    {/* <div className="d-flex flex-column  ">
                      {val?.date_completed && (
                        <>
                          <div style={{ marginTop: "6px", color: "#47AD1D" }}>
                            <span>Completed on </span>
                            <DateFormat date={val?.date_completed} />
                          </div>
                        </>
                      )}
                    </div> */}
                    {val?.task_id > 0 && val?.task_status_id === 1 ? (
                      val?.task_id === 169 ? (
                        <>
                          <a
                            href={
                              process.env.REACT_APP_MODE !== "development"
                                ? "admin.salvex.com/"
                                : "admin-dev.salvex.com" +
                                  `/admin/_sellers/_mrk_doc_auc/_create.cfm?aucID=${props?.auctionId}&s_user_id=${val?.user_id}&request_location=${val?.task_id}`
                            }
                            target="_blank"
                          >
                            &raquo; Respond
                          </a>{" "}
                          <a class="TaskChatMessage" status="4" rel="#ch.chat_id#">
                            &raquo; In Progress
                          </a>
                          <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                            &raquo; Revisit
                          </a>
                          <a class="TaskChatMessage" status="3" rel="#ch.chat_id#">
                            &raquo; Complete
                          </a>
                          <div
                            id="revisit_date"
                            style={{ marginTop: "5px", padding: ".3em .5em", background: "#EFEFEF", display: "none" }}
                          >
                            <input name="revisit_date" placeholder="Revisit Date" />
                            <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                              {" "}
                              Save{" "}
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <a class="TaskChatMessage" status="4" rel="#ch.chat_id#">
                            &raquo; In Progress
                          </a>
                          <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                            &raquo; Revisit
                          </a>
                          <a class="TaskChatMessage" status="3" rel="#ch.chat_id#">
                            &raquo; Complete
                          </a>
                          <div
                            id="revisit_date"
                            style={{ marginTop: "5px", padding: ".3em .5em", background: "#EFEFEF", display: "none" }}
                          >
                            <input name="revisit_date" placeholder="Revisit Date" />
                            <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                              {" "}
                              Save{" "}
                            </a>
                          </div>
                        </>
                      )
                    ) : val?.task_id > 0 && val?.task_status_id == 4 ? (
                      <>
                        <strong style={{ color: "#137c10" }}>In progress</strong>
                        <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                          &raquo; Revisit
                        </a>
                        <a class="TaskChatMessage" status="3" rel="#ch.chat_id#">
                          &raquo; Complete
                        </a>
                        <div
                          id="revisit_date"
                          style={{ marginTop: "5px", padding: ".3em .5em", background: "#EFEFEF", display: "none" }}
                        >
                          <input name="revisit_date" placeholder="Revisit Date" />
                          <a class="TaskChatMessage" status="5" rel="#ch.chat_id#">
                            {" "}
                            Save{" "}
                          </a>
                        </div>
                      </>
                    ) : val?.task_id > 0 && val?.task_status_id == 5 && val?.date_revisit ? (
                      <strong style={{ color: "#137c10" }}>
                        Revisit on{" "}
                        <em>
                          <DateFormat date={val?.date_revisit} />
                        </em>
                      </strong>
                    ) : val?.task_id > 0 && val?.task_status_id == 3 && val?.date_completed ? (
                      <strong style={{ color: "#137c10" }}>
                        Completed on{" "}
                        <em>
                          <DateFormat date={val?.date_completed} />
                        </em>
                      </strong>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
            })
          )}
        </div>
      </div>
      {!props.loadingMain && page < totalPages && totalCount > 10 && (
        <button
          className={loading ? "btn btn-default btn-disabled mt-3" : "btn btn-default mt-3"}
          style={{ width: "100%" }}
          onClick={() => setPage(page + 1)}
        >
          {loading ? "Loading..." : "View More Comments and Notes"}
        </button>
      )}
      <DeleteModal
        show={showDeleteModal}
        onHide={toggleDeleteModal}
        loadingDelete={loadingDelete}
        deleteHandler={deleteHandler}
      />
      <AddAdminList
        addMessage={addMessage}
        setSelectAdmin={setSelectAdmin}
        setAddMessage={setAddMessage}
        adminList={adminList}
        show={showAddAdmin}
        onHide={toggleShowAdmin}
        selectAdmin={selectAdmin}
        setKeyword={setKeyword}
        keyword={keyword}
      />
      <FeaturedNotes
        show={showFeatured}
        onHide={toggleFeatured}
        data={commentsAndNotesData}
        handleStarred={handleStarred}
        toggleDeleteModal={toggleDeleteModal}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  //global states for storing api data
  getCommentsAndNotes: (id, params, callback) => dispatch(getCommentsAndNotes(id, params, callback)),
  deleteChat: (params, callback) => dispatch(deleteChat(params, callback)),
  getAdminList: (params, callback) => dispatch(getAdminList(params, callback)),
  getTaskType: (callback) => dispatch(getTaskType(callback)),
  getPendingTasks: (params, callback) => dispatch(getPendingTasks(params, callback)),
  addCommentsAndNotes: (params, data, callback) => dispatch(addCommentsAndNotes(params, data, callback)),
  getProductCount: (id, callback) => dispatch(getProductCount(id, callback)),

  //global state for manipulating data
  commentsAndNotesFetched: (data) => dispatch(commentsAndNotesFetched(data)),
  setShowFeatured: (data) => dispatch(setFeaturedNotes(data)),
  setShowStrategicModal: (data) => dispatch(setStrategicModal(data)),
});
const mapStateToProps = (state) => ({
  commentsAndNotesDetails: commentsAndNotesDetails(state),
  getStrategicTeam: getStrategicTeam(state),
  showFeatured: getModals(state)?.featuredNotes,
  startegicModal: getModals(state)?.startegicModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CommentsAndNotes));
