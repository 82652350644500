import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "primarySeller",
  initialState: {
    loading: false,
    primarySeller: {},
    agreementDetail: {},
    emailReport: {},
    emailReportLog: {},
    listings: {},
    weeklyReportListings: {},
    sellerListings: {},
    buyerListings: {},
    keyBuyerListings: {},
    donationListings: {},
    userAuctions: {},
    buyerARR: {},
    invoiceAndPending: {},
    otherListings: {},
    otherPreferences: {},
    sellers: {},
    individualPush: {},
    publicSellerListings: {},
  },
  reducers: {
    primarySellerRequested: (state, action) => {
      state.loading = true;
    },
    primarySellerReceived: (state, action) => {
      state.primarySeller = action.payload;
      state.loading = false;
    },
    primarySellerFailed: (state, action) => {
      state.loading = false;
    },
    agreementDetailRequested: (state, action) => {
      state.loading = true;
    },
    agreementDetailReceived: (state, action) => {
      state.agreementDetail = action.payload;
      state.loading = false;
    },
    agreementDetailFailed: (state, action) => {
      state.loading = false;
    },
    emailReportRequested: (state, action) => {
      state.loading = true;
    },
    emailReportReceived: (state, action) => {
      state.emailReport = action.payload;
      state.loading = false;
    },
    emailReportFailed: (state, action) => {
      state.loading = false;
    },
    emailReportLogRequested: (state, action) => {
      state.loading = true;
    },
    emailReportLogReceived: (state, action) => {
      state.emailReportLog = action.payload;
      state.loading = false;
    },
    emailReportLogFailed: (state, action) => {
      state.loading = false;
    },
    listingsRequested: (state, action) => {
      state.loading = true;
    },
    listingsRecieved: (state, action) => {
      state.loading = false;
      state.listings = action.payload;
    },
    listingsFailed: (state, action) => {
      state.loading = false;
    },
    weeklyReportListingsRequested: (misc, action) => {
      misc.loading = true;
    },
    weeklyReportListingsReceived: (misc, action) => {
      misc.weeklyReportListings = action.payload;
      misc.loading = false;
    },
    weeklyReportListingsFailed: (misc, action) => {
      misc.loading = false;
    },
    sellerListingsRequested: (misc, action) => {
      misc.loading = true;
    },
    sellerListingsReceived: (misc, action) => {
      misc.sellerListings = action.payload;
      misc.loading = false;
    },
    sellerListingsFailed: (misc, action) => {
      misc.loading = false;
    },
    buyerListingsRequested: (misc, action) => {
      misc.loading = true;
    },
    buyerListingsReceived: (misc, action) => {
      misc.buyerListings = action.payload;
      misc.loading = false;
    },
    buyerListingsFailed: (misc, action) => {
      misc.loading = false;
    },
    keyBuyerListingsRequested: (misc, action) => {
      misc.loading = true;
    },
    keyBuyerListingsReceived: (misc, action) => {
      misc.keyBuyerListings = action.payload;
      misc.loading = false;
    },
    keyBuyerListingsFailed: (misc, action) => {
      misc.loading = false;
    },
    userAuctionsRequested: (state, action) => {
      state.loading = true;
    },
    userAuctionsReceived: (state, action) => {
      state.userAuctions = action.payload;
      state.loading = false;
    },
    userAuctionsFailed: (state, action) => {
      state.loading = false;
    },
    donationListingsRequested: (state, action) => {
      state.loading = true;
    },
    donationListingsReceived: (state, action) => {
      state.donationListings = action.payload;
      state.loading = false;
    },
    donationListingsFailed: (state, action) => {
      state.loading = false;
    },
    buyerARRRequested: (state, action) => {
      state.loading = true;
    },
    buyerARRReceived: (state, action) => {
      state.buyerARR = action.payload;
      state.loading = false;
    },
    buyerARRFailed: (state, action) => {
      state.loading = false;
    },
    invoiceAndPendingRequested: (state, action) => {
      state.loading = true;
    },
    invoiceAndPendingReceived: (state, action) => {
      state.invoiceAndPending = action.payload;
      state.loading = false;
    },
    invoiceAndPendingFailed: (state, action) => {
      state.loading = false;
    },
    otherListingsRequested: (state, action) => {
      state.loading = true;
    },
    otherListingsReceived: (state, action) => {
      state.otherListings = action.payload;
      state.loading = false;
    },
    otherListingsFailed: (state, action) => {
      state.loading = false;
    },
    otherPreferencesRequested: (state, action) => {
      state.loading = true;
    },
    otherPreferencesReceived: (state, action) => {
      state.otherPreferences = action.payload;
      state.loading = false;
    },
    otherPreferencesFailed: (state, action) => {
      state.loading = false;
    },
    sellersRequested: (state, action) => {
      state.loading = true;
    },
    sellersReceived: (state, action) => {
      state.sellers = action.payload;
      state.loading = false;
    },
    sellersFailed: (state, action) => {
      state.loading = false;
    },
    individualPushRequested: (state, action) => {
      state.loading = true;
    },
    individualPushReceived: (state, action) => {
      state.individualPush = action.payload;
      state.loading = false;
    },
    individualPushFailed: (state, action) => {
      state.loading = false;
    },
    publicSellerListingsRequested: (state, action) => {
      state.loading = true;
    },
    publicSellerListingsReceived: (state, action) => {
      state.publicSellerListings = action.payload;
      state.loading = false;
    },
    publicSellerListingsFailed: (state, action) => {
      state.loading = false;
    },
  },
});
export const {
  primarySellerRequested,
  primarySellerReceived,
  primarySellerFailed,
  agreementDetailRequested,
  agreementDetailReceived,
  agreementDetailFailed,
  emailReportRequested,
  emailReportReceived,
  emailReportFailed,
  emailReportLogRequested,
  emailReportLogReceived,
  emailReportLogFailed,
  listingsRequested,
  listingsRecieved,
  listingsFailed,
  weeklyReportListingsReceived,
  weeklyReportListingsRequested,
  weeklyReportListingsFailed,
  sellerListingsReceived,
  sellerListingsRequested,
  sellerListingsFailed,
  buyerListingsReceived,
  buyerListingsRequested,
  buyerListingsFailed,
  keyBuyerListingsReceived,
  keyBuyerListingsRequested,
  keyBuyerListingsFailed,
  userAuctionsRequested,
  userAuctionsReceived,
  userAuctionsFailed,
  donationListingsRequested,
  donationListingsReceived,
  donationListingsFailed,
  buyerARRRequested,
  buyerARRReceived,
  buyerARRFailed,
  invoiceAndPendingRequested,
  invoiceAndPendingReceived,
  invoiceAndPendingFailed,
  otherListingsRequested,
  otherListingsReceived,
  otherListingsFailed,
  otherPreferencesRequested,
  otherPreferencesReceived,
  otherPreferencesFailed,
  sellersRequested,
  sellersReceived,
  sellersFailed,
  individualPushRequested,
  individualPushReceived,
  individualPushFailed,
  publicSellerListingsReceived,
  publicSellerListingsRequested,
  publicSellerListingsFailed,
} = slice.actions;
export default slice.reducer;

//Action Creator
const baseUrl = "primarySellers";

//Get Primary Seller
export const getPrimarySeller = (params, sellerId, memberId, s_id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/detail/${params}?seller_id=${sellerId}&s_member_id=${memberId}&sid=${s_id}`,
      method: "get",
      callback,
      onStart: primarySellerRequested.type,
      onSuccess: primarySellerReceived.type,
      onError: primarySellerFailed.type,
    }),
  );
};

export const getweeklyEmailReport = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/emailReport/${params}`,
      method: "get",
      callback,
      onStart: emailReportRequested.type,
      onSuccess: emailReportReceived.type,
      onError: emailReportFailed.type,
    }),
  );
};

export const getweeklyReportListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/auctionForSellerWeeklyReport`,
      method: "get",
      params,
      callback,
      onStart: weeklyReportListingsRequested.type,
      onSuccess: weeklyReportListingsReceived.type,
      onError: weeklyReportListingsFailed.type,
    }),
  );
};

export const changeWeeklyReportListingStatus = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/enableDisableForSellerWeeklyReport/${id}`,
      method: "put",
      callback,
    }),
  );
};

export const getAgreementDetail = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listingAgreement/detail/${id}`,
      method: "get",
      callback,
      onStart: agreementDetailRequested.type,
      onSuccess: agreementDetailReceived.type,
      onError: agreementDetailFailed.type,
    }),
  );
};

export const sendAgreement = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listingAgreement/${id}`,
      data,
      method: "post",
      callback,
    }),
  );
};
export const sendAgreements = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listingAgreements/multiple`,
      data,
      method: "post",
      callback,
    }),
  );
};

export const previewAgreement = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listingAgreement/preview/1`,
      data,
      method: "post",
      callback,
    }),
  );
};
export const previewAgreements = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listingAgreements/multiple/preview/1`,
      data,
      method: "post",
      callback,
    }),
  );
};

export const userAuctionListings = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listingAgreement/userAuction/${id}`,
      method: "get",
      callback,
      onStart: userAuctionsRequested.type,
      onSuccess: userAuctionsReceived.type,
      onError: userAuctionsFailed.type,
    }),
  );
};

export const signAgreement = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listingAgreement/sign`,
      data,
      method: "post",
      callback,
    }),
  );
};

export const saveAuctionNote = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/auctionNote/" + id,
      data,
      method: "PUT",
      callback,
    }),
  );
};
export const sendReportemail = (data, id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: baseUrl + "/emailReport/" + id,
      data,
      method: "POST",
      callback,
    }),
  );
};

export const updateSendReportEmail = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `primarySellers/updateSendReportEmail/${id}`,
      method: "put",
      callback,
    }),
  );
};

export const getEmailReportLog = (userId, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `primarySellers/emailReport/log/${userId}`,
      params,
      method: "get",
      callback,
      onStart: emailReportLogRequested.type,
      onSuccess: emailReportLogReceived.type,
      onError: emailReportLogFailed.type,
    }),
  );
};

export const updateSendWeeklyReportEmailFrequency = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `primarySellers/updateSendWeeklyReportEmailFrequency/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getSellerOtherListings = (option, id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/${option}/${id}`,
      method: "get",
      params,
      callback,
      onStart: listingsRequested.type,
      onSuccess: listingsRecieved.type,
      onError: listingsFailed.type,
    }),
  );
};

//Get Seller Listings
export const getSellerListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/primarySellerForWeeklyReport`,
      params,
      method: "get",
      callback,
      onStart: sellerListingsRequested.type,
      onSuccess: sellerListingsReceived.type,
      onError: sellerListingsFailed.type,
    }),
  );
};

//Get Seller Listings

//Get Buyer Listings
export const getBuyerListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `buyerReports/buyerForWeeklyReport`,
      params,
      method: "get",
      callback,
      onStart: buyerListingsRequested.type,
      onSuccess: buyerListingsReceived.type,
      onError: buyerListingsFailed.type,
    }),
  );
};

//Get Key Buyer Listings
export const getKeyBuyerListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `keyBuyerPush/list`,
      params,
      method: "get",
      callback,
      onStart: keyBuyerListingsRequested.type,
      onSuccess: keyBuyerListingsReceived.type,
      onError: keyBuyerListingsFailed.type,
    }),
  );
};

//Get Donation Listings
export const getDonationListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/listing/donation`,
      params,
      method: "get",
      callback,
      onStart: donationListingsRequested.type,
      onSuccess: donationListingsReceived.type,
      onError: donationListingsFailed.type,
    }),
  );
};

export const getBuyerArrListings = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `buyerReports/emailReport/${id}`,
      method: "get",
      callback,
      onStart: buyerARRRequested.type,
      onSuccess: buyerARRReceived.type,
      onError: buyerARRFailed.type,
    }),
  );
};

export const getBuyerInvoicesandPending = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `buyerReports/bidAndInvoice/${id}`,
      method: "get",
      callback,
      onStart: invoiceAndPendingRequested.type,
      onSuccess: invoiceAndPendingReceived.type,
      onError: invoiceAndPendingFailed.type,
    }),
  );
};

export const getBuyerOtherListings = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `buyerReports/quesWatchListRef/${id}`,
      method: "get",
      callback,
      onStart: otherListingsRequested.type,
      onSuccess: otherListingsReceived.type,
      onError: otherListingsFailed.type,
    }),
  );
};

export const getBuyerOtherPreferences = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `buyerReports/preference/${id}`,
      method: "get",
      callback,
      onStart: otherPreferencesRequested.type,
      onSuccess: otherPreferencesReceived.type,
      onError: otherPreferencesFailed.type,
    }),
  );
};

//Update Donation Status
export const updateDonationStatus = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/emailReport/updateDonationStatus/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

//Update Buyer Report Frequency
export const updateBuyerReportEmailFrequency = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `buyerReports/updateBuyerReportEmailFrequency/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const sendBuyerEmailReport = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `buyerReports/emailReport/${id}`,
      method: "post",
      data,
      callback,
    }),
  );
};

//Get Sellers
export const getSellers = (id, params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/changeSeller/${id}`,
      params,
      method: "get",
      callback,
      onStart: sellersRequested.type,
      onSuccess: sellersReceived.type,
      onError: sellersFailed.type,
    }),
  );
};

//Change primary seller
export const changePrimarySeller = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/changeSeller/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

//Individual Push
export const getIndividualPushData = (id, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionKeyBuyer/individualPush/${id}`,
      method: "get",
      callback,
      onStart: individualPushRequested.type,
      onSuccess: individualPushReceived.type,
      onError: individualPushFailed.type,
    }),
  );
};

//Send Individual Push
//Change primary seller
export const sendIndividualPush = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `auctionKeyBuyer/individualPush/${id}`,
      method: "post",
      data,
      callback,
    }),
  );
};

//Release Order
export const releaseOrder = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/informationRelease/${id}`,
      method: "post",
      data,
      callback,
    }),
  );
};

//Choose Seller Deatils Type

export const saveSellerDisplayRequest = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/displaySeller/sendRequest`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const updateSellerDisplayRequest = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/displaySeller/updateRequest`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const getPublicSellerListings = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${baseUrl}/displaySeller/getRequest`,
      method: "get",
      params,
      callback,
      onStart: publicSellerListingsRequested.type,
      onSuccess: publicSellerListingsReceived.type,
      onError: publicSellerListingsFailed.type,
    }),
  );
};

export const saveCustomizeDashboard = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `user/customizeProfile/${id}`,
      method: "put",
      data,
      callback,
    }),
  );
};

export const primarySellerData = createSelector(
  (state) => state.entities.primarySeller,
  (primarySeller) => primarySeller,
);
