import React, { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import close from "../../include/images/close.svg";
import Editor from "../../common/editor/editor";
import trash from "../../include/images/trash-24x24.svg";
import NextButton from "../../common/form/nextButton";
import Select from "react-select";
import { customStyles } from "../../common/customStyles/reactSelectStyle";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";

const ProductConditionModal = ({ primaryCategory, setPrimaryCategory, ...props }) => {
  const [aviationIds, setAviationIds] = useState([]);
  const getCategoryIds = (data) => {
    return _.flatMapDeep(data, (item) => {
      return [item.category_id, ...getCategoryIds(item.subCategories || [])];
    });
  };
  const categoryId =
    props.categoryDropdown && props.categoryDropdown.length > 0 && props.categoryDropdown.map((val) => val);
  const getFlatCategoryIds = (data) => {
    return _.flatMapDeep(data, (item) => {
      return [item?.category_id, ...getFlatCategoryIds(item.subCategories || [])];
    });
  };
  const flatCategoryIds = getFlatCategoryIds(props?.categoryAccordionData);
  const checkList = props?.checkList?.filter((val) => flatCategoryIds?.includes(val));

  const [value, setValues] = useState();
  const [loadingCategories, setLoadingCategories] = useState([]);

  useEffect(() => {
    if (props.select && props.select.value) {
      props.subCat &&
        props.subCat.length > 0 &&
        props.subCat.map((val) => {
          if (val.value === props.select.value) {
            setValues(val.subCategories);
          }
        }, []);
    }
  });
  useEffect(() => {
    props.setEditorLoaded(true);
  }, []);

  const handleLoadCategoryDetails = (categoryId) => {
    if (loadingCategories.includes(categoryId)) return;

    // Start loading
    setLoadingCategories((prev) => [...prev, categoryId]);

    // Call API
    props?.loadCategoryDetails(categoryId);
    // Stop loading after data is fetched
    setLoadingCategories((prev) => prev.filter((id) => id !== categoryId));
  };

  const renderCategories = (categories, owner = "All Listings") => {
    return categories?.map((category, index) => {
      if (category?.ct > 1) {
        return (
          <Accordion
            className="accordion left-arrow-accordion small-checkbox"
            key={`${category.description}${category.category_id}`}
          >
            <Accordion.Item
              className="accordion-item"
              eventKey={`${category.description}${index}`}
              onClick={() => {
                if (category?.subCategories?.length > 0) return;
                handleLoadCategoryDetails(category?.category_id);
              }}
            >
              <Accordion.Header className="accordion-header">
                <span className="ps-4">
                  {category.description} {category.auctions ? `(${category?.auctions})` : ""}
                </span>
              </Accordion.Header>
              <Accordion.Body className="accordion-body">
                {" "}
                {loadingCategories.includes(category.category_id) ? (
                  <Skeleton count={3} /> // Display skeleton loader for loading categories
                ) : (
                  renderCategories(category.subCategories, category.description)
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      } else {
        return (
          <div className="form-check d-flex" key={category.category_id}>
            <input
              id={`sub${category.category_id}`}
              name={category.category_id}
              className="form-check-input"
              type="checkbox"
              style={{
                height: "1.5rem",
                width: "1.5rem",
                cursor: "pointer",
              }}
              value={category.category_id}
              checked={props.checkList && props.checkList.includes(category.category_id)}
              onChange={() => {
                const checkedData = [...props.checkList];
                const selectedData = [...(props?.selectedCategory || [])];
                if (checkedData.includes(category.category_id)) {
                  const updatedList = checkedData.filter((item) => item !== category.category_id);
                  const updatedData = selectedData?.filter((item) => item?.category_id !== category?.category_id);
                  props.setCheckList(updatedList);
                  props?.setSelectedCategory(updatedData);
                } else {
                  checkedData.push(category.category_id);
                  const newCategory = { ...category };
                  newCategory.subCategories = [owner];
                  selectedData.push(newCategory);
                  props?.setSelectedCategory(selectedData);
                  props.setCheckList(checkedData);
                }
              }}
            />
            <label
              className="form-check-label fw-medium"
              htmlFor={`sub${category.category_id}`}
              style={{ paddingLeft: "15px" }}
            >
              {category.description} {category.auctions ? `(${category.auctions})` : ""}
            </label>
          </div>
        );
      }
    });
  };

  return (
    <Modal
      className="modal fade custom-modal"
      id="editCondition"
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
      show={props.show}
      onHide={() => props.onHide()}
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Edit Category, Condition & Manufacturer </h5>
          <button type="button" class="close-btn d-flex align-items-center justify-content-center">
            <img src={close} onClick={() => props.onHide()} />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden" style={{ minHeight: "425px" }}>
          <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "10px" }}>
            <div class="rdr-label-box align-self-start">Select a Category</div>
            <div class="rdr-form-box w-350">
              <Select
                menuPosition="fixed"
                menuShouldScrollIntoView
                value={
                  primaryCategory?.firstLevel?.active
                    ? props.select
                    : primaryCategory?.firstLevel?.name
                    ? { name: primaryCategory?.firstLevel?.name, value: primaryCategory?.firstLevel?.value }
                    : ""
                }
                styles={customStyles}
                className="basic-single "
                classNamePrefix="select-search  "
                placeholder={`Please Select`}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                options={props.categoryDropdown}
                name={"PrimayCategoryFirstLevel"}
                onChange={async (event) => {
                  const data = { ...categoryId };
                  const filterData = props?.categoryDropDownData?.filter((ui) => ui?.category_id == event?.value);
                  const subCat = await props?.getCategoryDetails(event?.value);

                  if (subCat?.length > 0 && filterData[0]?.ct) {
                    setPrimaryCategory({
                      firstLevel: {
                        // Updated firstLevel
                        name: filterData[0]?.description,
                        value: filterData[0]?.category_id,
                        subCategories: filterData[0]?.ct,
                        subCat: subCat, // Add subCat here
                      },
                    });
                    if (props?.select) {
                      props?.setSelect("");
                    }
                  } else {
                    data.value = event;
                    props.setSelect(data.value);
                    setPrimaryCategory({ firstLevel: { active: true } });
                  }
                }}
              />
              {props?.errors?.firstLevel ? <p style={{ color: "red" }}>{props?.errors?.firstLevel} </p> : ""}
            </div>
          </div>

          {primaryCategory?.firstLevel?.subCategories > 0 ? (
            <div class="rdr-item d-flex flex-nowrap mb-4" style={{ marginTop: "30px" }}>
              <div class="rdr-label-box align-self-start">Category Level 2</div>
              <div class="rdr-form-box w-350">
                <Select
                  menuPosition="fixed"
                  menuShouldScrollIntoView
                  styles={customStyles}
                  value={
                    primaryCategory?.secondLevel?.active
                      ? { description: props?.select?.name, category_id: props?.select?.value }
                      : primaryCategory?.secondLevel?.name
                      ? {
                          description: primaryCategory?.secondLevel?.name,
                          category_id: primaryCategory?.secondLevel?.value,
                        }
                      : ""
                  }
                  className="basic-single"
                  classNamePrefix="select-search"
                  placeholder={`Please Select`}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.category_id}
                  options={primaryCategory?.firstLevel?.subCat}
                  name={"PrimayCategorySecondLevel"}
                  onChange={async (event) => {
                    const data = { ...categoryId };
                    const filterData = primaryCategory?.firstLevel?.subCat?.filter(
                      (ui) => ui?.category_id == event?.category_id,
                    );
                    const subCat = await props?.getCategoryDetails(event?.category_id);
                    if (subCat?.length > 0 && filterData[0]?.ct) {
                      setPrimaryCategory({
                        ...primaryCategory,
                        secondLevel: {
                          name: filterData[0]?.description,
                          value: filterData[0]?.category_id,
                          subCategories: filterData[0]?.ct,
                          subCat,
                        },
                      });
                      if (props?.select) {
                        props?.setSelect("");
                      }
                    } else {
                      data.value = event;
                      props.setSelect({ name: event.description, value: event.category_id });
                      setPrimaryCategory({
                        firstLevel: { ...primaryCategory?.firstLevel },
                        secondLevel: { active: true },
                      });
                    }
                  }}
                />
                {props?.errors?.secondLevel ? <p style={{ color: "red" }}>{props?.errors?.secondLevel} </p> : ""}
              </div>
            </div>
          ) : (
            ""
          )}

          {primaryCategory?.secondLevel?.subCategories > 0 ? (
            <div class="rdr-item d-flex flex-nowrap mb-4" style={{ marginTop: "30px" }}>
              <div class="rdr-label-box align-self-start">Category Level 3</div>
              <div class="rdr-form-box w-350">
                <Select
                  menuPosition="fixed"
                  menuShouldScrollIntoView
                  value={
                    primaryCategory?.thirdLevel?.active
                      ? { description: props?.select?.name, category_id: props?.select?.value }
                      : primaryCategory?.thirdLevel?.name
                      ? {
                          description: primaryCategory?.thirdLevel?.name,
                          category_id: primaryCategory?.thirdLevel?.value,
                        }
                      : ""
                  }
                  className="basic-single"
                  classNamePrefix="select-search"
                  placeholder={`Please Select`}
                  styles={customStyles}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.category_id}
                  options={primaryCategory?.secondLevel?.subCat}
                  name={"PrimayCategoryThirdLevel"}
                  onChange={async (event) => {
                    const data = { ...categoryId };
                    const filterData = primaryCategory?.secondLevel?.subCat?.filter(
                      (ui) => ui?.category_id == event?.category_id,
                    );
                    const subCat = await props?.getCategoryDetails(event?.category_id);
                    if (subCat?.length > 0 && filterData[0]?.ct) {
                      setPrimaryCategory({
                        ...primaryCategory,
                        thirdLevel: {
                          name: filterData[0]?.description,
                          value: filterData[0]?.category_id,
                          subCategories: filterData[0]?.ct,
                          subCat,
                        },
                      });
                      if (props?.select) {
                        props?.setSelect("");
                      }
                    } else {
                      data.value = event;
                      props.setSelect({ name: event.description, value: event.category_id });
                      setPrimaryCategory({
                        firstLevel: { ...primaryCategory?.firstLevel },
                        secondLevel: { ...primaryCategory?.secondLevel },
                        thirdLevel: { active: true },
                      });
                    }
                  }}
                />
                {props?.errors?.thirdLevel ? <p style={{ color: "red" }}>{props?.errors?.thirdLevel} </p> : ""}
              </div>
            </div>
          ) : (
            ""
          )}

          {primaryCategory?.thirdLevel?.subCategories > 0 ? (
            <div class="rdr-item d-flex flex-nowrap mb-4" style={{ marginTop: "30px" }}>
              <div class="rdr-label-box align-self-start">Category Level 4</div>
              <div class="rdr-form-box w-350">
                <Select
                  menuPosition="fixed"
                  menuShouldScrollIntoView
                  value={
                    primaryCategory?.fourthLevel?.active
                      ? { description: props?.select?.name, category_id: props?.select?.value }
                      : primaryCategory?.fourthLevel?.name
                      ? {
                          description: primaryCategory?.fourthLevel?.name,
                          category_id: primaryCategory?.fourthLevel?.value,
                        }
                      : ""
                  }
                  className="basic-single"
                  classNamePrefix="select-search"
                  placeholder={`Please Select`}
                  styles={customStyles}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.category_id}
                  options={primaryCategory?.thirdLevel?.subCat}
                  name={"PrimayCategoryFourthLevel"}
                  onChange={async (event) => {
                    const data = { ...categoryId };
                    const filterData = primaryCategory?.thirdLevel?.subCat?.filter(
                      (ui) => ui?.category_id == event?.category_id,
                    );
                    const subCat = await props?.getCategoryDetails(event?.category_id);
                    if (subCat?.length > 0 && filterData[0]?.ct) {
                      setPrimaryCategory({
                        ...primaryCategory,
                        fourthLevel: {
                          name: filterData[0]?.description,
                          value: filterData[0]?.category_id,
                          subCategories: filterData[0]?.ct,
                          subCat,
                        },
                      });
                      if (props?.select) {
                        props?.setSelect("");
                      }
                    } else {
                      data.value = event;
                      props.setSelect({ name: event.description, value: event.category_id });
                      setPrimaryCategory({
                        firstLevel: { ...primaryCategory?.firstLevel },
                        secondLevel: { ...primaryCategory?.secondLevel },
                        thirdLevel: { ...primaryCategory?.thirdLevel },
                        fourthLevel: { active: true },
                      });
                    }
                  }}
                />
                {props?.errors?.fourthLevel ? <p style={{ color: "red" }}>{props?.errors?.fourthLevel} </p> : ""}
              </div>
            </div>
          ) : (
            ""
          )}

          {primaryCategory?.fourthLevel?.subCategories > 0 ? (
            <div class="rdr-item d-flex flex-nowrap mb-4" style={{ marginTop: "30px" }}>
              <div class="rdr-label-box align-self-start">Category Level 5</div>
              <div class="rdr-form-box w-350">
                <Select
                  menuPosition="fixed"
                  menuShouldScrollIntoView
                  value={
                    primaryCategory?.fifthLevel?.active
                      ? { description: props?.select?.name, category_id: props?.select?.value }
                      : primaryCategory?.fifthLevel?.name
                      ? {
                          description: primaryCategory?.fifthLevel?.name,
                          category_id: primaryCategory?.fifthLevel?.value,
                        }
                      : ""
                  }
                  className="basic-single"
                  classNamePrefix="select-search"
                  placeholder={`Please Select`}
                  styles={customStyles}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.category_id}
                  options={primaryCategory?.fourthLevel?.subCat}
                  name={"PrimayCategoryFifthLevel"}
                  onChange={async (event) => {
                    const data = { ...categoryId };
                    const filterData = primaryCategory?.fourthLevel?.subCat?.filter(
                      (ui) => ui?.category_id == event?.category_id,
                    );
                    const subCat = await props?.getCategoryDetails(event?.category_id);

                    if (subCat?.length > 0 && filterData[0]?.ct) {
                      setPrimaryCategory({
                        ...primaryCategory,
                        fifthLevel: {
                          name: filterData[0]?.description,
                          value: filterData[0]?.category_id,
                          subCategories: filterData[0]?.ct,
                          subCat,
                        },
                      });
                      if (props?.select) {
                        props?.setSelect("");
                      }
                    } else {
                      data.value = event;
                      props.setSelect({ name: event.description, value: event.category_id });
                      setPrimaryCategory({
                        firstLevel: { ...primaryCategory?.firstLevel },
                        secondLevel: { ...primaryCategory?.secondLevel },
                        thirdLevel: { ...primaryCategory?.thirdLevel },
                        fourthLevel: { ...primaryCategory?.fourthLevel },
                        fifthLevel: { active: true },
                      });
                    }
                  }}
                />
                {props?.errors?.fifthLevel ? <p style={{ color: "red" }}>{props?.errors?.fifthLevel} </p> : ""}
              </div>
            </div>
          ) : (
            ""
          )}

          {primaryCategory?.fifthLevel?.subCategories > 0 ? (
            <div class="rdr-item d-flex flex-nowrap mb-4" style={{ marginTop: "30px" }}>
              <div class="rdr-label-box align-self-start">Category Level 6</div>
              <div class="rdr-form-box w-350">
                <Select
                  menuPosition="fixed"
                  menuShouldScrollIntoView
                  value={
                    primaryCategory?.sixthLevel?.active
                      ? { description: props?.select?.name, category_id: props?.select?.value }
                      : ""
                  }
                  className="basic-single"
                  classNamePrefix="select-search"
                  placeholder={`Please Select`}
                  styles={customStyles}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.category_id}
                  options={primaryCategory?.fifthLevel?.subCat}
                  name={"PrimaryCategorySixthLevel"}
                  onChange={(event) => {
                    const data = { ...categoryId };
                    data.value = event;
                    props.setSelect({ name: event.description, value: event.category_id });
                    setPrimaryCategory({ ...primaryCategory, sixthLevel: { active: true } });
                  }}
                />
                {props?.errors?.sixthLevel ? <p style={{ color: "red" }}>{props?.errors?.sixthLevel}</p> : ""}
              </div>
            </div>
          ) : (
            ""
          )}

          {primaryCategory?.secondLevel?.active ? (
            props?.select?.name === "Aircraft Parts" || props?.select?.name === "Complete Aircrafts" ? (
              <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "30px" }}>
                <div class="rdr-label-box align-self-start">Aviation Model</div>
                <div class="rdr-form-box  w-350">
                  <Select
                    menuPosition="fixed"
                    menuShouldScrollIntoView
                    value={props?.aviationType}
                    className="basic-single "
                    classNamePrefix="select-search"
                    placeholder={`Please Select`}
                    styles={customStyles}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    options={[
                      { name: "Please Select", value: "0" },
                      ...props?.aviationModel?.data?.map((ds) => ({
                        name: ds?.description,
                        value: ds?.aviationModelID,
                      })),
                    ]}
                    name={"PrimayCategorySecondLeve"}
                    onChange={(e) => props?.setAviationType(e)}
                  />
                </div>
              </div>
            ) : (
              ""
            )
          ) : primaryCategory?.secondLevel?.name ? (
            primaryCategory?.secondLevel?.name === "Aircraft Parts" ||
            primaryCategory?.secondLevel?.name === "Complete Aircrafts" ? (
              <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "30px" }}>
                <div class="rdr-label-box align-self-start">Aviation Model</div>
                <div class="rdr-form-box  w-350">
                  <Select
                    menuPosition="fixed"
                    menuShouldScrollIntoView
                    value={props?.aviationType}
                    className="basic-single "
                    classNamePrefix="select-search"
                    placeholder={`Please Select`}
                    styles={customStyles}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    options={[
                      { name: "Please Select", value: "0" },
                      ...props?.aviationModel?.data?.map((ds) => ({
                        name: ds?.description,
                        value: ds?.aviationModelID,
                      })),
                    ]}
                    name={"PrimayCategorySecondLeve"}
                    onChange={(e) => props?.setAviationType(e)}
                  />
                </div>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "10px" }}>
            <div class="rdr-label-box align-self-start">Manufacturer</div>
            <div class="rdr-form-box w-350">
              <Select
                menuPosition="fixed"
                menuShouldScrollIntoView
                value={props?.mfrValue?.manufacturer}
                className="basic-single "
                classNamePrefix="select-search  "
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                isClearable={true}
                isSearchable={true}
                name="manufacturer"
                isLoading={props?.loadingManufacturer}
                loadingMessage={() => "Loading..."}
                options={props?.options || []}
                placeholder={"Select Manufacturer"}
                styles={customStyles}
                onChange={(a) => {
                  const data = { ...value };
                  data.manufacturer = a;
                  props?.setMfrValue(data);
                }}
              />
            </div>
          </div>
          <div class="rdr-item d-flex fle x-nowrap mb-4" style={{ marginTop: "10px" }}>
            <div class="rdr-label-box align-self-start">Condition</div>
            <div class="rdr-form-box w-350">
              <Select
                // menuPortalTarget={document.body}
                menuPosition="fixed"
                menuShouldScrollIntoView
                value={props?.condition}
                className="basic-single  "
                classNamePrefix="select-search "
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                name={"conditionModal"}
                options={props.conditionOptions}
                placeholder="Select Condition"
                styles={customStyles}
                onChange={(a) => {
                  let data = { ...props.condition };
                  data = a;
                  props.setCondition(data);
                }}
              />
            </div>
          </div>
          {props?.condition?.value == "Others" && (
            <div class="d-flex flex-wrap mb-4 ">
              <div class="fwtr-label-box pt-2"></div>
              <div
                class="fwtr-input-box flex-grow-1 w-350 
           "
              >
                <input
                  name="otherTextArea"
                  className="form-control"
                  placeholder="Enter Condition Here"
                  value={props.otherData}
                  onChange={(e) => props.setOtherData(e.target.value)}
                />
              </div>
            </div>
          )}

          <div class="full-width-textarea-row d-flex flex-wrap mb-4">
            <div class="fwtr-label-box pt-2">Description</div>
            <div class="fwtr-input-box flex-grow-1">
              <Editor
                id="textarea"
                name="description"
                className="ck-content"
                onChange={(data) => {
                  props.setEditorData(data);
                }}
                editorLoaded={props.editorLoaded}
                value={props.savedData}
              />
            </div>
          </div>
          <table class="table table-borderless align-middle table-left-align mb-4">
            <colgroup>
              <col style={{ width: "15%" }} />
              <col style={{ width: "8%" }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td>Scrap</td>
                <td>
                  <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="scrap"
                      id="scrap1"
                      checked={props.scrapValue === 0 ? true : false}
                      onClick={() => props.setScrapValue(0)}
                    />
                    <label class="form-check-label" for="scrap1">
                      No
                    </label>
                  </div>
                </td>
                <td colspan="2">
                  <div class="form-check custom-radio d-flex align-items-center justify-content-start position-relative">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="scrap"
                      id="scrap2"
                      checked={props.scrapValue === 1 ? true : false}
                      onClick={() => props.setScrapValue(1)}
                    />
                    <label class="form-check-label" for="scrap2">
                      Yes
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-between mb-4">
            <div className="ysp w-50 d-flex justify-content-center align-items-center" style={{ height: "30px" }}>
              <span>You can select upto 5 push categories</span>
            </div>
            <a href="javascript:void(0)" class="link-btn add-more me-0" onClick={() => props?.toggleAssignAviationAI()}>
              Assign Aviation AI Categories
            </a>
          </div>
          <div className="d-flex justify-content-between">
            <div class="row-details-row hide-scrollbar" style={{ height: "500px", overflowY: "scroll" }}>
              <div class="rdr-item d-flex flex-nowrap">
                <Accordion className="accordion left-arrow-accordion small-checkbox">
                  {Array.isArray(props.categoryAccordionData) && renderCategories(props.categoryAccordionData)}
                </Accordion>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-border-radious table-bordered table-fit-content table-wrap align-middle table-large">
                <colgroup>
                  <col style={{ width: "36%" }} />
                  <col />
                </colgroup>
                <tbody>
                  <>
                    {props?.selectedCategory?.length > 0
                      ? props?.selectedCategory.map(
                          (val, i) =>
                            flatCategoryIds?.includes(val?.category_id) && (
                              <tr key={i}>
                                <td>
                                  <ul>
                                    {val.subCategories?.length ? (
                                      val.subCategories
                                        .slice()
                                        .reverse()
                                        .map((subCat, j) => <li key={j}>{`${subCat}`}</li>)
                                    ) : (
                                      <li>--</li>
                                    )}
                                  </ul>
                                </td>
                                <td>{val.description}</td>
                                <td>
                                  <div
                                    className="td-delete-icon"
                                    onClick={() => {
                                      const checkedData = [...props?.checkList];
                                      const data = [...props?.selectedCategory];
                                      const filteredData = data.filter((obj) => obj.category_id !== val.category_id);
                                      const updatedList = checkedData.filter((item) => item !== val.category_id);
                                      // Assuming you have a state setter for categoryData, e.g., setCategoryData
                                      props?.setSelectedCategory(filteredData);
                                      props.setCheckList(updatedList);
                                    }}
                                  >
                                    <img src={trash} alt="Delete" />
                                  </div>
                                </td>
                              </tr>
                            ),
                        )
                      : ""}
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          {checkList?.length > 5 || props?.loading || props?.loadingDropdown ? (
            <button className="btn btn-default btn-disabled">Save</button>
          ) : (
            <NextButton
              label="Save"
              classData="btn btn-default"
              handleSubmit={props.handleSubmit}
              loading={props.loadingCategory}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ProductConditionModal);
