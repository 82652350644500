import React, { useEffect, useRef, useState } from "react";
import { Accordion, Offcanvas } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import trash from "../../../include/images/trash-24x24.svg";
import emptyChat from "../../../include/images/chat-empty.svg";
import LoadingBar from "react-top-loading-bar";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import AlertError from "../../../common/alerts/alertError";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import NextButton from "../../../common/form/nextButton";

const CategoryPreferenceModal = (props) => {
  const ref = useRef();
  const [selectAll, setSelectAll] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState([]);

  const userData = props?.userData;
  useEffect(() => {
    props.setSelectedCategory([]);
    setSelectAll({});
  }, [props.show]);

  const handleAddCategoryPreference = () => {
    setLoading(true);
    ref?.current?.continuousStart();
    const categoryList = [];
    props?.selectedCategory?.map((val) => categoryList.push(val.category_id));
    const data = { category_preferences: categoryList };
    props.addCategoryPreference(props.userId, data, (res) => {
      if (res.status === 200) {
        setLoading(false);
        ref?.current?.complete();
        props.onHide();
        toast(<AlertSuccess message={"Information Saved"} />);
        if (props?.buyer) {
          const item = userData?.length > 0 && userData[0];
          props?.setSelectedId(item);
          props?.toggleShowRegion();
        } else {
          props?.setBuyer(false);
        }
      } else {
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        setLoading(false);
        ref?.current?.complete();
      }
    });
  };

  const handleLoadCategoryDetails = (categoryId) => {
    if (loadingCategories.includes(categoryId)) return;

    // Start loading
    setLoadingCategories((prev) => [...prev, categoryId]);

    // Call API
    props?.loadCategoryDetails(categoryId);
    // Stop loading after data is fetched
    setLoadingCategories((prev) => prev.filter((id) => id !== categoryId));
  };
  const renderCategories = (categories, owner = "All Listings") => {
    return categories?.map((category, index) => {
      if (category?.ct > 0) {
        return (
          <Accordion
            className="accordion left-arrow-accordion small-checkbox"
            key={`${category.description}${category.category_id}`}
          >
            <Accordion.Item
              className="accordion-item"
              eventKey={`${category.description}${index}`}
              onClick={() => {
                if (category?.subCategories?.length > 0) return;
                handleLoadCategoryDetails(category?.category_id);
              }}
            >
              <Accordion.Header className="accordion-header">
                <span className="ps-4">
                  {category.description} {category.auctions ? `(${category?.auctions})` : ""}
                </span>
              </Accordion.Header>
              <Accordion.Body className="accordion-body">
                {loadingCategories.includes(category.category_id) ? (
                  <Skeleton count={3} /> // Display skeleton loader for loading categories
                ) : (
                  renderCategories(category.subCategories, category.description)
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      } else {
        return (
          <div className="form-check d-flex" key={category.category_id}>
            <input
              id={`sub${category.category_id}`}
              name={category.category_id}
              className="form-check-input"
              type="checkbox"
              style={{
                height: "1.5rem",
                width: "1.5rem",
                cursor: "pointer",
              }}
              value={category.category_id}
              checked={props?.selectedCategory?.some((item) => item.category_id === category.category_id)}
              onChange={() => {
                const data = [...props.selectedCategory];
                const data1 = { ...selectAll };

                if (data.some((item) => item.category_id === category.category_id)) {
                  // Remove category from selected
                  const updatedData = data.filter((item) => item.category_id !== category.category_id);
                  data1[owner] = false; // Uncheck the "Select All" for the parent category
                  setSelectAll(data1);
                  props.setSelectedCategory(updatedData);
                } else {
                  // Add category to selected
                  const newCategory = { ...category };
                  newCategory.owner = owner;
                  data.push(newCategory);
                  props.setSelectedCategory(data);
                }
              }}
            />
            <label
              className="form-check-label fw-medium"
              htmlFor={`sub${category.category_id}`}
              style={{ paddingLeft: "15px" }}
            >
              {category.description} {category.auctions ? `(${category.auctions})` : ""}
            </label>
          </div>
        );
      }
    });
  };

  return (
    <>
      <LoadingBar height={5} color="#47AD1D" ref={ref} />
      <Offcanvas
        className="offcanvas offcanvas-end xl-offcanvas border-0"
        id="buyerPreferences"
        placement="end"
        backdropClassName="upload-documnets-backdrop"
        show={props.show}
        onHide={() => {
          props?.setBuyer(false);
          props.onHide();
        }}
        enforceFocus={false}
      >
        <Offcanvas.Header className="header-buyer-pref">
          <h5 class="offcanvas-title">Category Preferences</h5>
          {!props?.categoryLoading && (
            <a
              href="javascript:void(0)"
              class="btn btn-link me-md-2 global-cancel-button cancel-user-pref"
              onClick={() => {
                props?.setBuyer(false);
                props.onHide();
              }}
            >
              {" "}
              Cancel{" "}
            </a>
          )}
          {!props?.categoryLoading && (
            <a
              className={loading ? "btn btn-border-green disabled" : "btn btn-border-green"}
              onClick={handleAddCategoryPreference}
            >
              {loading ? "Loading..." : "Save"}
            </a>
          )}
        </Offcanvas.Header>

        <Offcanvas.Body className="offcanvas-body position-relative p-4">
          <div class="dashboard-widget-row-container w-100 d-lg-flex flex-lg-wrap">
            <div class="dwrc-left">
              <h3 class="widget-title text-black">Add/Edit Category</h3>
              <Accordion className="accordion left-arrow-accordion small-checkbox">
                {Array.isArray(props.categoryDropDownData) && renderCategories(props.categoryDropDownData)}
              </Accordion>
            </div>
            <div class="dwrc-right d-flex flex-column">
              <h3 class="widget-title text-black">Current Selection</h3>
              <div class="table-responsive mb-30">
                <table class="table table-border-radious table-bordered table-fit-content table-wrap align-middle table-large">
                  <tbody>
                    {props?.categoryLoading ? (
                      [1, 2, 3, 4, 5].map((item) => (
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <div class="td-delete-icon">
                              <Skeleton height="30px" />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : props?.selectedCategory?.length > 0 ? (
                      props?.selectedCategory?.map((val, i) => (
                        <tr>
                          <td>{val?.owner}</td>
                          <td>{val?.description}</td>
                          <td>
                            <div
                              class="td-delete-icon"
                              onClick={() => {
                                const data = [...props.selectedCategory];
                                const filteredData = data.filter((obj) => obj.category_id !== val.category_id);
                                props.setSelectedCategory(filteredData);
                              }}
                            >
                              <img src={trash} alt="" />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <div class="pannel-body d-flex align-items-center justify-content-center mt-5 mb-5">
                            <div class="empty-pannel-box">
                              <img src={emptyChat} alt="" />
                              <h4>No Categories Selected!</h4>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div class="widgets-btn text-end mt-auto">
                {!props.categoryLoading && (
                  <NextButton
                    label="Save"
                    classData="btn btn-default btn-lg"
                    handleSubmit={handleAddCategoryPreference}
                    loading={loading}
                  />
                )}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CategoryPreferenceModal;
