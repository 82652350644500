import React, { memo } from "react";
import { Modal } from "react-bootstrap";
import NextButton from "../../../common/form/nextButton";
import close from "../../../include/images/close.svg";

const ListingTitle = memo((props) => {
  return (
    <Modal
      className="modal fade custom-modal"
      id="editProductDescription"
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
      show={props.show}
      onHide={() => props.onHide()}
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">
            {props?.titleType == "notes_to_seller" ? "Notes To Seller" : "Change Listing Title"}
          </h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden">
          <div class="full-width-textarea-row d-flex flex-wrap">
            <div class="fwtr-label-box pt-2">{props?.titleType == "notes_to_seller" ? "Notes" : "Title"}</div>
            <div class="fwtr-input-box flex-grow-1">
              <textarea
                style={{ height: "160px" }}
                class="form-control w-50"
                placeholder="Enter Your Title Here"
                value={props.title}
                onChange={(e) => props.setTitle(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button class="btn btn-link me-md-2 global-cancel-button" type="button" onClick={() => props.onHide()}>
            Cancel
          </button>
          <NextButton
            classData="btn btn-default"
            handleSubmit={() => props.handleChangeTitle()}
            label={"Save"}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
});

export default ListingTitle;
