import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import EditProductDetails from "../../../layouts/modals/editProductDetails";
import {
  getListingProductDetail,
  productDetails,
  listingProductDetailReceived,
  updateProductDetails,
  updateComparablePrice,
} from "../../../store/listingProduct";
import _ from "lodash";
import ComparablePrice from "../../../layouts/modals/pricingDetail/pricingDetailModal";
import { toast } from "react-toastify";
import AlertError from "../../../common/alerts/alertError";
import AlertSuccess from "../../../common/alerts/alertSuccess";
import moment from "moment";
import icon2 from "../../../include/images/icon__2.svg";
import {
  getBidLog,
  getLatestActivity,
  getLatestBids,
  getPriceInfo,
  getRevisitSellerBid,
  quantityConfirmation,
  saveBidLog,
  updatePriceFeature,
} from "../../../store/latestActivity";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { getModals, setQtyPricingModal } from "../../../store/modal";
import { detailListing, getListings } from "../../../store/listings";
import { getDealProgress } from "../../../store/misc";
import { getCommentsAndNotes } from "../../../store/commentsAndNotes";
const Pricing = ({ refStart, refStop, loadingMain, auctionId, ...props }) => {
  const priceInfo = props.getLatestActivity?.priceInfo || {};
  const latestBids = props.getLatestActivity?.latestBids || {};
  const bidLog = props?.getLatestActivity?.bidLog?.data;
  const productDetails = props?.getListingProductDetail?.detail?.detail || {};
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [comparablePriceModal, setComparablePriceModal] = useState(false);
  const [comparablePriceData, setComparablePriceData] = useState([]);
  const [comparablePriceData1, setComparablePriceData1] = useState([]);
  const [revisitSellerBid1, setRevisitSellerBid1] = useState("");
  const [revisitSellerBid2, setRevisitSellerBid2] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);
  const [priceFeatureData, setPriceFeatureData] = useState({
    price_type: "",
  });

  //Checkbox state
  const [confirmed, setConfirmed] = useState(props?.getListings?.detail?.detail?.qty_confirmed);
  const [askingPrice, setAskingPrice] = useState(
    priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")?.feature,
  );
  const [retailValue, setRetailValue] = useState(
    priceInfo?.auction_prices?.find((item) => item.description === "Retail Value")?.feature,
  );
  const [wholesaleValue, setWholesaleValue] = useState(
    priceInfo?.auction_prices?.find((item) => item.description === "Original Wholesale Value")?.feature,
  );
  const [claimValue, setClaimValue] = useState(
    priceInfo?.auction_prices?.find((item) => item.description === "Claim Value")?.feature,
  );

  const pipeCategories = [1115, 1295, 1321, 1322, 1323, 1400, 1404, 1442, 1515, 1754];
  const editModal = props?.qtyPricingModal;

  useEffect(() => {
    const data =
      props.getListingProductDetail &&
      props.getListingProductDetail.comparablePrice &&
      props.getListingProductDetail.comparablePrice.data;
    setComparablePriceData(data);
    setComparablePriceData1(data);
  }, [
    props.getListingProductDetail &&
      props.getListingProductDetail.comparablePrice &&
      props.getListingProductDetail.comparablePrice.data,
  ]);

  useEffect(() => {
    const handleUpdatePriceFeature = () => {
      refStart();
      props.updatePriceFeature(auctionId, priceFeatureData, (res) => {
        if (res.status === 200) {
          props.getPriceInfo(auctionId, (res) => {});
          refStop();
        } else {
          refStop();
          toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
        }
      });
    };
    if (auctionId && priceFeatureData?.price_type) {
      handleUpdatePriceFeature();
    }
  }, [priceFeatureData]);
  const toggleEditModal = () => {
    props?.setEditModal(!editModal);
  };

  const toggleComparablePrice = () => {
    setComparablePriceModal(!comparablePriceModal);
  };

  const handleSaveBidLog = (data) => {
    refStop();
    props.saveBidLog(auctionId, data, (res) => {
      if (res.status === 200) {
        props?.getLatestBids(auctionId, (res) => {});
        props?.getBidLog(auctionId, (res) => {});
        refStop();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  useEffect(() => {
    setRevisitSellerBid1(latestBids?.auctionInfo?.revisit_seller_bid_date);
    setRevisitSellerBid2(latestBids?.auctionInfo?.revisit_seller_bid_date);
  }, [latestBids]);

  const saveHandler = () => {
    setLoadingSave(true);
    refStart();
    const id = auctionId;
    const data =
      comparablePriceData &&
      comparablePriceData.length > 0 &&
      comparablePriceData.map((item) => ({
        amount: item.amount ? item.amount : "",
        link: item.link ? item.link : "",
        year: item.year ? item.year : "",
        location: item.location ? item.location : "",
      }));
    const payload = { price: data ? data : [] };
    props.updateComparablePrice(id, payload, (res) => {
      if (res.status === 200) {
        refStop();
        setLoadingSave(false);
        setComparablePriceData1(payload.price);
        toggleComparablePrice();
        toast(<AlertSuccess message={"Information Saved"} />);
      } else {
        refStop();
        setLoadingSave(false);
        toast(<AlertError message={res && res.data && res.data.message ? res.data.message : "Something went wrong"} />);
      }
    });
  };

  const handleRevisitSellerBid = (index) => {
    refStart();
    props.getRevisitSellerBid(auctionId, (res) => {
      if (res.status === 200) {
        props.getLatestBids(auctionId, (res) => {});
        refStop();
        if (index === 1) {
          setRevisitSellerBid1(res.data.revisit_seller_bid_date);
        } else if (index === 2) {
          setRevisitSellerBid2(res.data.revisit_seller_bid_date);
        }
      } else {
        refStop();
        toast(<AlertError messge={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  //Quantity Confirmation
  const handleQuantityConfirmation = (val) => {
    refStart();
    const payload = {
      status: val,
    };
    props.quantityConfirmation(auctionId, payload, (res) => {
      if (res.status === 200) {
        props.detailListing(auctionId, (res) => {});
        props.getCommentsAndNotes(auctionId, {}, (ress) => {});
        props.getLatestBids(auctionId, (res) => {});
        refStop();
      } else {
        refStop();
        toast(<AlertError message={res.data.message ? res.data.message : "Something Went Wrong"} />);
      }
    });
  };

  const data =
    (props.getListingProductDetail &&
      props.getListingProductDetail.detail &&
      props.getListingProductDetail.detail.detail) ||
    {};

  const val = localStorage.getItem("data");
  const session = JSON.parse(val);

  useEffect(() => {
    setConfirmed(props?.getListings?.detail?.detail?.qty_confirmed);
    setAskingPrice(priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")?.feature);
    setRetailValue(priceInfo?.auction_prices?.find((item) => item.description === "Retail Value")?.feature);
    setWholesaleValue(
      priceInfo?.auction_prices?.find((item) => item.description === "Original Wholesale Value")?.feature,
    );
    setClaimValue(priceInfo?.auction_prices?.find((item) => item.description === "Claim Value")?.feature);
  }, [priceInfo, props?.getListings?.detail, editModal]);

  return (
    <>
      <div class="details-page-widget-block" id={props?.id}>
        {!props.isOnlisting && (
          <div class="page-widget-head d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div class="page-widget-label text-capitalize cursor-pointer" onClick={() => toggleEditModal()}>
                Qty & Pricing
              </div>
            </div>
            {/* <div class="btn-block">
              {loadingMain ? (
                <Skeleton height="30px" width="90px" />
              ) : (
                <div class="btn btn-primary btn-sm" onClick={() => toggleEditModal()}>
                  Edit
                </div>
              )}
            </div> */}
          </div>
        )}
        {session.sec_list === 100101 && !props.isOnListing ? (
          <div
            class="page-widget-empty-box text-capitalize d-flex align-items-center justify-content-center text-center mt-3"
            style={{
              height: "150px",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <span>Access requested.</span>
          </div>
        ) : (
          <div class="page-widget-body mt-3">
            <div class="table-responsive">
              <table class="table mb-0  product-table">
                {loadingMain ? (
                  <>
                    <colgroup>
                      <col style={{ width: "35%" }} />
                      <col />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                        <td>
                          <Skeleton />
                        </td>
                      </tr>
                    </tbody>
                  </>
                ) : (
                  <>
                    <colgroup>
                      <col style={{ width: "35%" }} />
                      <col />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>Quantity / Unit</td>
                        <td>
                          <span className="qty-pricing-font">
                            {" "}
                            {priceInfo?.auction_details?.quantity ? priceInfo?.auction_details?.quantity : 0}{" "}
                            {priceInfo?.auction_details?.unit ? priceInfo?.auction_details?.unit : "Units"}
                          </span>
                          <br />
                          {latestBids?.qtyInfo && latestBids?.qtyInfo?.last_qty_confirmation_date != "" && (
                            <small>
                              Updated By {latestBids?.qtyInfo?.name01} at{" "}
                              {latestBids?.qtyInfo?.last_qty_confirmation_date}
                            </small>
                          )}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <div className="d-flex align-items-center">
                            <div class="ctcr-right">
                              <div class="form-check dark-tick-checkbox">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="chk_asking_price_feature"
                                  checked={confirmed}
                                  id="confirm"
                                  onChange={(e) => {
                                    setConfirmed(!confirmed);
                                    handleQuantityConfirmation(e.target.checked ? 1 : 0);
                                  }}
                                />
                                <label class="form-check-label" for="confirm"></label>
                              </div>
                            </div>
                            <span style={{ marginRight: "10px" }}>Confirmed?</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Public Asking Price</td>
                        <td>
                          <span className="qty-pricing-font">
                            {priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")?.p_value
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(
                                  priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")
                                    ?.p_value,
                                )
                              : "$0.00"}

                            {priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")?.p_value &&
                              !isNaN(
                                priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")?.p_value,
                              ) &&
                              productDetails?.pipeDetail?.tonnage &&
                              !isNaN(productDetails?.pipeDetail?.tonnage) &&
                              productDetails?.pipeDetail?.tonnage > 0 && (
                                <span class="fw-normal">
                                  (
                                  {(
                                    priceInfo?.auction_prices?.find((item) => item.description === "Asking Price")
                                      ?.p_value / productDetails?.pipeDetail?.tonnage
                                  )?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) +
                                    "/MT"}
                                  )
                                </span>
                              )}
                          </span>
                        </td>
                        <td>
                          <div class="ctcr-right">
                            <div class="form-check dark-tick-checkbox">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="chk_asking_price_feature"
                                checked={askingPrice}
                                id="latest-activity-asking-pricexa"
                                onClick={(e) => {
                                  const data = { ...priceFeatureData };
                                  data.price_type = e.target.value;
                                  setAskingPrice(!askingPrice);
                                  setPriceFeatureData(data);
                                }}
                              />
                              <label class="form-check-label" for="latest-activity-asking-pricexa"></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Seller Asking Price {latestBids?.isSellerAccepted && <img src={icon2} alt="" />}</td>
                        <td>
                          <span className="qty-pricing-font">
                            {" "}
                            {priceInfo?.auction_details?.seller_reserve
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(priceInfo?.auction_details?.seller_reserve)
                              : 0}{" "}
                            <br />
                            {priceInfo?.auction_details?.seller_reserve &&
                              !isNaN(priceInfo?.auction_details?.seller_reserve) &&
                              productDetails?.pipeDetail?.tonnage &&
                              !isNaN(productDetails?.pipeDetail?.tonnage) &&
                              productDetails?.pipeDetail?.tonnage > 0 && (
                                <span class="fw-normal">
                                  (
                                  {(
                                    priceInfo?.auction_details?.seller_reserve / productDetails?.pipeDetail?.tonnage
                                  )?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) +
                                    "/MT"}
                                  )
                                </span>
                              )}
                            <span className="qty-pricing-font">
                              (
                              {priceInfo?.auction_details?.seller_reserve && priceInfo?.auction_details?.quantity
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(
                                    (
                                      priceInfo?.auction_details?.seller_reserve / priceInfo?.auction_details?.quantity
                                    ).toFixed(2),
                                  )
                                : 0}{" "}
                              Per Unit)
                            </span>
                            &nbsp;
                            <br />
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Seller Asking Price Percentage</td>
                        <td>
                          <span className="qty-pricing-font">
                            {" "}
                            {priceInfo?.auction_prices?.find((item) => item.description === "Original Wholesale Value")
                              ?.p_value > 0
                              ? Math.round(
                                  (priceInfo?.auction_details?.seller_reserve /
                                    priceInfo?.auction_prices?.find(
                                      (item) => item.description === "Original Wholesale Value",
                                    )?.p_value) *
                                    100,
                                ) + "%"
                              : "0%"}
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Original Wholesale Value</td>
                        <td>
                          <span className="qty-pricing-font">
                            {priceInfo?.auction_prices?.find((item) => item.description === "Original Wholesale Value")
                              ?.p_value
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(
                                  priceInfo?.auction_prices?.find(
                                    (item) => item.description === "Original Wholesale Value",
                                  )?.p_value,
                                )
                              : "$0.00"}
                          </span>
                        </td>
                        <td>
                          <div class="ctcr-right">
                            <div class="form-check dark-tick-checkbox">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="chk_wholesale_value_feature"
                                checked={wholesaleValue}
                                id="latest-activity-wholesale-pricexa"
                                onClick={(e) => {
                                  const data = { ...priceFeatureData };
                                  data.price_type = e.target.value;
                                  setWholesaleValue(!wholesaleValue);
                                  setPriceFeatureData(data);
                                }}
                              />
                              <label class="form-check-label" for="latest-activity-wholesale-pricexa"></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Retail Value</td>
                        <td>
                          <span className="qty-pricing-font">
                            {" "}
                            {priceInfo?.auction_prices?.find((item) => item.description === "Retail Value")?.p_value
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(
                                  priceInfo?.auction_prices?.find((item) => item.description === "Retail Value")
                                    ?.p_value,
                                )
                              : "$0.00"}
                          </span>
                        </td>
                        <td>
                          {" "}
                          <div class="ctcr-right">
                            <div class="form-check dark-tick-checkbox">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="chk_retail_value_feature"
                                checked={retailValue}
                                id="latest-activity-retailvalue-price12"
                                onClick={(e) => {
                                  const data = { ...priceFeatureData };
                                  data.price_type = e.target.value;
                                  setRetailValue(!retailValue);
                                  setPriceFeatureData(data);
                                }}
                              />
                              <label class="form-check-label" for="latest-activity-retailvalue-price12"></label>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>Claim Value</td>
                        <td>
                          <span className="qty-pricing-font">
                            {priceInfo?.auction_prices?.find((item) => item.description === "Claim Value")?.p_value
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(
                                  priceInfo?.auction_prices?.find((item) => item.description === "Claim Value")
                                    ?.p_value,
                                )
                              : "$0.00"}
                          </span>
                        </td>
                        <td>
                          <div class="ctcr-right">
                            <div class="form-check dark-tick-checkbox">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value="chk_claim_value_feature"
                                checked={claimValue}
                                id="latest-activity-claim-priceaa"
                                onClick={(e) => {
                                  const data = { ...priceFeatureData };
                                  data.price_type = e.target.value;
                                  setClaimValue(!claimValue);
                                  setPriceFeatureData(data);
                                }}
                              />
                              <label class="form-check-label" for="latest-activity-claim-priceaa"></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Recycle Value</td>
                        <td>
                          <span className="qty-pricing-font">
                            {" "}
                            {latestBids?.auctionInfo?.scrap_value
                              ? "$" +
                                new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(latestBids?.auctionInfo?.scrap_value)
                              : "$0.00"}
                          </span>
                        </td>
                        <td></td>
                      </tr>
                      {latestBids?.auctionInfo?.scrapPrice && !isNaN(latestBids?.auctionInfo?.scrapPrice) && (
                        <tr>
                          <td>Estimated Scrap Price</td>
                          <td>
                            <span className="qty-pricing-font">
                              {" "}
                              {latestBids?.auctionInfo?.scrapPrice
                                ? "$" +
                                  new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(latestBids?.auctionInfo?.scrapPrice)
                                : "$0.00"}
                            </span>
                          </td>
                          <td></td>
                        </tr>
                      )}

                      <tr>
                        <td>Bid Due to Seller</td>
                        <td>
                          {latestBids?.auctionInfo?.due_date ? (
                            <DateFormat date={latestBids?.auctionInfo?.due_date} />
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td></td>
                      </tr>
                      {latestBids?.bidInfo && Object.keys(latestBids?.bidInfo)?.length > 0 ? (
                        <>
                          <tr>
                            <td>Last Bid Date</td>
                            <td>
                              {latestBids?.bidInfo?.seller_bid_date ? (
                                <DateFormat date={latestBids?.bidInfo?.seller_bid_date} />
                              ) : (
                                "N/A"
                              )}
                            </td>
                            <td> </td>
                          </tr>
                          <tr>
                            <td>Last Bid Amount</td>
                            <td>
                              <span className="qty-pricing-font">
                                {latestBids?.bidInfo?.seller_bid
                                  ? "$" +
                                    new Intl.NumberFormat("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(latestBids?.bidInfo?.seller_bid)
                                  : "$0.00"}
                                <br />
                                {latestBids?.bidInfo?.seller_bid &&
                                  !isNaN(latestBids?.bidInfo?.seller_bid) &&
                                  productDetails?.pipeDetail?.tonnage &&
                                  !isNaN(productDetails?.pipeDetail?.tonnage) &&
                                  productDetails?.pipeDetail?.tonnage > 0 && (
                                    <span class="fw-normal">
                                      (
                                      {(
                                        latestBids?.bidInfo?.seller_bid / productDetails?.pipeDetail?.tonnage
                                      )?.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }) + "/MT"}
                                      )
                                    </span>
                                  )}
                                <br />
                                <span class="fw-normal">
                                  (
                                  {latestBids?.bidInfo?.seller_bid && priceInfo?.auction_details?.quantity
                                    ? "$" +
                                      new Intl.NumberFormat("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(
                                        (
                                          latestBids?.bidInfo?.seller_bid / priceInfo?.auction_details?.quantity
                                        ).toFixed(2),
                                      )
                                    : 0}{" "}
                                  Per Unit )
                                </span>
                                <div class="mt-3">
                                  {bidLog &&
                                    bidLog.length > 0 &&
                                    bidLog.map((val) => (
                                      <div className="col-text mt-2">
                                        {val.updated_dt.replace(/(AM|PM)/, " $1") + " CST"} Bid{" "}
                                        <span className={val.type ? "text-success" : "text-danger"}>
                                          {val.bid_amount
                                            ? "$" +
                                              new Intl.NumberFormat("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }).format(val.bid_amount)
                                            : "$0"}{" "}
                                          {val.type ? "Accepted" : "Rejected"}
                                        </span>
                                        {!val.type &&
                                          `- Seller Asking ${
                                            val.sp_amount
                                              ? "$" +
                                                new Intl.NumberFormat("en-US", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }).format(val.sp_amount)
                                              : "$0"
                                          }`}
                                      </div>
                                    ))}
                                </div>
                                <div class="col-button-group">
                                  <div class="col-btn-list d-flex flex-wrap">
                                    {latestBids?.auctionInfo?.revisit_seller_bid_date && (
                                      <div>
                                        Revisit seller bid on{" "}
                                        {latestBids?.auctionInfo?.revisit_seller_bid_date && (
                                          <span className="fw-medium">
                                            {moment
                                              .tz(latestBids?.auctionInfo?.revisit_seller_bid_date, "America/Chicago")
                                              .format("MM/DD/YY")}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                    <div class="col-btn-item">
                                      <a
                                        href="javascript:void(0)"
                                        class="btn btn-auto btn-xs-size btn-default"
                                        onClick={() => {
                                          handleRevisitSellerBid(2);
                                        }}
                                      >
                                        {revisitSellerBid2 ? "Reinitiate" : "Revisit"} Seller Bid
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </td>
                            <td>
                              {" "}
                              <div class="col-button-group">
                                <div class="col-btn-list d-flex flex-wrap">
                                  <div class="col-btn-item">
                                    <a
                                      href="javascript:void(0)"
                                      class="btn btn-auto btn-xs-size btn-default"
                                      onClick={() => {
                                        const data = {};
                                        data.type = 1;
                                        data.step = "Seller Accepted Bid";
                                        data.category = "Closing";
                                        data.bid = latestBids?.bidInfo?.seller_bid.toString();
                                        data.sp = latestBids?.auctionInfo?.seller_reserve.toString();
                                        data.bid_id = latestBids?.bidInfo?.b_id.toString();
                                        handleSaveBidLog(data);
                                      }}
                                    >
                                      Accepted
                                    </a>
                                  </div>
                                  <div class="col-btn-item">
                                    <a
                                      href="javascript:void(0)"
                                      class="btn btn-auto btn-xs-size btn-primary"
                                      onClick={() => {
                                        const data = {};
                                        data.type = 0;
                                        data.step = "Seller Accepted Bid";
                                        data.category = "Closing";
                                        data.bid = latestBids?.bidInfo?.seller_bid.toString();
                                        data.sp = latestBids?.auctionInfo?.seller_reserve.toString();
                                        data.bid_id = latestBids?.bidInfo?.b_id.toString();
                                        handleSaveBidLog(data);
                                      }}
                                    >
                                      Rejected
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td>Last Bid Sent</td>
                          <td>
                            <strong>None Sent</strong>
                          </td>
                          <td> </td>
                        </tr>
                      )}
                    </tbody>
                  </>
                )}
              </table>
            </div>
            {(session.sec_list === 100103 || session.sec_list === 100105) && (
              <>
                <div class="dividor"></div>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-title cursor-pointer" onClick={() => !loadingMain && toggleComparablePrice()}>
                    Comparable Prices{" "}
                    {comparablePriceData1 && comparablePriceData1.length > 0 ? "" : !loadingMain && "(0)"}
                  </div>
                  {/* <div>
                    {comparablePriceData1 && comparablePriceData1.length > 0
                      ? !loadingMain && (
                          <div class="btn btn-primary btn-sm mt-1" onClick={() => toggleComparablePrice()}>
                            Edit
                          </div>
                        )
                      : !loadingMain && (
                          <div class="btn btn-primary btn-sm mt-1" onClick={() => toggleComparablePrice()}>
                            Click here to Add Comparable Price
                          </div>
                        )}
                  </div> */}
                </div>
                {/* <div class="empty-table-row mt-3 d-flex flex-wrap"> */}
                <div class="table-responsive table-custom mt-2">
                  {loadingMain ? (
                    <table class="table table-bordered">
                      <colgroup>
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                      </colgroup>
                      <thead>
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {[1, 2, 3].map((item) => (
                          <tr>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              {" "}
                              <Skeleton />
                            </td>
                            <td>
                              {" "}
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    comparablePriceData1 &&
                    comparablePriceData1.length > 0 && (
                      <table class="table table-bordered">
                        <colgroup>
                          <col style={{ width: "25%" }} />
                          <col style={{ width: "25%" }} />
                          <col style={{ width: "25%" }} />
                          <col style={{ width: "25%" }} />
                        </colgroup>
                        <thead>
                          <tr>
                            <td>Amount</td>
                            <td>Year</td>
                            <td>Location</td>
                            <td>Link</td>
                          </tr>
                        </thead>
                        <tbody>
                          {comparablePriceData1.map((item) => (
                            <tr>
                              <td>
                                {item.amount
                                  ? "$" +
                                    new Intl.NumberFormat("en-US", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }).format(item.amount)
                                  : "$0.00"}
                              </td>
                              <td>{item.year ? item.year : "N/A"}</td>
                              <td>{item.location ? item.location : "N/A"}</td>
                              <td>
                                <a
                                  href={
                                    item.link
                                      ? item.link.substring(0, 5) === "https"
                                        ? `${item.link}`
                                        : `https://${item.link}`
                                      : "javascript:void(0)"
                                  }
                                  target={item.link && "_blank"}
                                  className={item.link ? "text-green" : ""}
                                >
                                  {item.link ? "Link" : "N/A"}
                                </a>{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                  )}
                </div>
              </>
            )}
            <EditProductDetails
              section="pricing"
              show={editModal}
              LatestActivity={true}
              toggleEditModal={() => toggleEditModal()}
              updateProductDetails={props?.updateProductDetails}
              listingProductDetailReceived={props?.listingProductDetailReceived}
              auctionId={auctionId}
              setLoadingDetails={setLoadingDetails}
              loadingDetails={loadingDetails}
              data={data}
              refStart={() => refStart()}
              refStop={() => refStop()}
              getLatestBids={props?.getLatestBids}
              // productDetails={productDetails}
              getPriceInfo={props?.getPriceInfo}
              productDetails={props.productDetails}
              detailListing={props.detailListing}
              getDealProgress={props.getDealProgress}
              getCommentsAndNotes={props.getCommentsAndNotes}
              qtyConfirmation={props?.getListings?.detail?.detail?.qty_confirmed}
              handleQuantityConfirmation={handleQuantityConfirmation}
              confirmed={confirmed}
              setConfirmed={setConfirmed}
              askingPrice={askingPrice}
              setAskingPrice={setAskingPrice}
              retailValue={retailValue}
              setRetailValue={setRetailValue}
              wholesaleValue={wholesaleValue}
              setWholesaleValue={setWholesaleValue}
              claimValue={claimValue}
              setClaimValue={setClaimValue}
            />
            <ComparablePrice
              show={comparablePriceModal}
              onHide={toggleComparablePrice}
              comparablePriceData={comparablePriceData}
              comparablePriceData1={comparablePriceData1}
              setComparablePriceData={setComparablePriceData}
              loadingSave={loadingSave}
              saveHandler={saveHandler}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  //global state for storing api value
  updateProductDetails: (params, data, callback) => dispatch(updateProductDetails(params, data, callback)),
  //global state managed
  listingProductDetailReceived: (data) => dispatch(listingProductDetailReceived(data)),
  updateComparablePrice: (params, data, callback) => dispatch(updateComparablePrice(params, data, callback)),
  getLatestBids: (params, callback) => dispatch(getLatestBids(params, callback)),
  detailListing: (params, callback) => dispatch(detailListing(params, callback)),
  getBidLog: (params, callback) => dispatch(getBidLog(params, callback)),
  productDetails: (params, callback) => dispatch(productDetails(params, callback)),
  getPriceInfo: (params, callback) => dispatch(getPriceInfo(params, callback)),
  getDealProgress: (params, callback) => dispatch(getDealProgress(params, callback)),
  getCommentsAndNotes: (id, params, callback) => dispatch(getCommentsAndNotes(id, params, callback)),
  saveBidLog: (params, data, callback) => dispatch(saveBidLog(params, data, callback)),
  getRevisitSellerBid: (params, callback) => dispatch(getRevisitSellerBid(params, callback)),
  quantityConfirmation: (params, data, callback) => dispatch(quantityConfirmation(params, data, callback)),

  updatePriceFeature: (params, data, callback) => dispatch(updatePriceFeature(params, data, callback)),
  setEditModal: (data) => dispatch(setQtyPricingModal(data)),
});

const mapStateToProps = (state) => ({
  getListingProductDetail: getListingProductDetail(state),
  getLatestActivity: getLatestActivity(state),
  qtyPricingModal: getModals(state)?.qtyPricingModal,
  getListings: getListings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Pricing));
