import React, { useEffect, useState } from "react";
import queryString from "query-string";
import close from "../../../include/images/close.svg";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";
import _ from "lodash";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import NextButton from "../../../common/form/nextButton";
import DateFormat from "../../../common/DateFormat/dateFormat";
import { connect } from "react-redux";
import { productLocationDetails, updateLocationSecurity } from "../../../store/productLocation";
import pdfIcon from "../../../include/images/pdf-file-icon.svg";
import moment from "moment";
import { getMoreDetails } from "../../../store/listingProduct";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ProductLocationModal = (props) => {
  const mode = process.env.REACT_APP_MODE;
  const location = useLocation();
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  //Options Location Type
  const optionLocationType = [
    { name: "Unknown", value: "Unknown" },
    { name: "Warehouse (private with NO equipment or crew)", value: "TYPE3" },
    { name: "Warehouse (manned with equipment & crew)", value: "TYPE2" },
    { name: "Yard/Field/Outdoor Space", value: "TYPE4" },
    { name: "Port (Air, Sea)", value: "TYPE1" },
    {
      name: "Retail Storefront/Office/Residence/Private Property",
      value: "TYPE5",
    },
  ];
  //Options Demurrage
  const optionDemurrage = [
    { name: "None / Not Applicable", value: "none" },
    { name: "Cleared / Seller will pay before release", value: "cleared" },
    { name: "Seller expects buyer to pay", value: "expects" },
  ];
  const [locationType, setLocationType] = useState({
    name: "",
    value: "",
  });
  const [truckFree, SetTruckFree] = useState({
    name: "",
    value: "",
  });
  const [inspectionAllowed, setInspectionAllowed] = useState({
    name: "",
    value: "",
  });
  const [uiiaCertified, setUiiaCertified] = useState({
    name: "",
    value: "",
  });
  const [shipped, setShipped] = useState({
    name: "",
    value: "",
  });
  const [emptyContainers, setEmptyContainers] = useState({
    name: "",
    value: "",
  });
  const [demurrage, setDemurrage] = useState({
    name: "",
    value: "",
  });
  const [customs, setCustoms] = useState({
    name: "",
    value: "",
  });

  const optionYesNo = [
    { name: "Yes", value: "yes" },
    { name: "No", value: "no" },
  ];

  useEffect(() => {
    const data = optionLocationType.find((item) => item.value === props?.locationDetails?.location_type)
      ? optionLocationType.find((item) => item.value === props?.locationDetails?.location_type)
      : {
          name: "Warehouse (private with NO equipment or crew)",
          value: "TYPE3",
        };
    setLocationType(data);
    const parseData = props.locationDetails?.logistics_details
      ? JSON.parse(props.locationDetails?.logistics_details)
      : {};
    SetTruckFree(optionYesNo.find((item) => item.value === parseData?.truck_free?.VALUE));
    setInspectionAllowed(optionYesNo.find((item) => item.value === parseData?.inspection_allowed?.VALUE));
    setUiiaCertified(optionYesNo.find((item) => item.value === parseData?.uiia_certified?.VALUE));
    setShipped(optionYesNo.find((item) => item.value === parseData?.shipped?.VALUE));
    setEmptyContainers(optionYesNo.find((item) => item.value === parseData?.empty_containers?.VALUE));
    setDemurrage(optionDemurrage.find((item) => item.value === parseData?.demurrage?.VALUE));
    setCustoms(optionYesNo.find((item) => item.value === parseData?.customs?.VALUE));
  }, [props.show]);

  useEffect(() => {
    if (props.show && _.isEmpty(stateOptions)) {
      const data = { ...props.region };
      if (props?.productLocationDetails?.states?.data) {
        setStateOptions(props?.productLocationDetails?.states?.data);
        const val = props?.productLocationDetails?.states?.data;
        data.stateData = val.find((item) => item.old === props.values.state_id);
        props.setRegion(data);
      } else {
        props.getStates((res) => {
          if (res.status === 200) {
            const val = res.data?.data;
            data.stateData = val.find((item) => item.old === props.values.state_id);
            props.setRegion(data);
            setStateOptions(res.data.data);
          }
        });
      }
    }
  }, [props.show]);

  useEffect(() => {
    const data = { ...props.region };
    if (countryOptions) {
      data.countryData = countryOptions?.find((item) => item.old === props.values.country_id);
      props.setRegion(data);
    }
    if (stateOptions) {
      data.stateData = stateOptions?.find((item) => item.old === props?.values?.state_id);
      props.setRegion(data);
    }
  }, [countryOptions, stateOptions]);

  useEffect(() => {
    if (props.show && _.isEmpty(countryOptions)) {
      const data = { ...props.region };
      if (props?.productLocationDetails?.countries?.data) {
        setCountryOptions(props?.productLocationDetails?.countries?.data);
        const val = props?.productLocationDetails?.countries?.data;
        data.countryData = val.find((item) => item.old === props.values.country_id);
        props.setRegion(data);
      } else {
        props.getCountries((res) => {
          if (res.status === 200) {
            const val = res.data?.data;
            data.countryData = val.find((item) => item.old === props.values.country_id);
            props.setRegion(data);
            setCountryOptions(res.data.data);
          }
        });
      }
    }
  }, [props.show]);

  const onHide = () => {
    props.setShowProductLocationModal(!props.show);
    props?.setReload(!props?.reload);
  };

  function formatDateForDisplay(isoDateString) {
    const date = new Date(isoDateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC", // Set the time zone to UTC
    };
    return date.toLocaleString("en-US", options);
  }

  return (
    <Modal
      className="modal fade custom-modal"
      id="editProductLocation"
      show={props.show}
      onHide={onHide}
      dialogClassName="modal-dialog modal-dialog-centered modal-xxl"
    >
      <div class="modal-content d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Product Location</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden pb-0">
          <div class="detail-row-container d-flex flex-wrap">
            <div class="drc-left w-50">
              <a
                href="javascript:void(0)"
                onClick={props.populateFromSeller}
                class="btn btn-default btn-xs-size btn-auto"
              >
                Populate From Seller
              </a>
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col style={{ width: "180px" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Contact</td>
                    <td>
                      <input
                        type="text"
                        value={props && props.values && props.values.c_name}
                        class="form-control"
                        onChange={(e) => {
                          const values = { ...props.values };
                          values.c_name = e.target.value;
                          props.setValues(values);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Company</td>
                    <td>
                      <input
                        type="text"
                        value={props && props.values && props.values.c_company}
                        class="form-control"
                        onChange={(e) => {
                          const values = { ...props.values };
                          values.c_company = e.target.value;
                          props.setValues(values);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>
                      <input
                        type="text"
                        value={props && props.values && props.values.c_phone}
                        class="form-control"
                        onChange={(e) => {
                          const values = { ...props.values };
                          values.c_phone = e.target.value;
                          props.setValues(values);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Fax</td>
                    <td>
                      <input
                        type="text"
                        value={props && props.values && props.values.c_fax}
                        class="form-control"
                        onChange={(e) => {
                          const values = { ...props.values };
                          values.c_fax = e.target.value;
                          props.setValues(values);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      <input
                        type="email"
                        value={props && props.values && props.values.c_email}
                        class="form-control"
                        onChange={(e) => {
                          const values = { ...props.values };
                          values.c_email = e.target.value;
                          props.setValues(values);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>
                      <input
                        type="text"
                        value={props && props.values && props.values.address_1}
                        class="form-control"
                        onChange={(e) => {
                          const values = { ...props.values };
                          values.address_1 = e.target.value;
                          props.setValues(values);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td>
                      <input
                        type="text"
                        value={props && props.values && props.values.city}
                        class="form-control"
                        onChange={(e) => {
                          const values = { ...props.values };
                          values.city = e.target.value;
                          props.setValues(values);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Zip</td>
                    <td>
                      <input
                        type="text"
                        value={props && props.values && props.values.zip}
                        class="form-control"
                        onChange={(e) => {
                          const values = { ...props.values };
                          values.zip = e.target.value;
                          props.setValues(values);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>
                      <Select
                        value={props.region.countryData}
                        className="basic-single"
                        classNamePrefix="select-search "
                        getOptionLabel={(option) => option.description}
                        getOptionValue={(option) => option.old}
                        options={countryOptions}
                        name="country"
                        placeholder="Select Country"
                        styles={customStyles}
                        onChange={(selectedOption) => {
                          const values = { ...props.values };
                          values.country_id = selectedOption.old;
                          props.setValues(values);
                          props.setRegion({
                            ...props.region,
                            countryData: selectedOption,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>State</td>
                    {props?.region?.countryData?.description === "United States" ? (
                      <td>
                        <Select
                          value={props.region.stateData}
                          className="basic-single"
                          classNamePrefix="select-search "
                          getOptionLabel={(option) => option.description}
                          getOptionValue={(option) => option.old}
                          options={stateOptions?.filter((item) => item.abb !== "IT")}
                          name="state"
                          placeholder="Select State"
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            const values = { ...props.values };
                            values.state_id = selectedOption.old;
                            props.setValues(values);
                            props.setRegion({
                              ...props.region,
                              stateData: selectedOption,
                            });
                          }}
                        />
                      </td>
                    ) : (
                      <td>
                        <Select
                          value={{
                            name: "International",
                            value: 1001,
                          }}
                          className="basic-single"
                          classNamePrefix="select-search "
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          options={[
                            {
                              name: "International",
                              value: 1001,
                            },
                          ]}
                          name="state"
                          placeholder="Select State"
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            const values = { ...props.values };
                            values.state_id = selectedOption.value;
                            props.setValues(values);
                            props.setRegion({
                              ...props.region,
                              stateData: selectedOption,
                            });
                          }}
                        />
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td>Post to all seller's listings?</td>
                    <td>
                      <div class="form-check dark-tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="location_post"
                          checked={props?.values?.location_post}
                          onChange={(e) => {
                            const values = { ...props.values };
                            values.location_post = e.target.checked;
                            props.setValues(values);
                          }}
                        />
                        <label class="form-check-label" for="location_post"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="dividerTable"></div>
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col style={{ width: "180px" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Loading Costs</td>
                    <td>
                      <textarea
                        class="form-control"
                        name=""
                        value={props && props.values && props.values.loading_costs}
                        onChange={(e) => {
                          const values = { ...props.values };
                          values.loading_costs = e.target.value;
                          props.setValues(values);
                        }}
                        id="textarea-160"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td>Post to all seller's listings?</td>
                    <td>
                      <div class="form-check dark-tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="loading_costs_post"
                          checked={props?.values?.loading_costs_post}
                          onChange={(e) => {
                            const values = { ...props.values };
                            values.loading_costs_post = e.target.checked;
                            props.setValues(values);
                          }}
                        />
                        <label class="form-check-label" for="loading_costs_post"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Feature?</td>
                    <td>
                      <div class="form-check dark-tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="tick3"
                          checked={props?.values?.loading_costs_feature}
                          onChange={(e) => {
                            const values = { ...props.values };
                            values.loading_costs_feature = e.target.checked;
                            props.setValues(values);
                          }}
                        />
                        <label class="form-check-label" for="tick3"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="drc-right w-50">
              <div class="page-widget-label text-capitalize">Location Security</div>
              <div class="table-responsive mt-2 mb-2">
                <div class="td-radio-group">
                  <div class="form-check radio">
                    <input
                      class="form-check-input cursor-pointer"
                      type="radio"
                      name="flexRadioDefault1"
                      id="continent1"
                      value={0}
                      defaultChecked={props?.locSec.loc_sec === 0}
                      onChange={props?.handleChange}
                    />
                    <label class="form-check-label" for="continent1">
                      Continent
                    </label>
                  </div>
                  <div class="form-check radio">
                    <input
                      class="form-check-input cursor-pointer"
                      type="radio"
                      name="flexRadioDefault1"
                      id="region1"
                      value={1}
                      defaultChecked={props?.locSec.loc_sec === 1}
                      onChange={props?.handleChange}
                    />
                    <label class="form-check-label" for="region1">
                      Region
                    </label>
                  </div>
                  <div class="form-check radio">
                    <input
                      class="form-check-input cursor-pointer"
                      type="radio"
                      name="flexRadioDefault1"
                      id="cityStateCountry1"
                      value={2}
                      defaultChecked={props?.locSec.loc_sec === 2}
                      onChange={props?.handleChange}
                    />
                    <label class="form-check-label" for="cityStateCountry1">
                      City, State/Country
                    </label>
                  </div>
                  <div class="form-check radio">
                    <input
                      class="form-check-input cursor-pointer"
                      type="radio"
                      name="flexRadioDefault1"
                      id="exact1"
                      value={3}
                      defaultChecked={props?.locSec.loc_sec === 3}
                      onChange={props?.handleChange}
                    />
                    <label class="form-check-label" for="exact1">
                      Exact
                    </label>
                  </div>
                </div>
              </div>
              <div class="dividerTable"></div>
              <div class="page-widget-label text-capitalize">Storage</div>
              <table class="table table-borderless align-middle table-left-align">
                <colgroup>
                  <col style={{ width: "180px" }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>Storage Costs</td>
                    <td>
                      <input
                        type="text"
                        value={props && props.values && props.values.storage_costs}
                        onChange={(e) => {
                          const values = { ...props.values };
                          values.storage_costs = e.target.value;
                          props.setValues(values);
                        }}
                        class="form-control"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Storage Date</td>
                    <td>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableHighlightToday={true}
                          className="form-control"
                          value={formatDateForDisplay(props && props.values && props.values.storage_date)}
                          onChange={(e) => {
                            const values = { ...props.values };
                            values.storage_date = e ? e : "";
                            props.setValues(values);
                          }}
                          renderInput={(params) => <TextField {...params} error={false} />}
                          inputFormat="MM/dd/yyyy"
                        />
                      </LocalizationProvider>
                      {props.storageDate && (
                        <p style={{ marginTop: "3px" }}>
                          {" "}
                          <DateFormat date={props.storageDate} />
                        </p>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Storage, Taxes and Fees</td>
                    <td>
                      <textarea
                        class="form-control"
                        value={props && props.values && props.values.storage_taxes_fees}
                        onChange={(e) => {
                          const values = { ...props.values };
                          values.storage_taxes_fees = e.target.value;
                          props.setValues(values);
                        }}
                        id="textarea-160"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td>Feature?</td>
                    <td>
                      <div class="form-check dark-tick-checkbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          checked={props?.values?.storage_taxes_fees_feature}
                          value={props && props.values && props.values.storage_taxes_fees_feature}
                          onChange={(e) => {
                            const values = { ...props.values };
                            values.storage_taxes_fees_feature = e.target.checked;
                            props.setValues(values);
                          }}
                          id="tick-1"
                        />
                        <label class="form-check-label" for="tick-1"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="dividerTable"></div>

            <div class="page-widget-label text-capitalize">Logistics and Release Details</div>
            <table className="table table-borderless align-middle table-left-align">
              <colgroup>
                {/* <col style={{ width: "45%" }} /> */}
                <col style={{ width: "15%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "40%" }} />
              </colgroup>
              <tbody>
                <tr>
                  {/* <td>
                    <span className="fw-medium">Location Type</span>
                  </td> */}
                  <td className="mt-1">Include in Product Release?</td>
                  <td className=" separator-right"></td>
                  <Select
                    styles={customStyles}
                    placeholder="Select"
                    options={optionLocationType}
                    value={locationType}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.value}
                    onChange={(e) => {
                      const data = { ...props.values };
                      data.location_type = e.value;
                      props.setValues(data);
                      setLocationType(e);
                    }}
                  />
                </tr>
                <tr>
                  {/* <td>
                    <span className="fw-medium">Location Requirements/Restrictions/Logistics Details</span>
                  </td> */}

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={"truck_free"}
                        checked={props?.values?.location_requirement?.includeInRelease
                          ?.split(",")
                          .includes("truck_free")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_truck_free"
                      />
                      <label class="form-check-label" for="chk_truck_free"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Can load buyer's truck free</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("truck_free") ||
                          props?.values?.location_requirement?.truck_free?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.truck_free?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.truck_free;
                          } else {
                            updatedLocationRequirement.truck_free = {
                              VALUE: "",
                            };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="truck_free_chk"
                      />
                      <label class="form-check-label" for="truck_free_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("truck_free") &&
                      props?.values?.location_requirement?.truck_free?.hasOwnProperty("VALUE") && (
                        <Select
                          styles={customStyles}
                          placeholder="Select"
                          options={optionYesNo}
                          value={truckFree}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => {
                            const updatedLocationRequirement = {
                              ...props.values.location_requirement,
                            };
                            updatedLocationRequirement.truck_free = {
                              VALUE: e.value,
                            };
                            props.setValues({
                              ...props.values,
                              location_requirement: updatedLocationRequirement,
                            });
                            SetTruckFree(e);
                          }}
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="truck_fee"
                        checked={props?.values?.location_requirement?.includeInRelease
                          ?.split(",")
                          .includes("truck_fee")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_truck_fee"
                      />
                      <label class="form-check-label" for="chk_truck_fee"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Can load buyer's truck for a fee</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("truck_fee") ||
                          props?.values?.location_requirement?.truck_fee?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.truck_fee?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.truck_fee;
                          } else {
                            updatedLocationRequirement.truck_fee = {
                              VALUE: "",
                            };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="truch_fee_chk"
                      />
                      <label class="form-check-label" for="truch_fee_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("truck_fee") &&
                      props?.values?.location_requirement?.truck_fee?.hasOwnProperty("VALUE") && (
                        <input
                          type="text"
                          value={props?.values?.location_requirement?.truck_fee?.VALUE}
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              location_requirement: {
                                ...props.values.location_requirement,
                                truck_fee: {
                                  ...props.values.location_requirement.truck_fee,
                                  VALUE: e.target.value,
                                },
                              },
                            });
                          }}
                          className="form-control"
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={"inspection_allowed"}
                        checked={props?.values?.location_requirement?.includeInRelease
                          ?.split(",")
                          .includes("inspection_allowed")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_inspection_allowed"
                      />
                      <label class="form-check-label" for="chk_inspection_allowed"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Inspections Allowed?</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("inspection_allowed") ||
                          props?.values?.location_requirement?.inspection_allowed?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.inspection_allowed?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.inspection_allowed;
                          } else {
                            updatedLocationRequirement.inspection_allowed = {
                              VALUE: "",
                            };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="inspection_allowed_chk"
                      />
                      <label class="form-check-label" for="inspection_allowed_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("inspection_allowed") &&
                      props?.values?.location_requirement?.inspection_allowed?.hasOwnProperty("VALUE") && (
                        <Select
                          styles={customStyles}
                          placeholder="Select"
                          options={optionYesNo}
                          value={inspectionAllowed}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => {
                            const updatedLocationRequirement = {
                              ...props.values.location_requirement,
                            };
                            updatedLocationRequirement.inspection_allowed = {
                              VALUE: e.value,
                            };
                            props.setValues({
                              ...props.values,
                              location_requirement: updatedLocationRequirement,
                            });
                            setInspectionAllowed(e);
                          }}
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={"uiia_certified"}
                        checked={props?.values?.location_requirement?.includeInRelease
                          ?.split(",")
                          .includes("uiia_certified")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_uiia_certified"
                      />
                      <label class="form-check-label" for="chk_uiia_certified"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>UIIA Certified Driver Required for Entry Into Location</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("uiia_certified") ||
                          props?.values?.location_requirement?.uiia_certified?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.uiia_certified?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.uiia_certified;
                          } else {
                            updatedLocationRequirement.uiia_certified = {
                              VALUE: "",
                            };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="uiia_certified_chk"
                      />
                      <label class="form-check-label" for="uiia_certified_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("uiia_certified") &&
                      props?.values?.location_requirement?.uiia_certified?.hasOwnProperty("VALUE") && (
                        <Select
                          styles={customStyles}
                          placeholder="Select"
                          options={optionYesNo}
                          value={uiiaCertified}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => {
                            const updatedLocationRequirement = {
                              ...props.values.location_requirement,
                            };
                            updatedLocationRequirement.uiia_certified = {
                              VALUE: e.value,
                            };
                            props.setValues({
                              ...props.values,
                              location_requirement: updatedLocationRequirement,
                            });
                            setUiiaCertified(e);
                          }}
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="shipped"
                        checked={props?.values?.location_requirement?.includeInRelease?.split(",").includes("shipped")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_shipped"
                      />
                      <label class="form-check-label" for="chk_shipped"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Can be shipped in current containers?</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("shipped") ||
                          props?.values?.location_requirement?.shipped?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.shipped?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.shipped;
                          } else {
                            updatedLocationRequirement.shipped = { VALUE: "" };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="shipped_chk"
                      />
                      <label class="form-check-label" for="shipped_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("shipped") &&
                      props?.values?.location_requirement?.shipped?.hasOwnProperty("VALUE") && (
                        <Select
                          styles={customStyles}
                          placeholder="Select"
                          options={optionYesNo}
                          value={shipped}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => {
                            const updatedLocationRequirement = {
                              ...props.values.location_requirement,
                            };
                            updatedLocationRequirement.shipped = {
                              VALUE: e.value,
                            };
                            props.setValues({
                              ...props.values,
                              location_requirement: updatedLocationRequirement,
                            });
                            setShipped(e);
                          }}
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="empty_containers"
                        checked={props?.values?.location_requirement?.includeInRelease
                          ?.split(",")
                          .includes("empty_containers")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_empty_containers"
                      />
                      <label class="form-check-label" for="chk_empty_containers"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Empty containers must be returned to original pick up depot?</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("empty_containers") ||
                          props?.values?.location_requirement?.empty_containers?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.empty_containers?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.empty_containers;
                          } else {
                            updatedLocationRequirement.empty_containers = {
                              VALUE: "",
                            };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="empty_containers_chk"
                      />
                      <label class="form-check-label" for="empty_containers_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("empty_containers") &&
                      props?.values?.location_requirement?.empty_containers?.hasOwnProperty("VALUE") && (
                        <Select
                          styles={customStyles}
                          placeholder="Select"
                          options={optionYesNo}
                          value={emptyContainers}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => {
                            const updatedLocationRequirement = {
                              ...props.values.location_requirement,
                            };
                            updatedLocationRequirement.empty_containers = {
                              VALUE: e.value,
                            };
                            props.setValues({
                              ...props.values,
                              location_requirement: updatedLocationRequirement,
                            });
                            setEmptyContainers(e);
                          }}
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="pick_up"
                        checked={props?.values?.location_requirement?.includeInRelease?.split(",").includes("pick_up")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_pick_up"
                      />
                      <label class="form-check-label" for="chk_pick_up"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Pick Up / Product Removal Deadline:</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("pick_up") ||
                          props?.values?.location_requirement?.pick_up?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.pick_up?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.pick_up;
                          } else {
                            updatedLocationRequirement.pick_up = { VALUE: "" };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="pick_up_chk"
                      />
                      <label class="form-check-label" for="pick_up_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("pick_up") &&
                      props?.values?.location_requirement?.pick_up?.hasOwnProperty("VALUE") && (
                        <input
                          type="text"
                          value={props?.values?.location_requirement?.pick_up?.VALUE}
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              location_requirement: {
                                ...props.values.location_requirement,
                                pick_up: {
                                  ...props.values.location_requirement.pick_up,
                                  VALUE: e.target.value,
                                },
                              },
                            });
                          }}
                          className="form-control"
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="storage_buyer_cost"
                        checked={props?.values?.location_requirement?.includeInRelease
                          ?.split(",")
                          .includes("storage_buyer_cost")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_storage_buyer_cost"
                      />
                      <label class="form-check-label" for="chk_storage_buyer_cost"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Storage Costs to Buyer?</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("storage_buyer_cost") ||
                          props?.values?.location_requirement?.storage_buyer_cost?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.storage_buyer_cost?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.storage_buyer_cost;
                          } else {
                            updatedLocationRequirement.storage_buyer_cost = {
                              VALUE: "",
                            };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="storage_buyer_cost_chk"
                      />
                      <label class="form-check-label" for="storage_buyer_cost_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("storage_buyer_cost") &&
                      props?.values?.location_requirement?.storage_buyer_cost?.hasOwnProperty("VALUE") && (
                        <>
                          <sub>Add storage cost and how often it is charged here:</sub>
                          <input
                            type="text"
                            value={props?.values?.location_requirement?.storage_buyer_cost?.VALUE}
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                location_requirement: {
                                  ...props.values.location_requirement,
                                  storage_buyer_cost: {
                                    ...props.values.location_requirement.storage_buyer_cost,
                                    VALUE: e.target.value,
                                  },
                                },
                              });
                            }}
                            className="form-control"
                          />
                        </>
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="storage_cost_date"
                        checked={props?.values?.location_requirement?.includeInRelease
                          ?.split(",")
                          .includes("storage_cost_date")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_storage_cost_date"
                      />
                      <label class="form-check-label" for="chk_storage_cost_date"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Storage Cost Start Date:</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("storage_cost_date") ||
                          props?.values?.location_requirement?.storage_cost_date?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.storage_cost_date?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.storage_cost_date;
                          } else {
                            updatedLocationRequirement.storage_cost_date = {
                              VALUE: "",
                            };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="storage_cost_date_chk"
                      />
                      <label class="form-check-label" for="storage_cost_date_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("storage_cost_date") &&
                      props?.values?.location_requirement?.storage_cost_date?.hasOwnProperty("VALUE") && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disableHighlightToday={true}
                            className="form-control"
                            value={props?.values?.location_requirement?.storage_cost_date?.VALUE}
                            onChange={(e) => {
                              props.setValues({
                                ...props.values,
                                location_requirement: {
                                  ...props.values.location_requirement,
                                  storage_cost_date: {
                                    ...props.values.location_requirement.storage_cost_date,
                                    VALUE: moment(e).format("YYYY-MM-DD"),
                                  },
                                },
                              });
                            }}
                            renderInput={(params) => <TextField {...params} error={false} />}
                            inputFormat="MM/dd/yyyy"
                          />
                        </LocalizationProvider>
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={"demurrage"}
                        checked={props?.values?.location_requirement?.includeInRelease
                          ?.split(",")
                          .includes("demurrage")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_demurrage"
                      />
                      <label class="form-check-label" for="chk_demurrage"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Demurrage:</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("demurrage") ||
                          props?.values?.location_requirement?.demurrage?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.demurrage?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.demurrage;
                          } else {
                            updatedLocationRequirement.demurrage = {
                              VALUE: "",
                            };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="demurrage_chk"
                      />
                      <label class="form-check-label" for="demurrage_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("demurrage") &&
                      props?.values?.location_requirement?.demurrage?.hasOwnProperty("VALUE") && (
                        <Select
                          styles={customStyles}
                          placeholder="Select"
                          options={optionDemurrage}
                          value={demurrage}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => {
                            const updatedLocationRequirement = {
                              ...props.values.location_requirement,
                            };
                            updatedLocationRequirement.demurrage = {
                              VALUE: e.value,
                            };
                            props.setValues({
                              ...props.values,
                              location_requirement: updatedLocationRequirement,
                            });
                            setDemurrage(e);
                          }}
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="customs"
                        checked={props?.values?.location_requirement?.includeInRelease?.split(",").includes("customs")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_customs"
                      />
                      <label class="form-check-label" for="chk_customs"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Customs Cleared?</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("customs") ||
                          props?.values?.location_requirement?.customs?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.customs?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.customs;
                          } else {
                            updatedLocationRequirement.customs = { VALUE: "" };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="customs_chk"
                      />
                      <label class="form-check-label" for="customs_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("customs") &&
                      props?.values?.location_requirement?.customs?.hasOwnProperty("VALUE") && (
                        <Select
                          styles={customStyles}
                          placeholder="Select"
                          options={optionYesNo}
                          value={customs}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => {
                            const updatedLocationRequirement = {
                              ...props.values.location_requirement,
                            };
                            updatedLocationRequirement.customs = {
                              VALUE: e.value,
                            };
                            props.setValues({
                              ...props.values,
                              location_requirement: updatedLocationRequirement,
                            });
                            setCustoms(e);
                          }}
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td>
                    <span className="fw-medium">Release and Product Removal Logistics Details</span>
                  </td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="c_picking_up"
                        checked={props?.values?.location_requirement?.includeInRelease
                          ?.split(",")
                          .includes("c_picking_up")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_c_picking_up"
                      />
                      <label class="form-check-label" for="chk_c_picking_up"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Trucking Company Picking Up Product</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("c_picking_up") ||
                          props?.values?.location_requirement?.c_picking_up?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.c_picking_up?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.c_picking_up;
                          } else {
                            updatedLocationRequirement.c_picking_up = {
                              VALUE: "",
                            };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="c_picking_up_chk"
                      />
                      <label class="form-check-label" for="c_picking_up_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("c_picking_up") &&
                      props?.values?.location_requirement?.c_picking_up?.hasOwnProperty("VALUE") && (
                        <input
                          type="text"
                          value={props?.values?.location_requirement?.c_picking_up?.VALUE}
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              location_requirement: {
                                ...props.values.location_requirement,
                                c_picking_up: {
                                  ...props.values.location_requirement.c_picking_up,
                                  VALUE: e.target.value,
                                },
                              },
                            });
                          }}
                          className="form-control"
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="scac_code"
                        checked={props?.values?.location_requirement?.includeInRelease
                          ?.split(",")
                          .includes("scac_code")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_scac_code"
                      />
                      <label class="form-check-label" for="chk_scac_code"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Trucking Company SCAC Code</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("scac_code") ||
                          props?.values?.location_requirement?.scac_code?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.scac_code?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.scac_code;
                          } else {
                            updatedLocationRequirement.scac_code = {
                              VALUE: "",
                            };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="scac_code_chk"
                      />
                      <label class="form-check-label" for="scac_code_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("scac_code") &&
                      props?.values?.location_requirement?.scac_code?.hasOwnProperty("VALUE") && (
                        <input
                          type="text"
                          value={props?.values?.location_requirement?.scac_code?.VALUE}
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              location_requirement: {
                                ...props.values.location_requirement,
                                scac_code: {
                                  ...props.values.location_requirement.scac_code,
                                  VALUE: e.target.value,
                                },
                              },
                            });
                          }}
                          className="form-control"
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="container_number"
                        checked={props?.values?.location_requirement?.includeInRelease
                          ?.split(",")
                          .includes("container_number")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (
                            props?.values?.location_requirement?.includeInRelease?.split(",")?.includes(e.target.value)
                          ) {
                            updatedLocationRequirement.includeInRelease =
                              props?.values?.location_requirement?.includeInRelease
                                ?.split(",")
                                ?.filter((val) => val !== e.target.value)
                                ?.join(",");
                          } else {
                            const arr = props?.values?.location_requirement?.includeInRelease?.split(",")
                              ? props?.values?.location_requirement?.includeInRelease?.split(",")
                              : [];
                            arr.push(e.target.value);
                            updatedLocationRequirement.includeInRelease = arr.join(",");
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_container_number"
                      />
                      <label class="form-check-label" for="chk_container_number"></label>
                    </div>
                  </td>
                  <td className=" separator-right"></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Container Number(s)</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={
                          props?.values?.location_requirement?.hasOwnProperty("container_number") ||
                          props?.values?.location_requirement?.container_number?.hasOwnProperty("VALUE")
                        }
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.container_number?.hasOwnProperty("VALUE")) {
                            delete updatedLocationRequirement.container_number;
                          } else {
                            updatedLocationRequirement.container_number = {
                              VALUE: "",
                            };
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="container_number_chk"
                      />
                      <label class="form-check-label" for="container_number_chk"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("container_number") &&
                      props?.values?.location_requirement?.container_number?.hasOwnProperty("VALUE") && (
                        <input
                          type="text"
                          value={props?.values?.location_requirement?.container_number?.VALUE}
                          onChange={(e) => {
                            props.setValues({
                              ...props.values,
                              location_requirement: {
                                ...props.values.location_requirement,
                                container_number: {
                                  ...props.values.location_requirement.container_number,
                                  VALUE: e.target.value,
                                },
                              },
                            });
                          }}
                          className="form-control"
                        />
                      )}
                  </td>
                </tr>
                <tr>
                  {/* <td></td> */}
                  <td></td>
                  <td></td>

                  <td>
                    <div class="form-check dark-tick-checkbox mb-2">
                      <span>Original BOL (redacted)</span>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={props?.values?.location_requirement?.hasOwnProperty("org_bol")}
                        onChange={(e) => {
                          const updatedLocationRequirement = {
                            ...props.values.location_requirement,
                          };
                          if (props?.values?.location_requirement?.hasOwnProperty("org_bol")) {
                            delete updatedLocationRequirement.org_bol;
                          } else {
                            updatedLocationRequirement.org_bol = {};
                          }
                          props.setValues({
                            ...props.values,
                            location_requirement: updatedLocationRequirement,
                          });
                        }}
                        id="chk_org_bol"
                      />
                      <label class="form-check-label" for="chk_org_bol"></label>
                    </div>
                    {props?.values?.location_requirement?.hasOwnProperty("org_bol") && (
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) => {
                          const data = { ...props.values };
                          data.org_bol_file = e.target.files[0].name;
                          props?.setUploadDocuments(e.target.files[0]);
                          props.setValues(data);
                        }}
                        className="form-control"
                      />
                    )}
                    {props?.values?.location_requirement?.hasOwnProperty("org_bol") && props?.values?.org_bol_file && (
                      <div className="d-flex align-items-center cursor-pointer mt-3">
                        <a href={props?.values?.org_bol_file} target="_blank" download={true} class="td-pdf-img-box">
                          <img src={pdfIcon} alt="pdf" />
                        </a>
                        <a
                          href={props?.values?.org_bol_file}
                          target="_blank"
                          download={true}
                          className="deafult-black-hover-green ms-2"
                        >
                          Original BOL Document
                        </a>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            Cancel
          </button>
          <NextButton
            class="btn btn-default"
            handleSubmit={props.handleSubmit}
            type="button"
            label={"Save"}
            loading={props.loadingProductLocation}
          />
        </div>
      </div>
    </Modal>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getLocSec: (params, callback) => dispatch(getMoreDetails(params, callback)),
  updateLocSec: (params, data, callback) => dispatch(updateLocationSecurity(params, data, callback)),
});
const mapStateToProps = (state) => ({
  productLocationDetails: productLocationDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ProductLocationModal));
