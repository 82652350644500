import React, { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import NextButton from "../../../common/form/nextButton";
import Select from "react-select";
import { customStyles } from "../../../common/customStyles/reactSelectStyle";

const CategoryPreferenacesModal = (props) => {
  const categoryId =
    props.categoryDropdown && props.categoryDropdown.length > 0 && props.categoryDropdown.map((val) => val);

  const [value, setValues] = useState();
  useEffect(() => {
    if (props.select && props.select.value) {
      props.subCat &&
        props.subCat.length > 0 &&
        props.subCat.map((val) => {
          if (val.value === props.select.value) {
            setValues(val.subCategories);
          }
        });
    }
  }, []);

  return (
    <Modal
      className="modal fade custom-modal"
      id="categoryRef"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      show={props.show}
      onHide={() => props.onHide()}
      enforceFocus={false}
    >
      <div class="modal-content d-flex flex-column">
        <div
          class="custom-modal-header d-flex align-items-center justify-content-between"
          style={{ height: "70px", padding: "30px 60px 10px 30px" }}
        >
          <h5 class="mb-0 text-capitalize">Category Preferences</h5>
          <div>
            <button
              type="button"
              class="close-btn d-flex align-items-center justify-content-center"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => props.onHide()}
            >
              <img src={close} alt="" />
            </button>
          </div>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden" style={{ paddingBottom: "10px" }}>
          <div className="d-flex align-items-center justify-content-center ysp mb-4" style={{ height: "30px" }}>
            <span>You can select upto 5 push categories</span>
          </div>
          <div class="row-details-row hide-scrollbar" style={{ height: "400px", overflowY: "scroll" }}>
            <div class="rdr-item d-flex flex-nowrap">
              <div class="rdr-label-box align-self-start">Push Categories</div>
              <Accordion className="accordion left-arrow-accordion small-checkbox">
                {Array.isArray(props.categoryDropDownData) &&
                  props.categoryDropDownData?.map((val, i) =>
                    val.ct > 0 ? (
                      <Accordion.Item className="accordion-item" eventKey={i}>
                        <Accordion.Header className="accordion-header">{`${val.description}(${val.auctions})`}</Accordion.Header>
                        <Accordion.Body className="accordion-body">
                          {val?.subCategories?.map((data, i) =>
                            true ? (
                              <Accordion className="accordion left-arrow-accordion small-checkbox">
                                <Accordion.Item className="accordion-item" eventKey={"200"}>
                                  <Accordion.Header className="accordion-header">{data.description}</Accordion.Header>
                                  <Accordion.Body className="accordion-body">
                                    {data?.subCategories?.map((er) => (
                                      <div class="form-check d-flex">
                                        <input
                                          id={`sub${er.category_id}`}
                                          name={er.category_id}
                                          class="form-check-input"
                                          type="checkbox"
                                          style={{
                                            height: "1.5rem",
                                            width: "1.5rem",
                                            cursor: "pointer",
                                          }}
                                          value={er.category_id}
                                          checked={props.checkList && props.checkList.includes(er.category_id)}
                                          onChange={() => {
                                            const checkedData = [...props.checkList];
                                            if (checkedData.includes(er.category_id)) {
                                              const a =
                                                checkedData &&
                                                checkedData.length &&
                                                checkedData.filter((item) => item !== er.category_id);
                                              props.setCheckList(a);
                                            } else {
                                              checkedData.push(er.category_id);
                                              props.setCheckList(checkedData);
                                            }
                                          }}
                                        />
                                        <label
                                          class="form-check-label fw-medium "
                                          for={`sub${er.category_id}`}
                                          style={{ paddingLeft: "15px" }}
                                        >
                                          {`${er.description} ${er.auctions ? `(${er.auctions})` : ""}`}
                                        </label>
                                      </div>
                                    ))}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            ) : (
                              <div class="form-check d-flex">
                                <input
                                  id={`vdv${data.category_id}`}
                                  name={data.category_id}
                                  class="form-check-input"
                                  type="checkbox"
                                  style={{
                                    height: "1.5rem",
                                    width: "1.5rem",
                                    cursor: "pointer",
                                  }}
                                  value={data.category_id}
                                  checked={props.checkList && props.checkList.includes(data.category_id)}
                                  onChange={() => {
                                    const checkedData = [...props.checkList];
                                    if (checkedData.includes(data.category_id)) {
                                      const a =
                                        checkedData &&
                                        checkedData.length &&
                                        checkedData.filter((item) => item !== data.category_id);
                                      props.setCheckList(a);
                                    } else {
                                      checkedData.push(data.category_id);
                                      props.setCheckList(checkedData);
                                    }
                                  }}
                                />
                                <label
                                  class="form-check-label fw-medium "
                                  for={`vdv${data.category_id}`}
                                  style={{ paddingLeft: "15px" }}
                                >
                                  {`${data.description} ${data.auctions ? `(${data.auctions})` : ""}`}
                                </label>
                              </div>
                            ),
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : (
                      <div class="form-check d-flex">
                        <input
                          id={`subcat${val.category_id}`}
                          name={val.category_id}
                          class="form-check-input"
                          type="checkbox"
                          style={{
                            height: "1.5rem",
                            width: "1.5rem",
                            cursor: "pointer",
                          }}
                          value={val.category_id}
                          checked={props.checkList && props.checkList.includes(val.category_id)}
                          onChange={() => {
                            const checkedData = [...props.checkList];
                            if (checkedData.includes(val.category_id)) {
                              const a =
                                checkedData &&
                                checkedData.length &&
                                checkedData.filter((item) => item !== val.category_id);
                              props.setCheckList(a);
                            } else {
                              checkedData.push(val.category_id);
                              props.setCheckList(checkedData);
                            }
                          }}
                        />
                        <label
                          class="form-check-label fw-medium "
                          for={`subcat${val.category_id}`}
                          style={{ paddingLeft: "15px", marginTop: "5px" }}
                        >
                          {`${val.description} ${val.auctions ? `(${val.auctions})` : ""}`}
                        </label>
                      </div>
                    ),
                  )}
              </Accordion>
            </div>
            {/* {props.select ? (
              <>
                <div class="rdr-item d-flex flex-nowrap">
                  <div class="rdr-label-box align-self-start">
                    Push Categories
                  </div>
                  <div
                    class="rdr-form-box"
                    style={{ height: "300px", overflow: "auto" }}
                  >
                    {value &&
                      value.length > 0 &&
                      value.map((data) => {
                        return (
                          <>
                            <div
                              class="form-check form-tick mb-3"
                              style={{ height: "auto", width: "auto" }}
                            >
                              <input
                                id={data.category_id}
                                name={data.category_id}
                                class="form-check-input"
                                type="checkbox"
                                style={{
                                  height: "1.5rem",
                                  width: "1.5rem",
                                  cursor: "pointer",
                                }}
                                value={data.category_id}
                                checked={
                                  props.checkList &&
                                  props.checkList.includes(data.category_id)
                                }
                                onChange={() => {
                                  const checkedData = [...props.checkList];
                                  if (checkedData.includes(data.category_id)) {
                                    const a =
                                      checkedData &&
                                      checkedData.length &&
                                      checkedData.filter(
                                        (item) => item !== data.category_id
                                      );
                                    props.setCheckList(a);
                                  } else {
                                    checkedData.push(data.category_id);
                                    props.setCheckList(checkedData);
                                  }
                                }}
                              />
                              <label
                                class="form-check-label fw-medium "
                                for={data.category_id}
                                style={{ paddingLeft: "15px" }}
                              >
                                {data.description}
                              </label>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </>
            ) : (
              ""
            )} */}
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div class="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button class="btn btn-link me-md-2 global-cancel-button" type="button" onClick={() => props.onHide()}>
            Cancel
          </button>
          {props?.checkList?.length > 5 ? (
            <button className="btn btn-default btn-disabled">Save</button>
          ) : (
            <NextButton
              label="Save"
              classData="btn btn-default"
              handleSubmit={props.handleSubmit}
              loading={props.loadingCategory}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(CategoryPreferenacesModal);
