import React, { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import close from "../../../include/images/close.svg";
import trash from "../../../include/images/trash-24x24.svg";
import NextButton from "../../../common/form/nextButton";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";

const AssignAviationAIModal = (props) => {
  const [search, setSearch] = useState("");
  useEffect(() => {
    setSearch("");
  }, [props.show]);
  const getFlatCategories = (data) => {
    return _.flatMapDeep(data, (item) => {
      return [item, ...getFlatCategories(item.subCategories || [])];
    });
  };
  const getFlatCategoryIds = (data) => {
    return _.flatMapDeep(data, (item) => {
      return [item?.category_id, ...getFlatCategoryIds(item.subCategories || [])];
    });
  };
  const flatCategoryIds = getFlatCategoryIds(props?.aviationAIData);
  const flatCategories = getFlatCategories(props?.aviationAIData);
  const filteredCategories = flatCategories.filter((item) =>
    item.description.toLowerCase().includes(search.toLowerCase()),
  );
  const renderCategories = (categories, owner = "All Listings") => {
    return categories?.map((category, index) => {
      if (category?.ct > 1) {
        return (
          <Accordion
            className="accordion left-arrow-accordion small-checkbox"
            key={`${category.description}${category.category_id}`}
            defaultActiveKey={`${category.description}0`}
          >
            <Accordion.Item className="accordion-item" eventKey={`${category.description}${index}`}>
              <Accordion.Header className="accordion-header">
                <span className="ps-4">
                  {category.description} {category.auctions ? `(${category?.auctions})` : ""}
                </span>
              </Accordion.Header>
              <Accordion.Body className="accordion-body">
                {renderCategories(category.subCategories, category.description)}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      } else {
        return (
          <div className="form-check d-flex" key={category.category_id}>
            <input
              id={`sub${category.category_id}`}
              name={category.category_id}
              className="form-check-input"
              type="checkbox"
              style={{
                height: "1.5rem",
                width: "1.5rem",
                cursor: "pointer",
              }}
              value={category.category_id}
              checked={props.checkList && props.checkList.includes(category.category_id)}
              onChange={() => {
                const checkedData = [...props.checkList];
                const selectedData = [...(props?.selectedCategory || [])];
                if (checkedData.includes(category.category_id)) {
                  const updatedList = checkedData.filter((item) => item !== category.category_id);
                  const updatedData = selectedData?.filter((item) => item?.category_id !== category?.category_id);
                  props.setCheckList(updatedList);
                  props?.setSelectedCategory(updatedData);
                } else {
                  checkedData.push(category.category_id);
                  const newCategory = { ...category };
                  newCategory.subCategories = [owner];
                  selectedData.push(newCategory);
                  props?.setSelectedCategory(selectedData);
                  props.setCheckList(checkedData);
                }
              }}
            />
            <label
              className="form-check-label fw-medium"
              htmlFor={`sub${category.category_id}`}
              style={{ paddingLeft: "15px" }}
            >
              {category.description} {category.auctions ? `(${category.auctions})` : ""}
            </label>
          </div>
        );
      }
    });
  };
  return (
    <Modal
      className="modal fade custom-modal"
      id="editSustainabilityReport"
      show={props.show}
      onHide={() => props.onHide()}
      dialogClassName="modal-dialog modal-dialog-centered modal-xxxl"
    >
      <div class="modal-content h-100 d-flex flex-column">
        <div class="custom-modal-header d-flex align-items-center justify-content-between">
          <h5 class="mb-0 text-capitalize">Assign Aviation AI Categories</h5>
          <button
            type="button"
            class="close-btn d-flex align-items-center justify-content-center"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            <img src={close} alt="" />
          </button>
        </div>
        <Modal.Body className="custom-modal-body flex-grow-1 w-100 overflow-hidden pb-0">
          <div class="detail-row-container d-flex flex-wrap">
            <div class="drc-left w-50">
              <div className="header-search-box custom-header-search-box">
                <input
                  type="search"
                  className="w-100"
                  placeholder="Enter Keywords"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              {search ? (
                <div className="mb-3">
                  {filteredCategories?.length > 0 ? (
                    filteredCategories?.map((category, index) => (
                      <div className="form-check d-flex mt-3" key={category.category_id}>
                        <input
                          id={`sub${category.category_id}`}
                          name={category.category_id}
                          className="form-check-input"
                          type="checkbox"
                          style={{
                            height: "1.5rem",
                            width: "1.5rem",
                            cursor: "pointer",
                          }}
                          value={category.category_id}
                          checked={props.checkList && props.checkList.includes(category.category_id)}
                          onChange={() => {
                            const checkedData = [...props.checkList];
                            const selectedData = [...(props?.selectedCategory || [])];
                            if (checkedData.includes(category.category_id)) {
                              const updatedList = checkedData.filter((item) => item !== category.category_id);
                              const updatedData = selectedData?.filter(
                                (item) => item?.category_id !== category?.category_id,
                              );
                              props.setCheckList(updatedList);
                              props?.setSelectedCategory(updatedData);
                            } else {
                              checkedData.push(category.category_id);
                              const newCategory = { ...category };
                              newCategory.subCategories = ["Aviation AI"];
                              selectedData.push(newCategory);
                              props?.setSelectedCategory(selectedData);
                              props.setCheckList(checkedData);
                            }
                          }}
                        />
                        <label
                          className="form-check-label fw-medium"
                          htmlFor={`sub${category.category_id}`}
                          style={{ paddingLeft: "15px" }}
                        >
                          {category.description} {category.auctions ? `(${category.auctions})` : ""}
                        </label>
                      </div>
                    ))
                  ) : (
                    <span className="text-danger">No Data Found</span>
                  )}
                </div>
              ) : (
                <Accordion className="accordion left-arrow-accordion small-checkbox mt-3">
                  {Array.isArray(props?.aviationAIData) && renderCategories(props?.aviationAIData)}
                </Accordion>
              )}
            </div>
            <div class="drc-right w-50">
              <div className="table-responsive mb-30">
                <table className="table table-border-radious table-bordered table-fit-content table-wrap align-middle table-large">
                  <colgroup>
                    <col style={{ maxWidth: "200px" }} />
                    <col style={{ maxWidth: "240px" }} />
                    <col style={{ maxWidth: "80px" }} />
                  </colgroup>
                  <tbody>
                    {props?.selectedCategory?.length > 0
                      ? props?.selectedCategory?.map(
                          (val, i) =>
                            flatCategoryIds?.includes(val?.category_id) && (
                              <tr key={i}>
                                <td className="padding-td">
                                  <ul>
                                    {val.subCategories.length ? (
                                      val.subCategories
                                        .slice()
                                        .reverse()
                                        .map((subCat, j) => <li key={j}>{subCat}</li>)
                                    ) : (
                                      <li>--</li>
                                    )}
                                  </ul>
                                </td>
                                <td className="padding-td">{val.description}</td>
                                <td className="padding-td">
                                  <div
                                    className="td-delete-icon"
                                    onClick={() => {
                                      const updatedList = props?.checkList.filter((item) => item !== val.category_id);
                                      const filteredData = props?.selectedCategory.filter(
                                        (obj) => obj.category_id !== val.category_id,
                                      );
                                      props?.setSelectedCategory(filteredData);
                                      props?.setCheckList(updatedList);
                                    }}
                                  >
                                    <img src={trash} alt="Delete" />
                                  </div>
                                </td>
                              </tr>
                            ),
                        )
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div class="custom-modal-dividor"></div>
        <div className="custom-modal-footer d-grid gap-2 d-md-flex align-items-center justify-content-md-end">
          <button
            class="btn btn-link me-md-2 global-cancel-button"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => props.onHide()}
          >
            Cancel
          </button>
          <NextButton
            label="Save"
            classData="btn btn-default"
            handleSubmit={props.handleSubmit}
            loading={props.loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AssignAviationAIModal;
